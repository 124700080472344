import React from 'react';
import PropTypes from 'prop-types';
import { Upload, Icon, Button, Switch } from 'antd';
import { Message, Icon as SuiIcon } from 'semantic-ui-react';
import partition from 'lodash/partition';
import { download, isImageContentType, getBase64, detectThumbUrl } from './file-tools';
import { ModalPreview } from './ModalPreview';

const DEFAULT_MAX_FILES = 10;

export default class FilePreviewWall extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            previewVisible: false,
            previewImage: null,
            previewFile: null,
            listType: props.defaultListType || 'picture',
            skippedFiles: [],
        }
    }

    maxFiles = () => this.props.maxFiles || DEFAULT_MAX_FILES;
    listTypeToggling = () => {
        const { listTypeToggling = true } = this.props;
        return listTypeToggling
    }

    handlePreviewClose = () => {
        this.setState({ previewVisible: false, previewFile: null, previewImage: null })
    }

    handlePreview = async file => {
        //console.log('handlePreview', file, 'image', isImageContentType(file.type))

        if (!isImageContentType(file.type)) {
            download(file)
            return
        }

        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewFile: file,
            previewVisible: true
        });
    }

    beforeUpload = file => {
        // Upload przy zapisie formularza
        return false;
    }

    handleChange = ({ fileList }) => {
        if (fileList.length > this.maxFiles()) {
            const [retainedFiles, skippedFiles] = partition(fileList, o => {
                const i = fileList.indexOf(o);
                return i <= this.maxFiles() - 1
            });
            this.setState({ skippedFiles })
            fileList = retainedFiles;
        }
        else if (this.state.skippedFiles.length > 0) {
            this.setState({ skippedFiles: [] })
        }

        fileList = detectThumbUrl(fileList);
        //console.log('handleChange', fileList, file, event);

        this.props.onChange({ fileList })
    }

    handleRemove = file => {
        const { filesToDelete = [], onChange } = this.props;
        //console.log('handleRemove ', file)
        if (file.uploaded) {
            filesToDelete.push(file);
            onChange({ filesToDelete })
        }
        return true;
    }

    handleToggleListView = listType => this.setState({ listType })

    render() {
        const { previewVisible, previewImage, previewFile, listType, skippedFiles } = this.state;
        const fileList = detectThumbUrl(this.props.fileList);
        const { readOnly, multiple = true, placeholder, accept } = this.props;
        const uploadDisabled = readOnly || fileList.length >= this.maxFiles();

        return (
            <>
                {this.listTypeToggling() &&
                    <ToggleListView
                        listType={listType}
                        onChange={this.handleToggleListView}
                        uploadDisabled={uploadDisabled}
                    />
                }

                {skippedFiles.length > 0 && <SkippedFilesMessage skippedFiles={skippedFiles} maxFiles={this.maxFiles()} />}

                <div className='clearfix'>
                    {
                        multiple ?
                            <MultipleUpload
                                listType={listType} fileList={fileList} handlePreview={this.handlePreview}
                                handleChange={this.handleChange} handleRemove={this.handleRemove}
                                beforeUpload={this.beforeUpload} readOnly={readOnly}
                                maxFiles={this.maxFiles()} uploadDisabled={uploadDisabled}
                                placeholder={placeholder} accept={accept}
                            />
                            :
                            <SingleUpload
                                handlePreview={this.handlePreview} handleChange={this.handleChange}
                                handleRemove={this.handleRemove} beforeUpload={this.beforeUpload}
                                readOnly={readOnly} listType={listType} uploadDisabled={uploadDisabled}
                                fileList={fileList} placeholder={placeholder} accept={accept}
                            />
                    }

                    <ModalPreview
                        previewFile={previewFile}
                        previewImage={previewImage}
                        visible={previewVisible}
                        handlePreviewClose={this.handlePreviewClose}
                    />
                </div>
            </>
        )
    }
}

const MultipleUpload = ({ listType, fileList, handlePreview, handleChange, handleRemove,
    beforeUpload, readOnly, maxFiles, uploadDisabled, placeholder, accept }) =>
    <Upload
        listType={listType}
        fileList={fileList}
        showUploadList={{ showPreviewIcon: true, showRemoveIcon: true, showDownloadIcon: false }}
        onPreview={handlePreview}
        onChange={handleChange}
        onRemove={handleRemove}
        beforeUpload={beforeUpload}
        multiple
        disabled={readOnly}
        accept={accept}
    >
        {!uploadDisabled && <UploadButton listType={listType} maxFiles={maxFiles} placeholder={placeholder} />}
    </Upload>

const SingleUpload = ({ handlePreview, handleChange, handleRemove, beforeUpload,
    readOnly, listType, fileList, uploadDisabled, placeholder, accept }) =>
    <Upload
        listType={listType}
        fileList={fileList}
        showUploadList={{ showPreviewIcon: true, showRemoveIcon: false, showDownloadIcon: false }}
        onPreview={handlePreview}
        onChange={handleChange}
        onRemove={handleRemove}
        beforeUpload={beforeUpload}
        multiple={false}
        disabled={readOnly}
        className='singleUpload'
        accept={accept}
    >
        {!uploadDisabled && <UploadButton listType={listType} maxFiles={1} placeholder={placeholder} />}
    </Upload>

const UploadButton = ({ listType, maxFiles, placeholder }) => {
    const title = `Click or drag files to this area to upload up to ${maxFiles} files`;
    const label = placeholder || `Select or drop file${maxFiles > 1 ? 's' : ''} (graphics, pdf, other)`;

    switch (listType) {
        case 'picture-card':
            return (
                <div title={title}>
                    <Icon type='upload' />
                    <div className='ant-upload-text'>{label}</div>
                </div>
            )

        default:
            return (
                <Button title={title} size='large' style={{ height: '50px' }}>
                    <Icon type='upload' />{label}
                </Button>

            )
    }
}

const ToggleListView = ({ listType, onChange, uploadDisabled }) => {
    const checked = listType === 'picture';
    const label = listType === 'picture' ? 'pictures' : 'list';
    const style = { marginBottom: uploadDisabled || listType === 'picture-card' ? '0.3em' : '-2em' };

    return (
        <div className='fileWall' style={style}>
            <span title={`Switch to ${label} view`}>
                <Switch checked={checked}
                    checkedChildren={<SuiIcon name='images' />}
                    unCheckedChildren={<SuiIcon name='list' />}
                    onChange={() => onChange(checked ? 'picture-card' : 'picture')}
                />
            </span>
        </div>
    )
}

const SkippedFilesMessage = ({ skippedFiles, maxFiles }) =>
    <Message
        negative
        size='small'
        header={`${skippedFiles.length} files skipped due to exceeded limit of ${maxFiles} files`}
        list={skippedFiles.map(o => o.name)}
    />

FilePreviewWall.propTypes = {
    fileList: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    filesToDelete: PropTypes.array,
    readOnly: PropTypes.bool,
    maxFiles: PropTypes.number,
    defaultListType: PropTypes.string,
    listTypeToggling: PropTypes.bool,
    multiple: PropTypes.bool,
}