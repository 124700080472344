class GraphQlSchema {

    static fetchSchema = (handler) => {
        fetch("/graphql/schema.json", {
            method: 'get',
            headers: { 'Content-Type': 'application/json', },
        })
            .then(response => {
                if (!response.ok) {
                    //responseError = true;
                    return response.json();
                }
                return response.json();
            })
            .then(schema => handler(schema))
    }

    static getQueries = (graphQlSchema) => {
        if (!graphQlSchema) return []
        return graphQlSchema.data.__schema.types.filter(type => type.name === "Query")[0].fields
    }

    static getEntityFieldsDef = (graphQlSchema, entityName) => {
        if (!graphQlSchema) return []
        return graphQlSchema.data.__schema.types.filter(type => type.name === entityName)[0].fields
    }
}

export default GraphQlSchema