import React from 'react';
import PropTypes from 'prop-types';
import { Form, Table, Dimmer, Loader, Container, Icon, Pagination, Message } from 'semantic-ui-react';
import classNames from 'classnames';

/**
 * Wyświetla standardową listę obiektów (organizacje, projekty, etc.)
 * @param {*} props 
 */
export const DataTable = props => {
    const { loading, data, header, body, footer = props => <DataTableFooter {...props} />,
        sortMetaData, onSort, scrollX = false, ...rest } = props;

    return (
        <Container fluid className='projectList'>
            <Form className={classNames({ 'scrollX': scrollX })}>
                <Dimmer active={loading} inverted>
                    <Loader />
                </Dimmer>

                {
                    (data.length === 0 && loading !== undefined && !loading) ? <MessageInfoNoResults /> : (
                        <Table celled striped sortable>
                            {header({ sortMetaData, onSort })}
                            {body({ data, ...rest })}
                            {footer({ ...rest })}
                        </Table>
                    )
                }
            </Form>
        </Container>
    )
}

const MessageInfoNoResults = () => <Message info header='No results found.' />

export const DataTableFooter = ({ footerColSpan, totalPages, activePage, onPageChange, totalElements }) => (
    <Table.Footer>
        <Table.Row>
            <Table.HeaderCell colSpan={footerColSpan} textAlign='center'>
                <Pagination
                    firstItem={null}
                    lastItem={null}
                    prevItem={{ content: <Icon name='angle left' />, icon: true }}
                    nextItem={{ content: <Icon name='angle right' />, icon: true }}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                    activePage={activePage + 1}
                />
                <div className='total_records_div'>Records no.: {totalElements}</div>
            </Table.HeaderCell>
        </Table.Row>
    </Table.Footer>
)

export const DataTableHeaderCell = ({ field, label, onSort, sortMetaData, ...rest }) => (
    <Table.HeaderCell
        sorted={sortMetaData.field === field ? sortMetaData.direction : null}
        onClick={e => onSort(field, e)} {...rest}>
        {label || rest.children}
    </Table.HeaderCell>
)

DataTableHeaderCell.propTypes = {
    field: PropTypes.string.isRequired,
    label: PropTypes.string,
    onSort: PropTypes.func.isRequired,
    sortMetaData: PropTypes.object.isRequired,
}

DataTable.propTypes = {
    loading: PropTypes.bool,
    data: PropTypes.array.isRequired,
    header: PropTypes.func.isRequired,
    body: PropTypes.func.isRequired,
    footer: PropTypes.func,
    sortMetaData: PropTypes.object.isRequired,
    footerColSpan: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    activePage: PropTypes.number.isRequired,
    totalElements: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onSort: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    scrollX: PropTypes.bool,
}