import React from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { Search, Table, Button, Radio } from 'semantic-ui-react'
import client from '../../lib/client.js'
import './ProjectPartners.css'

const resultRenderer = ({ id, title, description }) => (
    <div key='content' className='content'>
        {title && <div className='title'>{title}</div>}
        {description && <div className='description'>{description}</div>}
    </div>
)

export class ProjectPartersSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            value: props.defaultValue || '',
            results: []
        }
        this.searchForPartnersDebounced = _.debounce(this.searchForPartners, 500)
    }

    componentDidUpdate(prevProps) {
        if (this.props.defaultValue !== prevProps.defaultValue) {
            this.setState({ value: this.props.defaultValue });
        }
    }

    resetComponent = () => this.setState({ loading: false, results: [], value: '' })

    handleResultSelect = (e, { result }) => {
        const { onProjectPartnerAdd, insertValueOnSelect } = this.props;
        onProjectPartnerAdd(e, result);

        if (insertValueOnSelect) {
            this.setState({ value: result.title })
        }
    }

    searchForPartners = () => {
        //console.log('searchForPartners', `/api/organizations/search/byName?searchText=${this.state.value}&sort=nameEnglish,asc`);

        this.setState({ loading: true });
        client(`/api/organizations/search/byName?searchText=${this.state.value}&sort=nameEnglish,asc`)
            .then(response => {
                let searchResults = response.entity._embedded.organizations.map(org => ({
                    id: parseInt(_.last(org._links.self.href.split("/")), 10),
                    title: org.nameEnglish,
                    description: org.contactPerson,
                    organization: org,
                    childKey: _.last(org._links.self.href.split("/"))
                }));
                //console.log('searchResults', searchResults);
                this.setState({ results: searchResults });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    handleSearchChange = (e, obj) => {
        let value = obj.value;
        this.setState({ value });

        if (value.trim() === '') {
            const { onSearchClear } = this.props;
            onSearchClear && onSearchClear();
        }

        if (value.length < 1) {
            return this.resetComponent();
        }
        this.searchForPartnersDebounced();
    }

    render() {
        const { onProjectPartnerAdd, insertValueOnSelect, onSearchClear, readOnly, defaultValue,
            ...restProps } = this.props;

        return (
            <Search
                loading={this.state.loading}
                onResultSelect={this.handleResultSelect}
                onSearchChange={this.handleSearchChange}
                results={this.state.results}
                value={this.state.value}
                placeholder='Search for partners...'
                autoFocus
                resultRenderer={resultRenderer}
                showNoResults={!readOnly}
                readOnly={readOnly}
                icon={readOnly ? null : 'search'}
                {...restProps}
            />
        )
    }
}

class ProjectPartersList extends React.Component {
    render() {
        let projectId = this.props.match.params.id;
        const { readOnly } = this.props;

        return (
            <div className='projectPartnersContainer'>
                {!readOnly &&
                    <div className='searchBar'>
                        <ProjectPartersSearch
                            onProjectPartnerAdd={this.props.onProjectPartnerAdd}
                        />

                        <Button content='Register your organization' icon='add' labelPosition='left'
                            primary as={Link} to={`/projects/${projectId || 'register'}/partners/register`}
                        />
                    </div>
                }

                {this.props.projectPartners.length > 0 &&
                    <Table striped className='projectPartners'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={2}>Lead<br />Partner</Table.HeaderCell>
                                <Table.HeaderCell width={4}>Name of the organization<br /> / english</Table.HeaderCell>
                                <Table.HeaderCell width={3}>Name of the organization<br /> / original language</Table.HeaderCell>
                                <Table.HeaderCell width={3}>Contact</Table.HeaderCell>
                                <Table.HeaderCell width={2}>Country</Table.HeaderCell>
                                <Table.HeaderCell width={1}></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.props.projectPartners.map(partner =>
                                <Table.Row key={partner.organizationId}>
                                    <Table.Cell textAlign='center' width={2}>
                                        <Radio
                                            name='leadPartner'
                                            checked={partner.leadPartner}
                                            onChange={this.props.onProjectPartnerChange}
                                            value={partner.organizationId}
                                            toggle
                                            disabled={readOnly}
                                        />
                                    </Table.Cell>
                                    <Table.Cell className='preLine' width={4}>
                                        {partner.organization.nameEnglish}
                                    </Table.Cell>
                                    <Table.Cell className='preLine' width={3}>
                                        {partner.organization.nameOriginalLanguage}
                                    </Table.Cell>
                                    <Table.Cell className='preLine' width={3}>
                                        {partner.organization.contactPerson}
                                    </Table.Cell>
                                    <Table.Cell width={2}>
                                        {partner.organization.country}
                                    </Table.Cell>
                                    <Table.Cell textAlign='center' width={1}>
                                        {!readOnly &&
                                            <Button basic icon='delete' title='Delete' type='button'
                                                onClick={(evt) => this.props.onProjectPartnerRemove(evt, partner)}
                                            />
                                        }
                                    </Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                }
            </div>
        )
    }
}

export default ProjectPartersList