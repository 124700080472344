import React from 'react'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import 'react-leaflet-markercluster/dist/styles.min.css'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import { Button } from 'semantic-ui-react'
import { withRouter } from 'react-router'

export const DEFAULT_VIEWPORT = {
    center: [48, 10],
    zoom: 5
}

export const DefaultTileLayer = () =>
    <TileLayer
        attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />

class MapView extends React.Component {
    constructor(props) {
        super(props);
        this.markerClusterGroupRef = React.createRef();
        this.mapRef = React.createRef();

        this.state = {
            viewport: DEFAULT_VIEWPORT
        }
    }

    // shouldComponentUpdate(nextProps) {
    //     let shouldUpdate = nextProps.isLoading !== this.props.isLoading;
    //     console.log('shouldUpdate mapView', shouldUpdate);
    //     return shouldUpdate;
    // }

    render() {
        let clickedOrg = this.props.clickedOrg;
        //console.log('rendering map clickedOrg', clickedOrg);

        return (
            <Map viewport={this.state.viewport} minZoom={2} maxZoom={18} ref={this.mapRef}>
                <DefaultTileLayer />

                <MarkerClusterGroup maxClusterRadius={40} ref={this.markerClusterGroupRef}>
                    {
                        this.props.organizations.map(org =>
                            <OrganizationMarker
                                key={org.id}
                                organization={org}
                                position={[org.geoSearchData.lat, org.geoSearchData.lon]}
                                selected={clickedOrg && clickedOrg.id === org.id}
                                markerClusterGroupRef={this.markerClusterGroupRef}
                                mapRef={this.mapRef}
                            />
                        )
                    }
                </MarkerClusterGroup>
            </Map>
        )
    }
}

class OrganizationMarker extends React.Component {
    constructor(props) {
        super(props);
        this.markerRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (this.props.selected) { // && this.props.selected !== prevProps.selected
            let markerClusterGroup = this.props.markerClusterGroupRef.current.leafletElement;
            let marker = this.markerRef.current.leafletElement;
            //let map = this.props.mapRef.current.leafletElement;
            //let parent = markerClusterGroup.getVisibleParent(marker);

            //console.log('componentDidUpdate', this.props);
            // console.log('parent', parent);
            // console.log('marker', marker);
            // console.log('map', map);

            markerClusterGroup.zoomToShowLayer(marker, () => marker.openPopup());

            //map.flyTo(marker.getLatLng(), DEFAULT_VIEWPORT.zoom);
        }
    }

    handleEditClick = (org, { history }) => {
        history.push(`/map/organization/${org.id}`);
    }

    render() {
        const { organization } = this.props;

        const PopupWithRouterContext = withRouter(router => (
            <Popup>
                <div>
                    <div className='header'>{organization.nameEnglish}</div>
                    <div>{organization.geoSearchData.displayName}</div>
                    <div className='buttons'>
                        <Button icon='edit' basic compact size='tiny' title='Details'
                            onClick={() => this.handleEditClick(organization, router)}
                        />
                    </div>
                </div>
            </Popup>
        ))

        return (
            <Marker position={this.props.position} title={organization.nameEnglish} ref={this.markerRef}>
                <PopupWithRouterContext />
            </Marker>
        )
    }
}

export default MapView;