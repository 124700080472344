import React from 'react'
import { Form } from 'semantic-ui-react'
import * as utils from '../../lib/utils.js'
//import Dropdown from '../inputs/Dropdown.js'
//import DropdownTree from '../inputs/DropdownTree.js'
//import { organizationFields as fields } from './OrganizationFields.js'
import OptionListWrapper from '../inputs/OptionListWrapper.js'
import 'react-dropdown-tree-select/dist/styles.css'

export const StepServices = (props) => (
    <div>
        <DropdownServiceType
            onChange={props.onDropdownChange}
            value={utils.multiselectStringToArray(props.organization.serviceType)}
            error={props.invalidFields.find(o => o.name === 'serviceType') !== undefined}
            readOnly={props.readOnly}
        />
        <DropdownIndustrySector
            onOrganizationChange={props.onOrganizationChange}
            value={utils.multiselectStringToArray(props.organization.industrySector, '|')}
            error={props.invalidFields.find(o => o.name === 'industrySector') !== undefined}
            readOnly={props.readOnly}
        />
    </div>
)

class DropdownServiceType extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            options: []
        };
    }

    componentDidMount() {
        OptionListWrapper.serviceType()
            .then(res => {
                const lista = res.data;
                let result = lista.map(el => ({ value: el, text: el }));
                return result;
            })
            .then(result => {
                this.setState({ options: result });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        let label = 'Service type';
        const { readOnly, ...rest } = this.props;

        return (
            <Form.Dropdown name='serviceType' label={label} placeholder={label} options={this.state.options}
                multiple selection
                loading={this.state.loading} disabled={this.state.loading || readOnly}
                className={readOnly ? 'readOnly' : null}
                {...rest}
                onChange={(evt, data) => this.props.onChange(evt, { selectedValues: data.value, name: data.name })} />
        );
    }
}

class DropdownIndustrySector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            options: []
        };
    }

    componentDidMount() {
        OptionListWrapper.industrySector()
            .then(res => {
                const lista = res.data;
                let result = lista.map(el => ({ value: el, text: el }));
                return result;
            })
            .then(result => {
                this.setState({ options: result });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    handleChange = (evt, dropdownData) => {
        const value = Array.isArray(dropdownData.selectedValues) ? utils.multiselectArrayToString(dropdownData.selectedValues, '|') : dropdownData.selectedValues;
        this.props.onOrganizationChange({ industrySector: value });
    }

    render() {
        let label = 'Industry sector';
        const { value, error, readOnly, onOrganizationChange, ...rest } = this.props;

        return (
            <Form.Dropdown name='industrySector' label={label} placeholder={label} options={this.state.options}
                value={value} error={error}
                multiple selection
                loading={this.state.loading} disabled={this.state.loading || readOnly}
                className={readOnly ? 'readOnly' : null}
                {...rest}
                onChange={(evt, data) => this.handleChange(evt, { selectedValues: data.value, name: data.name })} />
        );
    }
}
