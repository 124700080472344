import React, { useState } from 'react';
import { Redirect } from 'react-router-dom'
import { Table, Button, Container } from 'semantic-ui-react'
import { userService } from '../../user/user.service';
import { DataList } from '../../DataList';
import { DataTable } from '../../DataTable';
//import { SearchBar } from '../../SearchBar';
import { timestampCutSeconds } from '../../../lib/utils';
import { authHeader } from '../../user/auth-header';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';

class InfrastructureRequestList extends React.Component {
    state = {
        searchText: ''
    }

    getDefaultUrl = () => {
        return '/infrastructureRequests/report'
    }

    render() {
        if (!userService.isAdmin()) return <Redirect to='/' />

        return (
            <div>
                <DataList apiDataFetchUrl={this.getDefaultUrl()} apiEntityName='tupleBackedMaps' defaultSortBy='createTimestamp,desc'
                    {...this.props}
                >
                    {({ loading, data, sortMetaData, page, ...rest }) => (
                        <>
                            <ExportToExcelButton />

                            <InfrastructureRequestTable loading={loading} sort={sortMetaData} data={data}
                                totalPages={page.totalPages} activePage={page.number} totalElements={page.totalElements}
                                {...rest}
                            />
                        </>
                    )}
                </DataList>
            </div>
        )
    }
}

const InfrastructureRequestTable = ({ data, loading, sort, ...rest }) => (
    <DataTable data={data} loading={loading} sortMetaData={sort} footerColSpan={12} scrollX={true} {...rest}
        header={({ sortMetaData, onSort }) => (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell sorted={sortMetaData.field === 'createTimestamp' ? sortMetaData.direction : null}
                        onClick={evt => onSort('createTimestamp', evt)}>Request create time</Table.HeaderCell>
                    <Table.HeaderCell sorted={sortMetaData.field === 'i.name' ? sortMetaData.direction : null}
                        onClick={evt => onSort('i.name', evt)}>Infrastructure</Table.HeaderCell>
                    <Table.HeaderCell sorted={sortMetaData.field === 'org.nameEnglish' ? sortMetaData.direction : null}
                        onClick={evt => onSort('org.nameEnglish', evt)}>Organization</Table.HeaderCell>
                    <Table.HeaderCell sorted={sortMetaData.field === 'req_from_user.email' ? sortMetaData.direction : null}
                        onClick={evt => onSort('req_from_user.email', evt)}>Request from</Table.HeaderCell>
                    <Table.HeaderCell sorted={sortMetaData.field === 'requestTo' ? sortMetaData.direction : null}
                        onClick={evt => onSort('requestTo', evt)}>Request to</Table.HeaderCell>
                    <Table.HeaderCell sorted={sortMetaData.field === 'requestDescription' ? sortMetaData.direction : null}
                        onClick={evt => onSort('requestDescription', evt)} style={{ minWidth: '25em' }}>Request description</Table.HeaderCell>
                    <Table.HeaderCell sorted={sortMetaData.field === 'plannedUseDescription' ? sortMetaData.direction : null}
                        onClick={evt => onSort('plannedUseDescription', evt)} style={{ minWidth: '10em' }}>Planned duration</Table.HeaderCell>
                    <Table.HeaderCell sorted={sortMetaData.field === 'possibleUseStartDate' ? sortMetaData.direction : null}
                        onClick={evt => onSort('possibleUseStartDate', evt)}>Planned use from</Table.HeaderCell>
                    <Table.HeaderCell sorted={sortMetaData.field === 'possibleUseEndDate' ? sortMetaData.direction : null}
                        onClick={evt => onSort('possibleUseEndDate', evt)}>Planned use to</Table.HeaderCell>
                    <Table.HeaderCell sorted={sortMetaData.field === 'possibilitiesToRent' ? sortMetaData.direction : null}
                        onClick={evt => onSort('possibilitiesToRent', evt)}>Possibilities to rent</Table.HeaderCell>
                    <Table.HeaderCell sorted={sortMetaData.field === 'mailSendTimestamp' ? sortMetaData.direction : null}
                        onClick={evt => onSort('mailSendTimestamp', evt)}>Mail send time</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )}
        body={({ data }) => (
            <Table.Body>
                {data.map(o =>
                    <Table.Row key={o.id}>
                        <Table.Cell textAlign='center'>
                            {timestampCutSeconds(o.createTimestamp)}
                        </Table.Cell>
                        <Table.Cell>
                            {o.infrastructureName}
                        </Table.Cell>
                        <Table.Cell>
                            {o.orgNameEnglish}
                        </Table.Cell>
                        <Table.Cell>
                            {o.reqFromUser}
                        </Table.Cell>
                        <Table.Cell>
                            {o.requestTo}
                        </Table.Cell>
                        <Table.Cell>
                            {o.requestDescription}
                        </Table.Cell>
                        <Table.Cell>
                            {o.plannedUseDescription}
                        </Table.Cell>
                        <Table.Cell textAlign='center'>
                            {o.possibleUseStartDate}
                        </Table.Cell>
                        <Table.Cell textAlign='center'>
                            {o.possibleUseEndDate}
                        </Table.Cell>
                        <Table.Cell>
                            {o.possibilitiesToRent}
                        </Table.Cell>
                        <Table.Cell textAlign='center'>
                            {timestampCutSeconds(o.mailSendTimestamp)}
                        </Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
        )}
    />
)

const ExportToExcelButton = () => {
    const [loading, setLoading] = useState(false);

    const fetchData = () => {
        setLoading(true);

        fetch('/infrastructureRequests/report-excel', { headers: authHeader() })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Response failed with: ' + response.status + ' ' + response.statusText)
                }
                return response.blob()
            })
            .then(blob => saveAs(blob, 'infrastructure-request-report.xlsx'))
            .catch(err => toast.error(err.message))
            .finally(() => setLoading(false))
    }

    return (
        <Container className='searchControls' fluid>
            <Button content='Export to Excel' icon='file excel' labelPosition='left'
                primary onClick={fetchData} loading={loading} disabled={loading}
            />
        </Container>
    )
}

export default InfrastructureRequestList