import React from 'react';
import { Link, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button, Form, Message, Segment, Modal } from 'semantic-ui-react'
import { Dimmer, Loader, Container, Checkbox } from 'semantic-ui-react'
import './user.css'
import client from '../../lib/client.js'
import { ToastErrorMessage } from '../../lib/validation.js'
import {userService} from './../user/user.service.js';

class UserEditForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {
                username: '',
                email: '',
                firstName: '',
                lastName: '',
                isAuthorizing: false,
                isAdmin: false,
            },
            submitted: false,
            error: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    }

    componentDidMount() {
        this.userId = parseInt(this.props.match.params.id, 10);
        if (this.userId > 0) {
            this.fetchUser(this.userId);
        }
        else {
            this.setState({ loading: false });
        }
    }

    fetchUser(userId) {
        client(`/api/users/${userId}`)
            .then(response => {
                let user = Object.assign({}, response.entity, { _links: response.entity._links });
                this.setState({ user: user });
                //return client(`/api/users/${userId}/authorities`);
                this.fetchUserAuthorities(this.userId);
            }, error => {
                this.setState({ user: undefined });
            })
            //.then(response => this.setState({ authorities: response.entity._embedded.authorities }))
            .finally(() => this.setState({ loading: false }));
    }

    fetchUserAuthorities(userId) {
        client(`/api/users/${userId}/authorities`) //http://localhost:3000/api/users/2/authorities
            .then(response => {
                let authoritiesHAL = response.entity._embedded.authorities;
                let authorities = authoritiesHAL.map(en => en._links.authority.href.split('/')[5]);
                console.log('fetchUserAuthorities ' + authorities);
                let user = { ...this.state.user, isAdmin: authorities.includes('ROLE_ADMIN'), isAuthorizing: authorities.includes('ROLE_AUTHORIZING'), };
                this.setState({ user: user });
                //return client(`/api/users/${userId}/authorities`);
            }, error => {
                //this.setState({ user: undefined });
            })
        //.then(response => this.setState({ authorities: response.entity._embedded.authorities }))
        //.finally(() => this.setState({ loading: false }));
    }

    handleChange(e) {
        const { name, value } = e.target;
        let user = { ...this.state.user };
        console.log("handleChange "+ name +" "+ value);
        user[name] = value;

        this.setState({ user });
    }

    handleCheckboxChange(event, data) {
        const { name, checked } = data;
        let user = { ...this.state.user };
        console.log("handleCheckboxChange ", event, data);
        user[name] = checked;

        this.setState({ user });
    }

    handleSubmit = (e, history) => {
        this.setState({ loading: true });
        let authorities = [];

        authorities.push({authority: 'ROLE_ADMIN'});
        let user = {
            ...this.state.user,
            ROLE_ADMIN: this.state.isAdmin,
        };

        client({
            method: 'PATCH',
            path: user._links.self.href,
            entity: user,
            headers: { 'Content-Type': 'application/json' }
        }).then(response => {
            this.props.onSubmit(user, <span>User updated: <em>{user.firstName + ' ' + user.lastName}</em></span>, e);
        }, error => {
            this.setState({ loading: false });
            toast.error(<ToastErrorMessage
                message={'Failed to update user: ' + error.entity.message}
                violations={error.entity.violations} />, { autoClose: false }
            );
        })

        console.log('UserEditForm '+ this.userId +" "+ this.state.user.isAdmin +' '+ this.state.user.isApprobatory);
        userService.updateAuthorities({userId: this.userId, isAdmin: this.state.user.isAdmin, isAuthorizing: this.state.user.isAuthorizing,})
    }

    render() {
        const ButtonSubmit = withRouter(({ history }) => (
            <Button color='green' key='submit' icon='checkmark'
                content='Save' onClick={(e) => { this.handleSubmit(e, history) }} disabled={this.state.loading} />
        ))

        const { user } = this.state
        let { isLoading } = this.props;
        return (
            < Modal dimmer='inverted' open>
                <Modal.Header>
                    Edit user account <span className='nameEditLabel'>{user.login}</span>
                </Modal.Header>

                <Form className='editForm'>

                    <Dimmer active={isLoading} inverted>
                        <Loader />
                    </Dimmer>
                    <Form.Input id='form-input-login' label='Login' placeholder='Login'
                        name="login" value={user.login || ''} readOnly
                    />
                    <Form.Input id='form-input-firstName' label='First Name' placeholder='First Name'
                        name="firstName" value={user.firstName || ''} onChange={this.handleChange}
                    />
                    <Form.Input id='form-input-lastName' label='Last Name' placeholder='Last Name'
                        name="lastName" value={user.lastName || ''} onChange={this.handleChange}
                    />
                    <Form.Input id='form-input-email' label='E-mail' placeholder='E-mail'
                        name="email" value={user.email || ''} onChange={this.handleChange}
                    />
                    {/* <Label as='a' Basic>Overview</Label> */}
                    <Form.Field >
                        <label>Authorities</label>
                        <Segment.Group horizontal id='authorities'>
                            <Segment><Checkbox toggle label='Authorizing' name='isAuthorizing' onChange={this.handleCheckboxChange} checked={user.isAuthorizing} /></Segment>
                            <Segment><Checkbox toggle label='Admin' name='isAdmin' onChange={this.handleCheckboxChange} checked={user.isAdmin} /></Segment>
                        </Segment.Group>
                    </Form.Field >
                    <Form.Field >
                        <label>Account status</label>
                        <Segment.Group horizontal>
                            <Segment><Checkbox toggle label='Activated' name='activated' onChange={this.handleCheckboxChange} checked={user.activated} /></Segment>
                        </Segment.Group>
                    </Form.Field >

                    <Message attached='bottom'>
                        <Container textAlign='right'>
                            <ButtonSubmit />
                            {/* <Button color='green' key='submit' icon='checkmark'
                                content='Save' onClick={this.handleSubmit} disabled={this.state.loading} /> */}
                            <Button key='cancel' color='red' icon='remove' content='Cancel' as={Link} to='/users' />
                        </Container>
                    </Message>
                </Form>

            </Modal>
        )
    }
}

export default UserEditForm