import React from 'react'
import { Table, Input, Button, Dimmer, Loader } from 'semantic-ui-react'
import { saveAs } from 'file-saver'
import * as utils from '../../lib/utils.js'
import { graphQLFetcher } from '../graphql/GraphQLFetcher'
import DateRangePicker from './DateRangePicker'
import FilterText from './FilterText'
import './OrganizationFiltredTable.css'

class OrganizationFiltredTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            organizations: [],
            loading: false,
            filterState: '',
        }
    }

    componentDidMount() {
        this.setState({ loading: true, })
        this.fetchGraphQlOrganizations()
    }

    fetchGraphQlOrganizations = () => {
        const graphQLParams = {
            query: `{
                    allOrganizations(count:666) {
                        id nameEnglish country
                        createTimestamp updateTimestamp userUpdateId
                        userOwner {
                            id login activated
                        }
                    }
            }`
        }
        graphQLFetcher(graphQLParams, '/graphql') //http://localhost:9000/graphql
            .then(graphQlResult => {
                console.log('graphQLFetcher graphQlResult', graphQlResult)
                this.setState({organizations: graphQlResult.data.allOrganizations, loading: false, })
            })
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    setStateForKey = (stateKey) => {
        return (value) => {
            this.setState({ [stateKey]: value });
        }
    }

    filterRangeChange = (filteredFild, from, to) => {
        console.log('OrganizationFiltredTable.filterRangeChange', filteredFild, from)
        this.setState({ [filteredFild + 'from']: from, [filteredFild + 'to']: to, })
    }
    saveToFile = () => {
        const organizations = this.filterOrganizations()
        const reducer = (acc, org) => acc + '\n' + org.nameEnglish + ';' + org.createTimestamp + ';' + org.updateTimestamp
        const csv = organizations.reduce(reducer, 'Name;Created;Updated')
        var blob = new Blob([csv], { type: "text/plain;charset=utf-8" });
        saveAs(blob, "organizations.csv");
    }

    filterOrganizations = () => {
        const { organizations, updateTimestampfrom, updateTimestampto, createTimestampfrom, createTimestampto, } = this.state;
        const filterCreated = (org) => 
            (!createTimestampfrom || org.createTimestamp >= createTimestampfrom) &&
                (!createTimestampto || org.createTimestamp <= createTimestampto)
        const filterUpdated = org => 
            (!updateTimestampfrom || org.updateTimestamp >= updateTimestampfrom) &&
            (!updateTimestampto || org.updateTimestamp <= updateTimestampto)
        
        const filter = (org) => {
            return filterCreated(org) &&
                filterUpdated(org)
                && this.filterNo1(org)
        }
        return organizations.filter(filter) //org => !createTimestampfrom || org.updateTimestamp >= createTimestampfrom
    }
    filterNo1 = (org) => {
        const { filterState, } = this.state;
        return !filterState || org.nameEnglish.includes(filterState)
    }

    render() {
        const { loading, updateTimestampfrom, updateTimestampto, createTimestampfrom, createTimestampto, } = this.state;
        const organizationsToShow = this.filterOrganizations()
        return (
            <div>
                {/* <Button content='GraphQL org' onClick={() => this.fetchGraphQlOrganizations()} /> */}
                <Button content={'Organizations [' + organizationsToShow.length +']'} onClick={() => this.saveToFile()}
                    loading={loading} icon='download' />
                <Dimmer active={loading} inverted>
                    <Loader />
                </Dimmer>
                <Table celled striped sortable loading={loading}>
                <Table.Header>
                    <Table.Row>
                            <Table.HeaderCell
                            >Name
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            >Country
                        </Table.HeaderCell>
                            <Table.HeaderCell className='rangeColumn'
                            >Created</Table.HeaderCell>
                            <Table.HeaderCell className='rangeColumn'
                            >Updated</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>
                                <FilterText keepStateHandler={this.setStateForKey('filterState')} state={this.state.filterState}/>
                        </Table.HeaderCell>
                            <Table.HeaderCell>

                            </Table.HeaderCell>
                        <Table.HeaderCell>
                            From 
                                <Input id='form-input-createTimestampfrom' type='date'
                                    name="createTimestampfrom" value={createTimestampfrom} onChange={this.handleChange}
                                />
                            To
                                <Input id='form-input-createTimestampto' type='date'
                                name="createTimestampto" value={createTimestampto} onChange={this.handleChange}
                            />

                        </Table.HeaderCell>
                        <Table.HeaderCell>
                                <DateRangePicker filteredFild='updateTimestamp' onChange={this.filterRangeChange} 
                                    valueForm={updateTimestampfrom} valueTo={updateTimestampto} />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {organizationsToShow.map(organization =>
                        <Table.Row key={organization.id}>
                            <Table.Cell className='preLine'>
                                {organization.nameEnglish}
                            </Table.Cell>
                            <Table.Cell className='preLine'>
                                {organization.country}
                            </Table.Cell>
                            <Table.Cell className='preLine'>
                                {utils.timestampCutSeconds(organization.createTimestamp)}
                            </Table.Cell>
                            <Table.Cell>
                                {utils.timestampCutSeconds(organization.updateTimestamp)}
                            </Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>

                <Table.Footer>
                </Table.Footer>
            </Table>
            </div>
        )
    }

}

export default OrganizationFiltredTable