import { isBlank, maxSize } from '../../lib/validation.js';
import { isInteger, isNil, negate } from 'lodash';

export const infrastructureFields = {
    name: {
        label: 'Name of infrastructure',
        sizeMax: 200,
        get validations() { return [isBlank, maxSize(this.sizeMax)] },
        get validationError() {
            if (isBlank(this.value)) return this.label + ' is required'
            return this.label + ' max size is ' + this.sizeMax
        },
        step: 1
    },
    organizationId: {
        label: 'Organization',
        validations: [isNil, negate(isInteger)],
        get validationError() { return this.label + ' is required' },
        step: 1
    },
    description: {
        label: 'Short description of Infrastructure',
        sizeMax: 750,
        get validations() { return [isBlank, maxSize(this.sizeMax)] },
        get validationError() {
            if (isBlank(this.value)) return this.label + ' is required'
            return this.label + ' max size is ' + this.sizeMax
        },
        step: 1
    },
    address: {
        label: 'Address',
        sizeMax: 255,
        get validations() { return [isBlank, maxSize(this.sizeMax)] },
        get validationError() {
            if (isBlank(this.value))
                return this.label + ' is required'
            return this.label + ' max size is ' + this.sizeMax
        },
        step: 1
    },
    zipCode: {
        label: 'Zip code',
        sizeMax: 255,
        get validations() { return [isBlank, maxSize(this.sizeMax)] },
        get validationError() {
            if (isBlank(this.value))
                return this.label + ' is required'
            return this.label + ' max size is ' + this.sizeMax
        },
        step: 1
    },
    city: {
        label: 'City',
        sizeMax: 255,
        get validations() { return [isBlank, maxSize(this.sizeMax)] },
        get validationError() {
            if (isBlank(this.value))
                return this.label + ' is required'
            return this.label + ' max size is ' + this.sizeMax
        },
        step: 1
    },
    region: {
        label: 'Region',
        step: 1
    },
    country: {
        label: 'Country',
        validations: isBlank,
        get validationError() { return this.label + ' is required' },
        step: 1
    },
    researchServices: {
        label: 'Research Services',
        sizeMax: 500,
        step: 1
    },
    possibilitiesToRent: {
        label: 'Possibilities of use',
        get validations() { return [isBlank] },
        get validationError() {
            if (isBlank(this.value)) return this.label + ' is required'
            return ''
        },
        step: 1
    },
    website: {
        label: 'Website',
        step: 1
    },
    openKeywords: {
        label: 'Open Keywords',
        get validations() { return [isBlank] },
        get validationError() {
            if (isBlank(this.value)) return this.label + ' is required'
            return ''
        },
        step: 1
    },
    possibleApplication: {
        label: 'Possible Application',
        sizeMax: 500,
        get validations() { return [maxSize(this.sizeMax)] },
        get validationError() {
            return this.label + ' max size is ' + this.sizeMax
        },
        step: 1
    },
    location: {
        label: 'Location',
        step: 1
    },
    acquisitionYear: {
        label: 'Year of production',
        step: 1
    },
    usagePrice: {
        label: 'Price of usage (EUR)',
        get validations() { return [isBlank] },
        get validationError() {
            if (isBlank(this.value)) return this.label + ' is required'
            return ''
        },
        step: 1
    },
    responsiblePerson: {
        label: 'Responsible person',
        get validations() { return [isBlank] },
        get validationError() {
            if (isBlank(this.value)) return this.label + ' is required'
            return ''
        },
        step: 1
    },
    responsiblePersonEmail: {
        label: 'Responsible person email',
        get validations() { return [isBlank] },
        get validationError() {
            if (isBlank(this.value)) return this.label + ' is required'
            return ''
        },
        step: 1
    },
    responsiblePersonPhone: {
        label: 'Responsible person phone',
        get validations() { return [isBlank] },
        get validationError() {
            if (isBlank(this.value)) return this.label + ' is required'
            return ''
        },
        step: 1
    },
    imageDescriptionCopyright: {
        label: 'Description/copyright',
        step: 2
    },
}