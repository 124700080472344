import { userService } from './user.service.js'

export function authHeader() {
    // return authorization header with jwt token
    let user = userService.loggedUser();

    if (user && user.token) {
        return { 'Authorization': 'Bearer ' + user.token };
    } else {
        return { 'Security': 'noToken' };
    }
}