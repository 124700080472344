import client from '../../lib/client.js'
import axios from 'axios'
import when from 'when'
import { authHeader } from '../user/auth-header';

let axiosGet = (url, config) => {
    return when(axios.get(url, config));
}

class OptionListWrapper {

    static byUrl(dataUrl) {
        //console.log(dataUrl);
        return client(dataUrl);
    }

    static projectType() {
        return axiosGet('/api/dictionary/project_type');
    }

    static nationalInternational() {
        return axiosGet('/api/dictionary/national_international');
    }

    static totalBudget() {
        return axiosGet('/api/dictionary/total_budget');
    }

    static finalizedRunning() {
        return axiosGet('/api/dictionary/finalized_running');
    }

    static developmentStage() {
        return axiosGet('/api/dictionary/development_stage');
    }

    static technologyReadinessLevel() {
        return axiosGet('/api/dictionary/technology_readiness_level');
    }

    static iprStatus() {
        return axiosGet('/api/dictionary/ipr_status');
    }

    static projectsOutput() {
        return axiosGet('/api/dictionary/projects_output');
    }

    static serviceType() {
        return axiosGet('/api/dictionary/service_type');
    }

    static industrySector() {
        //return axiosGet('/api/dictionary/industry_sector', );

        // return when(axios.post('/api/dictionary/industry_sector', {
        //     params: {
        //         ID: 12345
        //     }
        // }));

        return when(axios({
            method:'post',
            url:'/api/dictionary/industry_sector',
            headers: authHeader()
          }))
    }
}

export default OptionListWrapper