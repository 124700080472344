import React from 'react'
import { Table, Container, Tab, Header } from 'semantic-ui-react'
import './MatchmakingDataSource.css'

class MatchmakingDataSource extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        const keywordTabs = [
            {
                menuItem: 'Additive Manufacturing KpaTechnology', render: () =>
                    <Tab.Pane><MatchmakingDataSourceTabContent dataSourceType={DataSourceTypes.AdditiveManufacturingKpaTechnology} /></Tab.Pane>
            },
            {
                menuItem: 'Additive Manufacturing KpaMarket', render: () =>
                    <Tab.Pane><MatchmakingDataSourceTabContent dataSourceType={DataSourceTypes.AdditiveManufacturingKpaMarket} /></Tab.Pane>
            },
            {
                menuItem: 'Micro-&Nanotechnology KpaTechnology', render: () =>
                    <Tab.Pane><MatchmakingDataSourceTabContent dataSourceType={DataSourceTypes.MicroNanotechnologyKpaTechnology} /></Tab.Pane>
            },
            {
                menuItem: 'Micro-&Nanotechnology KpaMarket', render: () =>
                    <Tab.Pane><MatchmakingDataSourceTabContent dataSourceType={DataSourceTypes.MicroNanotechnologyKpaMarket} /></Tab.Pane>
            },
            {
                menuItem: 'Industry KpaTechnology', render: () =>
                    <Tab.Pane><MatchmakingDataSourceTabContent dataSourceType={DataSourceTypes.IndustryKpaTechnology} /></Tab.Pane>
            },
            {
                menuItem: 'Industry KpaMarket', render: () =>
                    <Tab.Pane><MatchmakingDataSourceTabContent dataSourceType={DataSourceTypes.IndustryKpaMarket} /></Tab.Pane>
            },
        ]

    return (
            <Container textAlign='center'>
                <Header as='h2'>Data sources for grouping projects</Header>
                <Tab className='matchmakingTabs' panes={keywordTabs} />
            </Container>
        )
    }
}

const DataSourceTypes = {
    AdditiveManufacturingKpaTechnology: 'additive manufacturing_kpa_technology',
    AdditiveManufacturingKpaMarket: 'additive manufacturing_kpa_market',
    MicroNanotechnologyKpaTechnology: 'micro nanotechnology_kpa_technology',
    MicroNanotechnologyKpaMarket: 'micro nanotechnology_kpa_market',
    IndustryKpaTechnology: 'industry_kpa_technology',
    IndustryKpaMarket: 'industry_kpa_market',
}

class MatchmakingDataSourceTabContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            projects: [],
            dataSourceType: this.props.dataSourceType,
        }
    }

    componentDidMount() {
        this.fetchResult(this.urlForType(this.props.dataSourceType));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.dataSourceType !== this.state.dataSourceType) {
            this.fetchResult(this.urlForType(this.props.dataSourceType));
            this.setState({ dataSourceType: this.props.dataSourceType })
        }
    }

    fetchResult(request) {
        fetch(request, {
            method: 'get',
            headers: { 'Content-Type': 'application/json', },
        })
            .then(response => {
                if (!response.ok) {
                    return Promise.reject();
                }
                return response.json();
            })
            .then(json => {
                this.setState({ projects: json.map(this.parseProject) })
            })
    }

    parseProject = csv => {
        const arrayOfStrings = csv.split(';')
        return {id: arrayOfStrings[0], title: arrayOfStrings[1], acronym: arrayOfStrings[2], key: arrayOfStrings[3], }
    }

    urlForType(dataSourceType) {
        switch (dataSourceType) {
            case DataSourceTypes.AdditiveManufacturingKpaTechnology:
                return '/matchmaking/additive_manufacturing_kpa_technology_keywords';
            case DataSourceTypes.AdditiveManufacturingKpaMarket:
                return '/matchmaking/additive_manufacturing_kpa_market_keywords';
            case DataSourceTypes.MicroNanotechnologyKpaTechnology:
                return '/matchmaking/micro_nanotechnology_kpa_technology_keywords';
            case DataSourceTypes.MicroNanotechnologyKpaMarket:
                return '/matchmaking/micro_nanotechnology_kpa_market_keywords';
            case DataSourceTypes.IndustryKpaTechnology:
                return '/matchmaking/industry_kpa_technology_keywords';
            case DataSourceTypes.IndustryKpaMarket:
                return '/matchmaking/industry_kpa_market_keywords';
            default: // do nothing
        }
    }

    render() {
        const { projects, } = this.state;
        return (
            <Table celled striped sortable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell
                        >Project acronym</Table.HeaderCell>
                        <Table.HeaderCell
                        >Project title</Table.HeaderCell>
                        <Table.HeaderCell
                        >Key</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {projects.map(project =>
                        <Table.Row key={project.acronym}>
                            <Table.Cell className='preLine'>
                                {project.acronym}
                            </Table.Cell>
                            <Table.Cell className='preLine'>
                                {project.title}
                            </Table.Cell>
                            <Table.Cell>
                                {project.key}
                            </Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>

                <Table.Footer>
                </Table.Footer>
            </Table>
        )
    }
}

export { MatchmakingDataSource }