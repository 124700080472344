import React from 'react'
import { Image } from 'semantic-ui-react'
import logo from '../assets/logo-synergy.jpg'
import microscope from '../assets/microscope.png'
import './AppHeader.css'

const AppHeader = () => (
    <header className='appHeader'>
        <div className='linkInterreg'>
            <a href='http://www.interreg-central.eu/' className='interreg'>interreg central europe</a>
        </div>
        <div className='headerRight'>
            <span className='priority headerRightElement'>
                <img src={microscope} className='microscope' alt='' />
                priority: innovation and knowledge development
            </span>
            <span className='headerRightElement'>
                <Image className='synergyLogo' src={logo} as='a'
                    href='http://www.interreg-central.eu/Content.Node/SYNERGY.html'
                />
            </span>
        </div>
    </header>
)

export default AppHeader