import React from 'react';
import { useState, useEffect } from 'react';
import { Form, Button, Container, Icon } from 'semantic-ui-react'
import Dropdown from '../inputs/Dropdown.js'
import DropdownTree from '../inputs/DropdownTree.js'
import * as utils from '../../lib/utils.js'
import { UserManualButton } from './Infrastructure'
import client from '../../lib/client.js'

const InfrastructureSearch = props => {
    const { infrastructureSearchFields, onInfrastructureSearchFieldsChange, searchInfrastructure, searchHash } = props
    const searchFieldsChanged = searchHash !== utils.hashCodeObject(infrastructureSearchFields)
    const [countryOptions, setCountryOptions] = useState([]);
    useEffect(() => {
        loadCountries()
    }, []);
    const loadCountries = () => {
        return client('/api/dictionary/country/dropdown')
            .then(response => {
                setCountryOptions(response.entity)
            })
            .finally(() => {
                //this.setState({ countryLoading: false });
            });
    }

    return (
        <div className='infrastructure-search'>
            <Form size='tiny'>
                <Container textAlign='center' as='h5' fluid className='search-header'>
                    <Button primary icon style={{ margin: '0 15px' }}
                        onClick={searchInfrastructure} disabled={!searchFieldsChanged}>
                        <Icon name='search' /> Search infrastructures
                    </Button>
                    <UserManualButton />
                </Container>

                <Form.Input
                    label='Free search'
                    placeholder='Free search'
                    name='freeSearch'
                    value={infrastructureSearchFields.freeSearch || ''}
                    onChange={(e, data) => utils.handleInputChange(e, onInfrastructureSearchFieldsChange)}
                />
                <Dropdown
                    onChange={(e, dropdownData) => utils.handleDropdownChange(dropdownData, onInfrastructureSearchFieldsChange)}
                    value={infrastructureSearchFields.country}
                    label='Country'
                    name='country'
                    options={countryOptions}
                />
                <div className='stepInfrastructure'>
                    <DropdownTree
                        name='infrastructureAdditiveManufacturing'
                        label='Infrastructure Type Additive Manufacturing'
                        onChange={(e, dropdownData) => utils.handleDropdownChange(dropdownData, onInfrastructureSearchFieldsChange)}
                        dataUrl='/data/organization_infrastructure_additive_manufacturing.json'
                        value={utils.multiselectStringToArray(infrastructureSearchFields.infrastructureAdditiveManufacturing)}
                    />

                    <DropdownTree
                        name='infrastructureIndustry40'
                        label='Infrastructure Type Industry 4.0'
                        onChange={(e, dropdownData) => utils.handleDropdownChange(dropdownData, onInfrastructureSearchFieldsChange)}
                        dataUrl='/data/organization_infrastructure_industry_40.json'
                        value={utils.multiselectStringToArray(infrastructureSearchFields.infrastructureIndustry40)}
                    />

                    <DropdownTree
                        name='infrastructureMicroNanoManufacturing'
                        label='Infrastructure Type Micro/Nano Manufacturing'
                        onChange={(e, dropdownData) => utils.handleDropdownChange(dropdownData, onInfrastructureSearchFieldsChange)}
                        dataUrl='/api/dictionary/infrastructure_micro_nano_manufacturing/dropdown_tree'
                        value={utils.multiselectStringToArray(infrastructureSearchFields.infrastructureMicroNanoManufacturing)}
                    />
                </div>
                <div className='stepInfrastructure'>
                    <DropdownTree
                        name='infrastructureResearchEquipment'
                        label='Infrastructure Type Research Equipment'
                        onChange={(e, dropdownData) => utils.handleDropdownChange(dropdownData, onInfrastructureSearchFieldsChange)}
                        dataUrl='/infrastructure_type/infrastructure_research_equipment.json'
                        value={utils.multiselectStringToArray(infrastructureSearchFields.infrastructureResearchEquipment)}
                    />

                    <DropdownTree
                        name='infrastructureOfficeEquipment'
                        label='Infrastructure Type Space and Office Equipment'
                        onChange={(e, dropdownData) => utils.handleDropdownChange(dropdownData, onInfrastructureSearchFieldsChange)}
                        dataUrl='/infrastructure_type/infrastructure_office_equipment.json'
                        value={utils.multiselectStringToArray(infrastructureSearchFields.infrastructureOfficeEquipment)}
                    />
                </div>
                <Form.Input
                    label='Research Services'
                    placeholder='Research Services'
                    name='researchServices'
                    value={infrastructureSearchFields.researchServices || ''}
                    onChange={(e, data) => utils.handleInputChange(e, onInfrastructureSearchFieldsChange)}
                />
                <Dropdown
                    upward
                    onChange={(e, dropdownData) => utils.handleDropdownChange(dropdownData, onInfrastructureSearchFieldsChange)}
                    value={infrastructureSearchFields.possibilitiesToRent}
                    label='Possibilities to rent'
                    name='possibilitiesToRent'
                    dataUrl='/data/infrastructure_possibilities_to_rent.json'
                />
            </Form>
        </div>
    )
}

export default InfrastructureSearch