import React from 'react'
import AppHeader from './AppHeader.js'
import AppMenu from './AppMenu.js'
import AppFooter from './AppFooter.js'
import { useRouteMatch } from 'react-router'

const MainContent = ({ children }) => {
    const appMenuMatchRoute = useRouteMatch('/(projects|organizations|register_new_account|forgot-password|reset-password|change_password|matchmaking_results|users|map|infrastructures|infrastructure-requests)');
    return (
        <div className='siteParent'>
            <AppHeader />
            <main className='siteContent'>
                {appMenuMatchRoute && <AppMenu />}
                {children}
            </main>
            <AppFooter />
        </div>
    )
}

export default MainContent

