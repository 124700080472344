import React from 'react'

export function isBlank(value) {
    return !value || value.trim().length === 0
}

export function maxSize(sizeMax) {
    return (value) => {
        return value && value.length > sizeMax
    }
}

export function getInvalidFields(entityObj, entityFields, onInvalidItemClick) {
    let invalidFields = Object.keys(entityFields)
        .filter(name => {
            const fieldDef = entityFields[name]
            const fieldValue = entityObj[name]
            if (fieldDef.isValid) {
                return !fieldDef.isValid(entityObj, fieldValue);
            }

            return !isFieldValid(fieldValue, fieldDef.validations, entityObj);
        })
        .map(name => {
            let o = entityFields[name];
            o.name = name;
            o.value = entityObj[name];

            if (onInvalidItemClick) {
                o.validationClickableError =
                    <li className='validationErrorClickable' key={name}>
                        <button className='link' onClick={() => { onInvalidItemClick(o) }}>{o.validationError}</button>
                    </li>
            }

            return o;
        });

    return invalidFields;
}

export function isFieldValid(fieldValue, validators, entityObj) {
    if (validators && !Array.isArray(validators)) {
        validators = [validators];
    }
    return !(validators && validators.some(o => o(fieldValue, entityObj)));
}

export const ToastErrorMessage = (props) => {
    let violations = props.violations || [];
    let violationsList = violations.length > 0 ? <ul>{violations.map((o, i) => <li key={i}>{o}</li>)}</ul> : null;
    return (
        <div className='toastError'>
            {props.message || 'Error'}
            {violationsList}
        </div>
    )
}