import React, { useState } from 'react';
import { Button, Form, Message, Segment, Header } from 'semantic-ui-react'
import { userService } from './user.service.js';
import { LoginModal, ForgotPasswordButton, RegisterNewAccountButton } from './LoginPage';

export const ResetPasswordForm = props => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');

    const handleSubmit = evt => {
        const token = new URLSearchParams(props.location.search).get('token');

        if (!token) {
            setErrorMsg(`This Reset password form has no valid token.`)
            return
        }

        if (isPasswordValid(password) && isPasswordValid(confirmPassword) && password === confirmPassword) {
            setErrorMsg('');
            setLoading(true);

            userService.resetPassword({ password, confirmPassword, token },
                resp => { setLoading(false); setSuccessMsg(resp.message) },
                resp => { setLoading(false); setErrorMsg(resp.error) }
            );
        }
        else {
            setErrorMsg(`Make sure the new password is at least 6 characters long and both 
                fields 'New password' and 'Confirm new password' are the same`)
            return
        }
    }

    if (successMsg !== '') {
        return <LoginForm success={successMsg} open setLoggedUser={() => { props.history.push('/projects') }} />
    }

    return (
        <Segment className='forgotPassword'>
            <Header as='h2' color='teal' textAlign='center'>
                Reset your password
            </Header>
            <Form error={errorMsg !== ''} success={successMsg !== ''}>
                <Message error size='small' header={errorMsg} />
                <Message success size='small' header={successMsg} />

                <Form.Input
                    autoFocus
                    readOnly={loading}
                    fluid
                    placeholder='New password'
                    name='password' value={password} type='password' onChange={({ target }) => setPassword(target.value)}
                />

                <Form.Input
                    readOnly={loading}
                    fluid
                    placeholder='Confirm new password'
                    name='confirmPassword' value={confirmPassword} type='password' onChange={({ target }) => setConfirmPassword(target.value)}
                />

                <Button color='teal' fluid size='large' content='Submit'
                    loading={loading} disabled={loading}
                    onClick={handleSubmit}
                />
            </Form>
        </Segment>
    )
}

const isPasswordValid = (pass = '') => {
    return pass.trim().length > 5;
}

const LoginForm = props =>
    <LoginModal {...props}>
        <ForgotPasswordButton />
        <RegisterNewAccountButton />
    </LoginModal>