import React from 'react';
import { Button, Form, Grid, Header, Segment, Message } from 'semantic-ui-react'
import { userService } from './user.service.js';
import { LoginModal } from './LoginPage';
import ReactGA from 'react-ga'

class RegisterNewAccount extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {
                login: '',
                password: '',
                email: '',
                firstName: '',
                lastName: '',
            },
            error: '',
            accountCreated: null,
        };

        this.handleChange = this.handleChange.bind(this);
        //this.handleSubmit = this.handleSubmit.bind(this);
        this.setError = this.setError.bind(this);
        this.handleAccountCreated = this.handleAccountCreated.bind(this);

        this.loginRegEx = /^[_'.@A-Za-z0-9-]*$/;
    }

    componentDidMount() {
        ReactGA.pageview('/register_new_account')
    }

    handleChange(e) {
        const { name, value } = e.target;
        let user = { ...this.state.user };
        user[name] = value;

        this.setState({ user });
    }

    setError(message) {
        this.setState({ error: message, isLoading: false, });
    }

    handleAccountCreated(newUser) {
        this.setState({ accountCreated: newUser, isLoading: false, });
    }

    handleSubmit(e) {
        this.setState({ error: '', isLoading: true, });
        const { user } = this.state;
        console.log('RegisterNewAccount.handleCreate ', user);
        if (user.login && user.password) {
            userService.registerNewAccount(user, this.handleAccountCreated, this.setError);

        }
    }

    validateLogin(login) {
        return (login.length > 2) && this.loginRegEx.test(String(login).toLowerCase());
    }

    validatePass(pass) {
        return pass.length > 5;
    }

    validateEmail(email) {
        // eslint-disable-next-line
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    formIsValid() {
        const { user } = this.state;
        return this.validateLogin(user.login) && this.validatePass(user.password) && this.validateEmail(user.email);
    }

    render() {
        const { user, error, isLoading, accountCreated } = this.state;
        let registerForm =
            <div className='login-form'>
                <Grid
                    textAlign='center'
                    style={{ height: '100%' }}
                    verticalAlign='middle'
                >
                    <Grid.Column style={{ maxWidth: 450 }}>
                        <Header as='h2' color='teal' textAlign='center'>
                            Register new account
                        </Header>
                        <Form size='large' error={error !== ''}>
                            <Message error size='small' header={error} />
                            <Segment>
                                <Form.Input id='form-input-login' label='Login' placeholder='Login'
                                    name="login" value={user.login || ''} onChange={this.handleChange}
                                    error={!this.validateLogin(user.login)}
                                />
                                <Form.Input id='form-input-password' label='Password' placeholder='password'
                                    name="password" value={user.password || ''} type='password' onChange={this.handleChange}
                                    error={!this.validatePass(user.password)}
                                />
                                <Form.Input id='form-input-firstName' label='First Name' placeholder='First Name'
                                    name="firstName" value={user.firstName || ''} onChange={this.handleChange}
                                />
                                <Form.Input id='form-input-lastName' label='Last Name' placeholder='Last Name'
                                    name="lastName" value={user.lastName || ''} onChange={this.handleChange}
                                />
                                <Form.Input id='form-input-email' label='E-mail' placeholder='E-mail'
                                    name="email" value={user.email || ''} onChange={this.handleChange}
                                    error={!this.validateEmail(user.email)}
                                />

                                <Button color='teal' fluid size='large' onClick={(evt) => this.handleSubmit(evt)}
                                    disabled={!this.formIsValid() || isLoading}>
                                    Create
                                </Button>
                            </Segment>
                        </Form>
                    </Grid.Column>
                </Grid>
            </div>

        return (
            accountCreated ?
                <LoginModal open={true} success='Account created succesfully'
                    setLoggedUser={() => { this.props.history.push('/projects') }} />
                :
                registerForm
        )
    }
}

export { RegisterNewAccount };