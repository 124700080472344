import React from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import { Container } from 'semantic-ui-react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './App.css'
import 'font-awesome/css/font-awesome.min.css'
import 'semantic-ui-css/semantic.min.css'
import ProjectList from './components/project/ProjectList'
import OrganizationList from './components/organization/OrganizationList'
import MapComponent from './components/map/MapComponent.js'
import UserList from './components/user/UserList'
import HomePage from './components/HomePage.js'
import MainContent from './components/MainContent.js'
import { LoginModal, ForgotPasswordButton, RegisterNewAccountButton } from './components/user/LoginPage';
import { ForgotPasswordForm } from './components/user/ForgotPassword'
import { ResetPasswordForm } from './components/user/ResetPassword'
import { UserContext, defaultUser } from './user-context';
import { userService } from './components/user/user.service.js';
import { RegisterNewAccount } from './components/user/RegisterNewAccount';
import { ChangePasswordForm } from './components/user/ChangePasswordForm';
import { MatchmakingResults } from './components/matchmaking/MatchmakingResults'
import { MatchmakingDataSource } from './components/matchmaking/MatchmakingDataSource'
import Analysis from './components/analysis/Analysis'
import ReactGA from 'react-ga'
import Infrastructure from './components/infrastructure/Infrastructure';
import InfrastructureRequestList from './components/infrastructure/request/InfrastructureRequestList';

function initializeReactGA() {
    ReactGA.initialize('UA-140243611-1', {
        testMode: window.location.hostname !== 'synpro.e-science.pl',
    });
    ReactGA.pageview('/homepage');
}
class App extends React.Component {
    constructor(props) {
        super(props);

        initializeReactGA()

        const parsedUrl = new URL(window.location.href)
        this.user_token = parsedUrl.searchParams.get("user_token")
        //console.log('user_token', this.user_token)

        this.setLoggedUser = newUser => {
            console.log('App.setLoggedUser ', newUser);
            if (!newUser) {
                userService.logout();
                this.state.userContext.logout();
            }

            let userContext =
            {
                ...Object.assign({ ...defaultUser }, { ...userService.loggedUser() }),
                setLoggedUser: this.setLoggedUser,
                handleShowLoginForm: this.handleShowLoginForm,
                handleCloseLoginForm: this.handleCloseLoginForm,
            }

            if (newUser && this.state.showLoginForm) {
                //this.handleCloseLoginForm();
                this.setState({ showLoginForm: false });
            }

            this.setState(state => ({
                user: newUser,
                userContext
            }));
        };

        this.userContext = {
            ...defaultUser,
            setLoggedUser: this.setLoggedUser,
            handleShowLoginForm: this.handleShowLoginForm,
            handleCloseLoginForm: this.handleCloseLoginForm,
        };

        this.state = {
            userContext: this.userContext,
            user: null,
            showLoginForm: false,
        }


    }

    componentDidMount() {
        if (this.user_token){
            const handleFetchError = error => console.log('loginByExternalToken error', error)
            userService.loginByExternalToken(this.user_token, this.setLoggedUser, handleFetchError)
        }
    }

    handleShowLoginForm = () => this.setState({ showLoginForm: true })
    handleCloseLoginForm = () => this.setState({ showLoginForm: false })
    handleNewAccount = (e) => {
        this.props.history.push('/register_new_account')
        this.setState({ showLoginForm: false })
    }

    handleForgotPasswordClick = () => {
        this.props.history.push('/forgot-password')
        this.setState({ showLoginForm: false })
    }

    render() {
        const parsedUrl = new URL(window.location.href)
        const tokenInQuery = parsedUrl.searchParams.get("user_token")
        //console.log('App.render ', this.state.userContext, this.state.showLoginForm);
        return (
            <UserContext.Provider value={this.state.userContext}>
                <>
                    <Switch>
                        {
                            tokenInQuery && <Redirect to="/infrastructures" />
                        }
                        <Route path="/" exact component={HomePage}></Route>
                        <Route path="/projects"><MainContent><ProjectList /></MainContent></Route>
                        <Route path="/organizations"><MainContent><OrganizationList /></MainContent></Route>
                        <Route path="/map" render={(props) => <MainContent><MapComponent {...props} /></MainContent>}></Route>
                        <Route path="/register_new_account" render={props => <MainContent><RegisterNewAccount {...props} /></MainContent>}></Route>
                        <Route path="/forgot-password" render={props => <MainContent><ForgotPasswordForm {...props} /></MainContent>}></Route>
                        <Route path="/reset-password" render={props => <MainContent><ResetPasswordForm {...props} /></MainContent>}></Route>
                        <Route path="/change_password" render={props => <MainContent><ChangePasswordForm {...props} /></MainContent>}></Route>
                        <Route path="/matchmaking_results" render={(props) => <MainContent><MatchmakingResults {...props} /></MainContent>}></Route>
                        <Route path="/matchmaking_data_source" render={(props) => <MainContent><MatchmakingDataSource {...props} /></MainContent>}></Route>
                        <Route path="/users" render={(props) => <MainContent><UserList {...props} /></MainContent>}></Route>
                        <Route path="/404" component={NotFound} />
                        {/* <Route path="/loginForm" render={(props) => <MainContent><LoginPage {...props} /></MainContent>}></Route> */}
                        <Route path="/analysis" render={(props) => <MainContent><Analysis {...props} /></MainContent>}></Route>
                        <Route path="/infrastructures"><MainContent><Infrastructure /></MainContent></Route>
                        <Route path="/infrastructure-requests"><MainContent><InfrastructureRequestList /></MainContent></Route>
                        <Redirect to="/404" />
                    </Switch>

                    <ToastContainer
                        position={toast.POSITION.TOP_RIGHT}
                        closeOnClick={false}
                        autoClose={6000}
                        hideProgressBar={true}
                    />

                    <LoginModal setLoggedUser={this.setLoggedUser} open={this.state.showLoginForm} onClose={this.handleCloseLoginForm}>
                        <ForgotPasswordButton onClick={this.handleForgotPasswordClick} />
                        <RegisterNewAccountButton onClick={this.handleNewAccount} />
                    </LoginModal>
                </>
            </UserContext.Provider>
        )
    }
}

const NotFound = () => (
    <MainContent>
        <Container textAlign='center' style={{ fontSize: '2em' }}>
            Page not found
        </Container>
    </MainContent>
)

export default withRouter(App);