import React, { Component } from 'react'
import { Button, Form, Modal, Message, Container } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import client from '../../lib/client.js'
import * as utils from '../../lib/utils.js'
import { organizationFields } from './OrganizationFields.js'
import OrganizationApi from './OrganizationApi.js'
import { getInvalidFields, ToastErrorMessage } from '../../lib/validation.js'
import { Steps, StepGeneralInformation, StepCompetences, StepInfrastructure } from './StepsFormContent.js'
import { StepServices } from './StepServices.js'
import { userService } from '../user/user.service.js';
import AuthenticationService from '../authentication_service/AuthenticationService.js'
import { UserContext } from '../../user-context';
import _ from 'lodash'
import ReactGA from 'react-ga'
import '../project/ProjectCreateForm.css'

class OrganizationCreateForm extends Component {
    constructor(props) {
        super(props);

        this.organizationId = 0
        this.totalSteps = 4;
        this.state = {
            loading: true,
            currentStep: 1,
            organization: this.props.organization || {},
            invalidFields: [],
            userForDataRequest: {},
        }

        //this.handleInputChangeDebounced = e => _.debounce(this.handleInputChange, 100)
    }

    componentDidMount() {
        this.organizationId = parseInt(this.props.match.params.organizationId, 10);
        const page = document.location.pathname.replace(this.organizationId, 'editView')
        ReactGA.pageview(page)

        if (this.organizationId > 0) {
            client(`/api/organizations/${this.organizationId}`)
                .then(response => {
                    let loggedUser = userService.loggedUser();
                    let organization = Object.assign({}, response.entity, { _links: response.entity._links },
                        { loggedUserId: loggedUser ? loggedUser.id : null });

                    this.setState({
                        organization,
                    });

                    OrganizationApi.checkGeoLocationOnCannotFind(organization,
                        error => {
                            toast.info(<span>Organization geo location not found.<br />
                                Make sure the address, city, zip code and country are correct.</span>, { autoClose: 7000 });
                        }
                    )
                }, error => {
                    this.setState({ organization: undefined });
                })
                .finally(() => this.setState({ loading: false }));

            client(`/api/organizations/search/userForDataRequest?id=${this.organizationId}`)
                .then(response => this.setState({ userForDataRequest: response.entity }))
        }
        else {
            this.setState({ loading: false });
        }
    }

    isFormValid = () => this.state.invalidFields.length === 0;

    handleDismissValidationErrors = () => {
        this.setState({ invalidFields: [] });
    }

    handleSubmit = e => {
        const organization = this.state.organization;
        let invalidFields = getInvalidFields(organization, organizationFields, this.handleValidationItemClick);

        this.setState({ invalidFields: invalidFields });

        if (invalidFields.length === 0) {
            this.setState({ loading: true });

            if (this.organizationId > 0) {
                this.handleUpdateOrganization({ ...this.state.organization });
            }
            else {
                this.handleCreateOrganization({ ...this.state.organization });
            }
        }
    }

    handleCreateOrganization = (organization, e) => {
        let loggedUser = userService.loggedUser();
        if (loggedUser && loggedUser.id) {
            organization.userCreateId = loggedUser.id;
            organization.userUpdateId = loggedUser.id;
        }

        client({
            method: 'POST',
            path: '/api/organizations',
            entity: organization,
            headers: { 'Content-Type': 'application/json' }
        }).then(response => {
            this.props.onSubmit(response.entity, <span>Organization created: <em>{organization.nameEnglish}</em></span>, e);
        }, error => {
            this.setState({ loading: false });
            toast.error(<ToastErrorMessage
                message={'Failed to create organization: ' + error.entity.message}
                violations={error.entity.violations} />, { autoClose: false }
            );
        })
    }

    handleUpdateOrganization = (organization, e) => {
        let loggedUser = userService.loggedUser();
        if (loggedUser && loggedUser.id) {
            organization.userUpdateId = loggedUser.id;
        }

        client({
            method: 'PATCH',
            path: organization._links.self.href,
            entity: Object.assign({}, organization, { id: Number(_.last(organization._links.self.href.split("/"))) }),
            headers: { 'Content-Type': 'application/json' }
        }).then(response => {
            this.props.onSubmit(organization, <span>Organization updated: <em>{organization.nameEnglish}</em></span>, e);
        }, error => {
            this.setState({ loading: false });
            toast.error(<ToastErrorMessage
                message={'Failed to update organization: ' + error.entity.message}
                violations={error.entity.violations} />, { autoClose: false }
            );
        })
    }

    handleClose = e => {
        if (this.props.onClose) {
            this.props.onClose();
        }
        else {
            this.props.history.push('/organizations');
        }
    }

    handleOrganizationChange = changes => {
        let organization = Object.assign({ ...this.state.organization }, changes);
        this.setState({ organization });
    }

    handleInputChange = ({ target }) => {
        const { value, name } = target;
        //console.log('handleInputChange', name, value)

        const organization = { ...this.state.organization };
        organization[name] = value;

        this.setState({ organization });
    }

    handleDropdownChange = (evt, dropdownData) => {
        const value = Array.isArray(dropdownData.selectedValues) ? utils.multiselectArrayToString(dropdownData.selectedValues) : dropdownData.selectedValues;
        const name = dropdownData.name;

        let organization = { ...this.state.organization };
        organization[name] = value;

        this.setState({ organization });
    }

    handleStepClick = (e, stepObj) => {
        this.setState({ currentStep: stepObj['data-stepnumber'] })
    }

    handleStepProceed = e => {
        if (this.state.currentStep < this.totalSteps) {
            this.setState((prevState, props) => ({
                currentStep: prevState.currentStep + 1
            }));
        }
    }

    handleValidationItemClick = clicked => {
        if (clicked.step) {
            this.setState({ currentStep: clicked.step });
        }
    }

    setupButtons = currentStep => {
        let readOnly = !OrganizationApi.canEdit(this.state.organization)

        let submitButton = <Button color='green' key='submit' icon='checkmark'
            content='Save' onClick={this.handleSubmit} disabled={this.state.loading} />;
        let cancelButton = <Button key='cancel' color='red' icon='remove' content='Cancel' onClick={this.handleClose} />;
        let proceedButton = <Button color='blue' key='proceed' labelPosition='right' icon='chevron right'
            content='Proceed' onClick={this.handleStepProceed} disabled={this.state.loading} />;

        let buttons = [cancelButton];

        if (readOnly) {
            submitButton = null;
        }

        if (this.organizationId > 0) {
            buttons.unshift(submitButton);
        }
        else {
            switch (currentStep) {
                default:
                case 1:
                case 2:
                case 3:
                    if (!this.state.loading) {
                        buttons.unshift(proceedButton);
                    }
                    break;

                case 4:
                    buttons.unshift(submitButton);
                    break;
            }
        }
        return buttons
    }

    render() {
        if (this.state.organization === undefined) {
            return (
                <Modal open dimmer='inverted' basic>
                    <Message
                        header='Not found'
                        content='The requested organization was not found.'
                    />
                </Modal>
            )
        }

        const { currentStep } = this.state;
        const readOnly = !OrganizationApi.canEdit(this.state.organization)
        let buttons = this.setupButtons(currentStep)

        let formContent;
        switch (currentStep) {
            default:
            case 1:
                formContent =
                    <StepGeneralInformation organization={this.state.organization} userForDataRequest={this.state.userForDataRequest}
                        onInputChange={this.handleInputChange}
                        onDropdownChange={this.handleDropdownChange}
                        onOrganizationChange={this.handleOrganizationChange}
                        invalidFields={this.state.invalidFields}
                        readOnly={readOnly}
                    />
                break;

            case 2:
                formContent =
                    <StepCompetences organization={this.state.organization}
                        onInputChange={this.handleInputChange}
                        onDropdownChange={this.handleDropdownChange}
                        invalidFields={this.state.invalidFields}
                        readOnly={readOnly}
                    />
                break;

            case 3:
                formContent =
                    <StepInfrastructure organization={this.state.organization}
                        onInputChange={this.handleInputChange}
                        onDropdownChange={this.handleDropdownChange}
                        invalidFields={this.state.invalidFields}
                        readOnly={readOnly}
                    />
                break;

            case 4:
                formContent =
                    <StepServices organization={this.state.organization}
                        onDropdownChange={this.handleDropdownChange}
                        onOrganizationChange={this.handleOrganizationChange}
                        invalidFields={this.state.invalidFields}
                        readOnly={readOnly}
                    />
                break;
        }

        let formRegister =
            <Modal open dimmer={this.props.dimmer}>
                <Modal.Header>
                    {this.props.title}<span className='nameEditLabel'>{this.state.organization.nameEnglish}</span>
                </Modal.Header>

                <Steps currentStep={currentStep} onStepClick={this.handleStepClick} />

                <Form className='attached segment' loading={this.state.loading} error={!this.isFormValid()}>
                    <Message className='validationErrors'
                        error
                        size='small'
                        header='The following fields are invalid or missing'
                        list={this.state.invalidFields.map(o => o.validationClickableError)}
                        onDismiss={this.handleDismissValidationErrors}
                    />
                    <Modal.Content scrolling={[1, 3].includes(currentStep)} className='formScrollingContent'>
                        {formContent}
                    </Modal.Content>
                </Form>
                <Message attached='bottom'>
                    <Container textAlign='right'>
                        {buttons}
                    </Container>
                </Message>
            </Modal>

        let { authRequired } = this.props

        return (
            <UserContext.Consumer>
                {({ setLoggedUser }) => (
                    (userService.isAuthenticated() || !authRequired) ?
                        formRegister
                        :
                        <AuthenticationService setLoggedUser={setLoggedUser} open />
                )}
            </UserContext.Consumer>
        )
    }
}

OrganizationCreateForm.defaultProps = {
    title: 'Register your organization',
    dimmer: 'inverted',
    readOnly: true
};

export default OrganizationCreateForm