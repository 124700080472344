import React from 'react'
import { Button, Accordion, Icon } from 'semantic-ui-react'
import { graphQLFetcher } from '../graphql/GraphQLFetcher'
import GraphQlSchema from '../graphql/GraphQlSchema'

class GraphQlTestForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            graphQlSchema: null,
        }
    }

    componentDidMount() {
        this.fetchGraphQlSchema();
        //this.gQlTest()
    }

    fetchGraphQlSchema() {
        GraphQlSchema.fetchSchema(schema => {
            this.setState({ graphQlSchema: schema })
        })
    }

    gQlTest = () => {
        // const graphQLParams = {
        //     query: `{
        //             recentPosts(count:3) {
        //                 title
        //             }
        //     }`
        // }
        const graphQLParams2 = {
            query: `{
                    recentPosts(count:3) {
                        title author {name}
                    }
            }`
        }
        graphQLFetcher(graphQLParams2, 'http://localhost:9000/graphql')
    }
    gQlOrg = () => {
        const graphQLParams = {
            query: `{
                    allOrganizations(count:3) {
                        id nameEnglish country
                        createTimestamp updateTimestamp userUpdateId
                        userOwner {
                            id login activated
                        }
                    }
            }`
        }
        graphQLFetcher(graphQLParams, 'http://localhost:9000/graphql')
            .then(graphQlResult => {
                console.log('graphQLFetcher graphQlResult', graphQlResult)
            })
    }

    gQlProj = () => {
        const graphQLParams = {
            query: `{
                    allProjects(count:3) {
                        id 	title acronym

                    }
            }`
        }
        graphQLFetcher(graphQLParams, 'http://localhost:9000/graphql')
            .then(graphQlResult => {
                console.log('graphQLFetcher graphQlResult', graphQlResult)
            })
    }


    render() {
        const { graphQlSchema } = this.state;
        return (
            <div>
                <Button content='GraphQL test' onClick={() => this.gQlTest()} />
                <Button content='GraphQL org' onClick={() => this.gQlOrg()} />
                <Button content='GraphQL projects' onClick={() => this.gQlProj()} />

                <Accordion fluid styled defaultActiveIndex={0}>
                    <Accordion.Title active={true} index={0}>
                        <Icon name='dropdown' />
                        Queries
                    </Accordion.Title>
                    <Accordion.Content active={true}>
                        {
                            GraphQlSchema.getQueries(graphQlSchema).map(query =>
                                <div>
                                    {query.name}
                                </div>
                            )
                        }
                    </Accordion.Content>

                    <Accordion.Title active={true} index={1}>
                        <Icon name='dropdown' />
                        Project
                    </Accordion.Title>
                    <Accordion.Content active={true}>
                        {
                            GraphQlSchema.getEntityFieldsDef(graphQlSchema, "Project").map(field =>
                                <div>
                                    {field.name}
                                </div>
                            )
                        }
                    </Accordion.Content>

                    <Accordion.Title active={true} index={1}>
                        <Icon name='dropdown' />
                        Organization
                    </Accordion.Title>
                    <Accordion.Content active={true}>
                        {
                            GraphQlSchema.getEntityFieldsDef(graphQlSchema, "Organization").map(field =>
                                <div>
                                    {field.name}
                                </div>
                            )
                        }
                    </Accordion.Content>
                </Accordion>
            </div>
        )
    }

}

export default GraphQlTestForm