import React from 'react'
import { Table, Input, Button, Dimmer, Loader } from 'semantic-ui-react'
import { saveAs } from 'file-saver'
import * as utils from '../../lib/utils.js'
import { graphQLFetcher } from '../graphql/GraphQLFetcher'
import GraphQlSchema from '../graphql/GraphQlSchema'
import DateRangePicker from './DateRangePicker'
import FilterText from './FilterText'
import FilterDate from './FilterDate'
import './OrganizationFiltredTable.css'

const columnAuthorizeTimestamp = {
    name: 'authorizeTimestamp',
    type: 'DateTime',
}

class ProjectsFiltredTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            projects: [],
            loading: false,
            filterState: '',
            projectFieldsNames: [],
            columnsToShow: [columnAuthorizeTimestamp],
        }
    }

    componentDidMount() {
        this.setState({ loading: true, })
        this.fetchGraphQlProjects()
        this.fetchProjectDef()
    }

    fetchGraphQlProjects = () => {
        const graphQLParams = {
            query: `{
                    allProjects(count:666) {
            id title acronym fundingProgramme  startDate endDate nationalInternational totalBudget noProjectPartners projectType 
            finalizedRunning developmentStage technologyReadinessLevel projectsOutput projectsOutputDesc iprStatus webLink 
            officialProjectNumber projectSummary 
            kpaTechnologyKeywords kpaMarketKeywords additiveManufacturing3dPrinting additiveManufacturingKpaTechnologyKeywords 
            additiveManufacturingKpaMarketKeywords microNanoTechnology microNanotechnologyKpaTechnologyKeywords 
            microNanotechnologyKpaMarketKeywords industry40 
            industryKpaTechnologyKeywords industryKpaMarketKeywords userCreateId dataSource createTimestamp updateTimestamp userUpdateId 
            authorized userAuthorizedId authorizeTimestamp                     }
            }`
        }
        graphQLFetcher(graphQLParams, '/graphql')
            .then(graphQlResult => {
                //console.log('graphQLFetcher graphQlResult', graphQlResult)
                this.setState({ projects: graphQlResult.data.allProjects, loading: false, })
            })
    }

    fetchProjectDef = () => {
        GraphQlSchema.fetchSchema(schema => {
            this.setState({ graphQlSchema: schema })
            this.setState({
                projectFieldsNames: GraphQlSchema.getEntityFieldsDef(schema, "Project").map(fieldDef => fieldDef.name)
            })
        })
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    setStateForKey = (stateKey) => {
        return (value) => {
            this.setState({ [stateKey]: value });
        }
    }

    filterRangeChange = (filteredFild, from, to) => {
        console.log('OrganizationFiltredTable.filterRangeChange', filteredFild, from)
        this.setState({ [filteredFild + 'from']: from, [filteredFild + 'to']: to, })
    }
    saveToFile = () => {
        const projects = this.filterProjects()
        const fields = this.state.projectFieldsNames
        const columnsNames = (fls) => {
            return fls.reduce((acc, fieldName) => acc + fieldName + ';', '')
        }
        const columnsValues = project => {
            return fields.reduce((acc, fieldName) => acc + (project[fieldName] ? JSON.stringify(project[fieldName]) : '') + ';','')
        }
        const reducer = (acc, project) => acc + '\n' + columnsValues(project)
        const csv = projects.reduce(reducer, columnsNames(fields))
        var blob = new Blob([csv], { type: "text/plain;charset=utf-8" });
        saveAs(blob, "projects.csv");
    }

    filterProjects = () => {
        const { projects, updateTimestampfrom, updateTimestampto, createTimestampfrom, createTimestampto, } = this.state;
        const filterCreated = (org) =>
            (!createTimestampfrom || org.createTimestamp >= createTimestampfrom) &&
            (!createTimestampto || org.createTimestamp <= createTimestampto)
        const filterUpdated = org =>
            (!updateTimestampfrom || org.updateTimestamp >= updateTimestampfrom) &&
            (!updateTimestampto || org.updateTimestamp <= updateTimestampto)

        const filter = (org) => {
            return filterCreated(org) &&
                filterUpdated(org)
                && this.filterNo1(org)
        }
        return projects.filter(filter) //org => !createTimestampfrom || org.updateTimestamp >= createTimestampfrom
    }
    filterNo1 = (org) => {
        const { filterState, } = this.state;
        return !filterState || org.nameEnglish.includes(filterState)
    }

    render() {
        const { loading, updateTimestampfrom, updateTimestampto, createTimestampfrom, createTimestampto, columnsToShow, } = this.state;
        const projectsToShow = this.filterProjects()
        return (
            <div>
                {/* <Button content='GraphQL org' onClick={() => this.fetchGraphQlprojects()} /> */}
                <Button content={'Projects [' + projectsToShow.length + ']'} onClick={() => this.saveToFile()}
                    loading={loading} icon='download' />
                <Dimmer active={loading} inverted>
                    <Loader />
                </Dimmer>
                <Table celled striped sortable loading={loading}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                            >Title
                            </Table.HeaderCell>
                            <Table.HeaderCell
                            >Acronym
                            </Table.HeaderCell>
                            <Table.HeaderCell className='rangeColumn'
                            >Created</Table.HeaderCell>
                            <Table.HeaderCell className='rangeColumn'
                            >Updated</Table.HeaderCell>
                            {columnsToShow.map(column =>
                                <Table.HeaderCell>
                                    {column.name}
                            </Table.HeaderCell>)}
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell>
                                <FilterText keepStateHandler={this.setStateForKey('filterState')} state={this.state.filterState} />
                            </Table.HeaderCell>
                            <Table.HeaderCell>

                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                From
                                <Input id='form-input-createTimestampfrom' type='date'
                                    name="createTimestampfrom" value={createTimestampfrom} onChange={this.handleChange}
                                />
                                To
                                <Input id='form-input-createTimestampto' type='date'
                                    name="createTimestampto" value={createTimestampto} onChange={this.handleChange}
                                />

                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <DateRangePicker filteredFild='updateTimestamp' onChange={this.filterRangeChange}
                                    valueForm={updateTimestampfrom} valueTo={updateTimestampto} />
                            </Table.HeaderCell>
                            {columnsToShow.map(column =>
                                <Table.HeaderCell>
                                    <FilterDate />
                                </Table.HeaderCell>)}
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {projectsToShow.map(project =>
                            <Table.Row key={project.id}>
                                <Table.Cell className='preLine'>
                                    {project.title}
                                </Table.Cell>
                                <Table.Cell className='preLine'>
                                    {project.acronym}
                                </Table.Cell>
                                <Table.Cell className='preLine'>
                                    {utils.timestampCutSeconds(project.createTimestamp)}
                                </Table.Cell>
                                <Table.Cell>
                                    {utils.timestampCutSeconds(project.updateTimestamp)}
                                </Table.Cell>
                                {columnsToShow.map(column =>
                                    <Table.Cell>
                                        {project[column.name]}
                                    </Table.Cell>)}
                            </Table.Row>
                        )}
                    </Table.Body>

                    <Table.Footer>
                    </Table.Footer>
                </Table>
            </div>
        )
    }

}

export default ProjectsFiltredTable