import React from 'react'
import { Form } from 'semantic-ui-react'
import client from '../../lib/client.js'
import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classNames from 'classnames'

export default class DropdownTree extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: []
        }
    }

    componentDidMount() {
        client(this.props.dataUrl)
            .then(response => {
                this.setState({ data: this.prepareData(response.entity) })
            })
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        let shouldUpdate = !_.isEqual(nextState.data, this.state.data) ||
            nextProps.required !== this.props.required ||
            nextProps.error !== this.props.error ||
            nextProps.value !== this.props.value;
        //console.log('shouldComponentUpdate', shouldUpdate, 'nextProps', nextProps, 'nextState', nextState);
        return shouldUpdate;
    }

    prepareData = data => {
        let dataCopy = _.cloneDeep(data);
        this.processTree(dataCopy);
        return dataCopy;
    }

    processTree = data => {
        data.forEach(o => {
            // if (this.props.name === 'infrastructureIndustry40') {
            //     console.log('current', o.value, o.label, ' props ', this.props.value, 'expanded',
            //         (this.props.value.some(el => el.startsWith(o.value))), 'children', o.children);
            // }

            const isRoot = o.value.startsWith('0');

            if (this.props.value.some(el => el.startsWith(o.value) || isRoot)) {
                o.expanded = true;
            }

            if (this.props.value.includes(o.value)) {
                o.checked = true;
            }

            if (o.children && o.children.length > 0) {
                this.processTree(o.children);
            }
        });
    }

    handleChange = (currentNode, selectedNodes) => {
        //console.log("onChange", this.props.onChange, currentNode, selectedNodes);

        this.props.onChange(null, { selectedValues: selectedNodes.map(o => o.value), name: this.props.name });
    }

    render() {
        //console.log('redner kpa dropdown', this.state.data);
        const { label, name, required, readOnly, visible } = this.props;

        return (
            visible &&
            <Form.Field required={required} name={name} error={this.props.error} readOnly={readOnly}>
                <label>{label}</label>
                <DropdownTreeSelect
                    className={classNames('ui dropdown', { readOnly })}
                    data={this.prepareData(this.state.data)}
                    onChange={this.handleChange}
                    texts={{ placeholder: label }}
                    keepTreeOnSearch // Displays search results as a tree instead of flattened results
                    showPartiallySelected
                />
            </Form.Field>
        )
    }
}

DropdownTree.defaultProps = {
    value: [],
    name: 'dropdownName',
    label: 'dropdownLabel',
    visible: true
};

DropdownTree.propTypes = {
    value: PropTypes.array,
    name: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    visible: PropTypes.bool
};