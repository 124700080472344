import React from 'react';
import { Form } from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';
import Dropdown from '../../inputs/Dropdown.js';
import InputDatePicker from '../../inputs/InputDatePicker';
import { infrastructureRequestFields as fields } from './InfrastructureRequestFields';
import * as utils from '../../../lib/utils.js';

export const FormContent = ({ infrastructure, infrastructureRequest = {}, onChange, readOnly = false, invalidFields }) => {
    return (
        <>
            <Form.TextArea id='form-input-request-desc'
                label={fields.requestDescription.label}
                autoFocus control={TextareaAutosize} rows={1}
                name='requestDescription'
                value={infrastructureRequest.requestDescription || ''}
                onChange={e => utils.handleInputChange(e, onChange)}
                required
                readOnly={readOnly}
                error={invalidFields.find(o => o.name === 'requestDescription') !== undefined}
            />
            <Dropdown
                required
                onChange={(e, dropdownData) => utils.handleDropdownChange(dropdownData, onChange)}
                value={utils.multiselectStringToArray(infrastructureRequest.possibilitiesToRent)}
                label={fields.possibilitiesToRent.label}
                name='possibilitiesToRent'
                options={utils.multiselectStringToArray(infrastructure.possibilitiesToRent).map(o => ({ value: o, text: o }))}
                readOnly={readOnly}
                multiple selection
                error={invalidFields.find(o => o.name === 'possibilitiesToRent') !== undefined}
            />
            <Form.Group inline className='slotUse'>
                <label>
                    What is the possible time slot when you want to use the infrastructure?<br />
                    (e.g. from 03.2020 to 05.2020)
                </label>

                <Form.Field required error={invalidFields.find(o => o.name === 'possibleUseStartDate') !== undefined}>
                    <InputDatePicker name='possibleUseStartDate' value={infrastructureRequest.possibleUseStartDate}
                        onChange={(date, evt, name) => utils.handleDateChange(date, evt, name, onChange)}
                        readOnly={readOnly}
                    />
                    <label />
                </Form.Field>
                <Form.Field required error={invalidFields.find(o => o.name === 'possibleUseEndDate') !== undefined}>
                    <InputDatePicker name='possibleUseEndDate' value={infrastructureRequest.possibleUseEndDate}
                        onChange={(date, evt, name) => utils.handleDateChange(date, evt, name, onChange)}
                        readOnly={readOnly}
                    />
                    <label />
                </Form.Field>
            </Form.Group>
            <Form.Input id='form-input-planned-use-desc'
                label={fields.plannedUseDescription.label}
                name='plannedUseDescription'
                value={infrastructureRequest.plannedUseDescription || ''}
                onChange={e => utils.handleInputChange(e, onChange)}
                required
                readOnly={readOnly}
                error={invalidFields.find(o => o.name === 'plannedUseDescription') !== undefined}
            />
            {/* <Form.Field>
                <Checkbox
                    label={fields.isVoucherApply.label}
                    onChange={(e, { checked }) => onChange({ isVoucherApply: checked })}
                    checked={infrastructureRequest.isVoucherApply}
                />
            </Form.Field> */}
            {
                infrastructureRequest.isVoucherApply &&
                <>
                    <Form.TextArea id='form-input-voucher-apply-plan-desc'
                        label={fields.voucherApplyPlanDescription.label}
                        control={TextareaAutosize} rows={1}
                        name='voucherApplyPlanDescription'
                        value={infrastructureRequest.voucherApplyPlanDescription || ''}
                        onChange={e => utils.handleInputChange(e, onChange)}
                        readOnly={readOnly}
                        error={invalidFields.find(o => o.name === 'voucherApplyPlanDescription') !== undefined}
                    />
                    <Form.TextArea id='form-input-voucher-apply-technological-plan-desc'
                        label={fields.voucherApplyTechnologicalPotential.label}
                        control={TextareaAutosize} rows={1}
                        name='voucherApplyTechnologicalPotential'
                        value={infrastructureRequest.voucherApplyTechnologicalPotential || ''}
                        onChange={e => utils.handleInputChange(e, onChange)}
                        readOnly={readOnly}
                        error={invalidFields.find(o => o.name === 'voucherApplyTechnologicalPotential') !== undefined}
                    />
                    <Form.TextArea id='form-input-voucher-apply-benefit-analysis-desc'
                        label={fields.voucherApplyBenefitAnalysis.label}
                        control={TextareaAutosize} rows={1}
                        name='voucherApplyBenefitAnalysis'
                        value={infrastructureRequest.voucherApplyBenefitAnalysis || ''}
                        onChange={e => utils.handleInputChange(e, onChange)}
                        readOnly={readOnly}
                        error={invalidFields.find(o => o.name === 'voucherApplyBenefitAnalysis') !== undefined}
                    />
                </>
            }
            {/* <Form.Field>
                <Checkbox
                    label={fields.isParticipatedInOtherSynergyActivities.label}
                    onChange={(e, { checked }) => onChange({ isParticipatedInOtherSynergyActivities: checked })}
                    checked={infrastructureRequest.isParticipatedInOtherSynergyActivities}
                />
            </Form.Field> */}
            {
                infrastructureRequest.isParticipatedInOtherSynergyActivities &&
                <Form.TextArea id='form-input-other-synergy-activities-desc'
                    label={fields.otherSynergyActivitiesDescription.label}
                    control={TextareaAutosize} rows={1}
                    name='otherSynergyActivitiesDescription'
                    value={infrastructureRequest.otherSynergyActivitiesDescription || ''}
                    onChange={e => utils.handleInputChange(e, onChange)}
                    readOnly={readOnly}
                    error={invalidFields.find(o => o.name === 'otherSynergyActivitiesDescription') !== undefined}
                />
            }
        </>
    )
}
