import rest from 'rest'
import when from 'when'
import interceptor from 'rest/interceptor'
import defaultRequest from 'rest/interceptor/defaultRequest'
import mime from 'rest/interceptor/mime'
import errorCode from 'rest/interceptor/errorCode'
import template from 'rest/interceptor/template'
//import hateoas from 'rest/interceptor/hateoas'
import { authHeader } from './../components/user/auth-header';

let authHeaderInterceptor = interceptor({
    request: function (request, config, meta) {
        let headers = request.headers || (request.headers = {});
        Object.assign(headers, authHeader());
        return request;
    }
});

let client = rest
    .wrap(mime)
    .wrap(errorCode)
    .wrap(template, { params: { projection: '' } })
    //.wrap(hateoas)
    .wrap(defaultRequest, { headers: { 'Accept': 'application/hal+json, application/json' } })
    .wrap(authHeaderInterceptor);

let whenWrapper = (request) => {
    return when(client(request));
}

export default whenWrapper