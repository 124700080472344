import React from 'react'
import { Form } from 'semantic-ui-react'
//import client from '../../lib/client.js'
import OptionListWrapper from '../inputs/OptionListWrapper.js'
import 'react-dropdown-tree-select/dist/styles.css'

export class DropdownTotalBudget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            options: []
        };
    }

    componentDidMount() {
        OptionListWrapper.totalBudget()
            .then(res => {
                const lista = res.data;
                let result = lista.map(el => ({ value: el, text: el }));
                return result;
            })
            .then(result => {
                this.setState({ options: result });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
        // client('/data/project_total_budget.json')
        //     .then(response => {
        //         this.setState({ options: response.entity });
        //     })
        //     .finally(() => {
        //         this.setState({ loading: false });
        //     })
    }

    render() {
        let label = 'Total budget (Euro)';
        const { readOnly, ...rest } = this.props;

        return (
            <Form.Dropdown name='totalBudget' label={label} placeholder={label} options={this.state.options}
                required={!readOnly} selection loading={this.state.loading} disabled={this.state.loading || readOnly}
                className={readOnly ? 'readOnly' : null}
                {...rest}
                onChange={(evt, data) => this.props.onChange(evt, { selectedValues: data.value, name: data.name })} />
        );
    }
}

export class DropdownTechnologyReadinessLevel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            options: []
        };
    }

    componentDidMount() {
        OptionListWrapper.technologyReadinessLevel()
            .then(res => {
                const lista = res.data;
                let result = lista.map(el => ({ value: el, text: el }));
                return result;
            })
            .then(result => {
                this.setState({ options: result });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        let label = 'Technology Readiness Level (TRL)';
        const { readOnly, ...rest } = this.props;

        return (
            <Form.Dropdown name='technologyReadinessLevel' label={label} placeholder={label} options={this.state.options}
                selection loading={this.state.loading} disabled={this.state.loading || readOnly}
                className={readOnly ? 'readOnly' : null}
                {...rest} 
                onChange={(evt, data) => this.props.onChange(evt, { selectedValues: data.value, name: data.name })} />
        );
    }
}

export class DropdownProjectType extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            options: []
        };
    }

    componentDidMount() {
        OptionListWrapper.projectType()
            .then(res => {
                const lista = res.data;
                let result = lista.map(el => ({ value: el, text: el }));
                return result;
            })
            .then(result => {
                this.setState({ options: result });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        let label = 'Type of project';
        const { readOnly, ...rest } = this.props;

        return (
            <Form.Dropdown name='projectType' label={label} placeholder={label} options={this.state.options}
                multiple selection
                loading={this.state.loading} disabled={this.state.loading || readOnly}
                className={readOnly ? 'readOnly' : null}
                {...rest}
                onChange={(evt, data) => this.props.onChange(evt, { selectedValues: data.value, name: data.name })} />
        );
    }
}

export class DropdownNationalInternational extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            options: []
        };
    }

    componentDidMount() {
        //OptionListWrapper.forField('national_international');
        //this.setState({ options: OptionListWrapper.forField('national_international') });

        OptionListWrapper.nationalInternational()
            .then(res => {
                const lista = res.data;
                let result = lista.map(el => ({ value: el, text: el }));
                return result;
            })
            .then(result => {
                this.setState({ options: result });
            })
            .finally(() => {
                this.setState({ loading: false });
            });

        // client('/data/project_national_international.json')
        //     .then(response => {
        //         this.setState({ options: response.entity });
        //     })
        //     .finally(() => {
        //         this.setState({ loading: false });
        //     });
    }

    render() {
        let label = 'National/International';
        const { readOnly, ...rest } = this.props;

        return (
            <Form.Dropdown name='nationalInternational' label={label} placeholder={label} options={this.state.options}
                selection loading={this.state.loading} disabled={this.state.loading || readOnly}
                className={readOnly ? 'readOnly' : null}
                {...rest}
                onChange={(evt, data) => this.props.onChange(evt, { selectedValues: data.value, name: data.name })} />
        );
    }
}

export class DropdownFinalizedRunning extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            options: []
        };
    }

    componentDidMount() {
        OptionListWrapper.finalizedRunning()
            .then(res => {
                const lista = res.data;
                let result = lista.map(el => ({ value: el, text: el }));
                return result;
            })
            .then(result => {
                this.setState({ options: result });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        let label = 'Status';
        const { readOnly, ...rest } = this.props;

        return (
            <Form.Dropdown name='finalizedRunning' label={label} placeholder={label} options={this.state.options}
                required={!readOnly} selection loading={this.state.loading} disabled={this.state.loading || readOnly}
                className={readOnly ? 'readOnly' : null}
                {...rest} 
                onChange={(evt, data) => this.props.onChange(evt, { selectedValues: data.value, name: data.name })} />
        );
    }
}

export class DropdownDevelopmentStage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            options: []
        };
    }

    componentDidMount() {
        OptionListWrapper.developmentStage()
            .then(res => {
                const lista = res.data;
                let result = lista.map(el => ({ value: el, text: el }));
                return result;
            })
            .then(result => {
                this.setState({ options: result });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        let label = 'Stage of development (simplified TRL)';
        const { readOnly, ...rest } = this.props;

        return (
            <Form.Dropdown name='developmentStage' label={label} placeholder={label} options={this.state.options}
                required={!readOnly} selection loading={this.state.loading} disabled={this.state.loading || readOnly}
                className={readOnly ? 'readOnly' : null}
                {...rest} 
                onChange={(evt, data) => this.props.onChange(evt, { selectedValues: data.value, name: data.name })} />
        );
    }
}

export class DropdownProjectsOutput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            options: []
        };
    }

    componentDidMount() {
        OptionListWrapper.projectsOutput()
            .then(res => {
                const lista = res.data;
                let result = lista.map(el => ({ value: el, text: el }));
                return result;
            })
            .then(result => {
                this.setState({ options: result });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        let label = 'Project Outputs';
        const { readOnly, ...rest } = this.props;

        return (
            <Form.Dropdown name='projectsOutput' label={label} placeholder={label} options={this.state.options}
                multiple selection
                loading={this.state.loading} disabled={this.state.loading || readOnly}
                className={readOnly ? 'readOnly' : null}
                {...rest}  
                onChange={(evt, data) => this.props.onChange(evt, { selectedValues: data.value, name: data.name })} />
        );
    }
}

export class DropdownIprStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            options: []
        };
    }

    componentDidMount() {
        OptionListWrapper.iprStatus()
            .then(res => {
                const lista = res.data;
                let result = lista.map(el => ({ value: el, text: el }));
                return result;
            })
            .then(result => {
                this.setState({ options: result });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        let label = 'IPR Status';
        const { readOnly, ...rest } = this.props;

        return (
            <Form.Dropdown name='iprStatus' label={label} placeholder={label} options={this.state.options}
                selection loading={this.state.loading} disabled={this.state.loading || readOnly}
                className={readOnly ? 'readOnly' : null}
                {...rest}  
                onChange={(evt, data) => this.props.onChange(evt, { selectedValues: data.value, name: data.name })} />
        );
    }
}