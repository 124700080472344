import React, { useState } from 'react';
import { Button, Form, Message, Segment, Header } from 'semantic-ui-react'
import { userService } from './user.service.js';

export const ForgotPasswordForm = props => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');

    const handleSubmit = evt => {
        if (email.trim() === '') {
            setErrorMsg(`Enter your user account's email address`)
            return
        }

        setErrorMsg('');
        setLoading(true);

        userService.requestResetPassword(email,
            resp => { setLoading(false); setSuccessMsg(resp.message) },
            resp => { setLoading(false); setErrorMsg(resp.error) }
        );
    }

    return (
        <Segment className='forgotPassword'>
            <Header as='h2' color='teal' textAlign='center'>
                Reset your password
            </Header>
            <Header as='h4' textAlign='center'>
                Enter your user account's email address and we will send you a password reset link.
            </Header>
            <Form error={errorMsg !== ''} success={successMsg !== ''}>
                <Message error size='small' header={errorMsg} />
                <Message success size='small' header={successMsg} />

                <Form.Input
                    readOnly={loading}
                    fluid icon='mail'
                    iconPosition='left'
                    placeholder='Enter your email address'
                    name='email' value={email} onChange={({ target }) => setEmail(target.value)}
                    autoFocus
                />

                <Button color='teal' fluid size='large' content='Send password reset email'
                    loading={loading} disabled={loading || successMsg !== ''}
                    onClick={handleSubmit}
                />
            </Form>
        </Segment>
    )
}