import fetch from 'isomorphic-fetch';

export function graphQLFetcher(graphQLParams, url) {
    if (url === undefined) {
        url = '/graphql'  //window.location.origin + 
    }
    return fetch(url, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(graphQLParams),
    }).then(response => response.json());
}

