import React from 'react'
import { Link } from 'react-router-dom'
import { Table, Image, List } from 'semantic-ui-react'
import { Typography } from 'antd';
import '../project/ProjectList.css'
import { DataTable } from '../DataTable';
import * as utils from '../../lib/utils.js';
import InfrastructureApi from './InfrastructureApi';

const InfrastructureTable = ({ infrastructures, loading, sort, ...rest }) => (

    <DataTable data={infrastructures} loading={loading} sortMetaData={sort} footerColSpan={5} {...rest}
        header={({ sortMetaData, onSort }) => (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell sorted={sortMetaData.field === 'name' ? sortMetaData.direction : null}
                        onClick={evt => onSort('name', evt)}>
                        Name
                    </Table.HeaderCell>
                    <Table.HeaderCell sorted={sortMetaData.field === 'description' ? sortMetaData.direction : null}
                        onClick={evt => onSort('description', evt)}>
                        Description
                    </Table.HeaderCell>
                    {/* onClick={evt => onSort('organizationNameEnglish', evt)} sorted={sortMetaData.field === 'organizationNameEnglish_todo' ? sortMetaData.direction : null} */}
                    <Table.HeaderCell>
                        Organization
                    </Table.HeaderCell>
                    <Table.HeaderCell sorted={sortMetaData.field === 'possibilitiesToRent' ? sortMetaData.direction : null}
                        onClick={evt => onSort('possibilitiesToRent', evt)}>
                        Possibilities of use
                    </Table.HeaderCell>
                    <Table.HeaderCell sorted={sortMetaData.field === 'updateTimestamp' ? sortMetaData.direction : null}
                        onClick={evt => onSort('updateTimestamp', evt)}>
                        Updated
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )}
        body={({ data, onDelete }) => (
            <Table.Body>
                {data.map(infrastructure => {
                    /* let loggedUser = userService.loggedUser();
                    const canEdit = userService.isAdmin() || userService.isAuthorizing()
                        || (loggedUser && loggedUser.id === infrastructure.userCreateId); */

                    return (
                        <Table.Row key={infrastructure._links.self.href}>
                            <Table.Cell className='preLine'>
                                <Link to={`/infrastructures/${InfrastructureApi.infrastructureId(infrastructure)}`}
                                    title='Preview'>
                                    {infrastructure.name}
                                    {infrastructure.image && <Image src={infrastructure.image.url} className='infrastructureListOverview'
                                        size='small' bordered rounded />}
                                </Link>
                            </Table.Cell>
                            <Table.Cell className='preLine'>
                                <Typography.Paragraph ellipsis={{ rows: 5, expandable: false }}>
                                    {infrastructure.description}
                                </Typography.Paragraph>
                            </Table.Cell>
                            <Table.Cell className='preLine'>
                                {infrastructure.organizationNameEnglish}
                            </Table.Cell>
                            <Table.Cell className='preLine'>
                                <List items={infrastructure.possibilitiesToRent} />
                            </Table.Cell>
                            <Table.Cell className='preLine' textAlign='center' width='2'>
                                {utils.timestampCutSeconds(infrastructure.updateTimestamp)}
                            </Table.Cell>
                        </Table.Row>
                    )
                })}
            </Table.Body>
        )}
    />
)

export default InfrastructureTable