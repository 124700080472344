import React, { Component } from 'react'
import { Form } from 'semantic-ui-react'
import client from '../../lib/client.js'
import { isFieldValid } from '../../lib/validation.js'
import * as utils from '../../lib/utils.js'
import PropTypes from 'prop-types';

class CountryRegionGroup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            countryLoading: true,
            countryOptions: [],
            regionLoading: true,
            regionOptions: []
        }
    }

    componentDidMount() {
        this.loadCountries().then(() => {
            const { entity } = this.props;
            //console.log('CountryRegionGroup componentDidMount', entity)
            if (entity.country) {
                this.loadRegions(entity.country)
            }
        })
    }

    componentDidUpdate(prevProps) {
        const { entity } = this.props;
        const prevEntity = prevProps.entity;
        if (entity.country !== prevEntity.country) {
            //console.log('CountryRegionGroup componentDidUpdate', entity.country, prevEntity.country)
            entity.country && this.loadRegions(entity.country)
        }
    }

    loadCountries() {
        return client('/api/dictionary/country/dropdown')
            .then(response => {
                this.setState({ countryOptions: response.entity });
            })
            .finally(() => {
                this.setState({ countryLoading: false });
            });
    }

    loadRegions(countryCode) {
        client('/api/dictionary/country/' + countryCode + '/dropdown')
            .then(response => {
                let regions = response.entity.sort((a, b) => { return a.text.localeCompare(b.text); });
                this.setState({ regionOptions: regions });
            })
            .finally(() => {
                this.setState({ regionLoading: false });
            });
    }

    handleCountryChange = (evt, dropdownData) => {
        utils.handleDropdownChange(dropdownData, this.props.onChange);
    }

    render() {
        const { entity, readOnly, onChange, countryPlaceholder, countryLabel, countryValidations,
            regionPlaceholder, regionLabel, invalidFields, countryRequired = false } = this.props;

        const countryError = invalidFields ?
            invalidFields.find(o => o.name === 'country') !== undefined // lazy validation
            :
            countryValidations && !isFieldValid(entity.country, countryValidations, entity); // eager validation

        return (
            <Form.Group widths='equal'>
                <Form.Dropdown
                    name='country' label={<label>{countryLabel}</label>}
                    placeholder={countryPlaceholder || countryLabel}
                    options={this.state.countryOptions}
                    value={entity.country}
                    search selection
                    loading={this.state.countryLoading} disabled={this.state.countryLoading || readOnly}
                    onChange={this.handleCountryChange}
                    className={readOnly ? 'readOnly' : null}
                    required={countryRequired && !readOnly}
                    error={countryError}
                />
                <Form.Dropdown name='region' label={<label>{regionLabel}</label>}
                    placeholder={regionPlaceholder || regionLabel}
                    options={this.state.regionOptions}
                    value={entity.region}
                    search selection
                    loading={this.state.loading} disabled={this.state.regionLoading || readOnly}
                    onChange={(e, dropdownData) => utils.handleDropdownChange(dropdownData, onChange)}
                    className={readOnly ? 'readOnly' : null}
                />
            </Form.Group>
        )
    }
}

export default CountryRegionGroup;

CountryRegionGroup.propTypes = {
    entity: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    countryLabel: PropTypes.string,
    countryPlaceholder: PropTypes.string,
    regionLabel: PropTypes.string,
    regionPlaceholder: PropTypes.string,
}