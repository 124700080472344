import _ from 'lodash'

const multiselectValueSeparator = ',';

export function multiselectStringToArray(valueString, separator) {
    if (!valueString) {
        return [];
    }
    if (!separator) {
        separator = multiselectValueSeparator;
    }
    return valueString.split(separator);
}

export function multiselectArrayToString(valueArray, separator) {
    if (!valueArray) {
        return '';
    }
    if (!separator) {
        separator = multiselectValueSeparator;
    }
    return valueArray.join(separator);
}

export const handleInputChange = ({ target }, callback) => {
    const { value, name } = target;
    callback({ [name]: value });
}

export const handleDropdownChange = (dropdownData, callback) => {
    let value = Array.isArray(dropdownData.selectedValues) ? multiselectArrayToString(dropdownData.selectedValues) : dropdownData.value;
    if (typeof value === "undefined" && dropdownData.selectedValues) {
        value = dropdownData.selectedValues
    }
    const name = dropdownData.name;
    callback({ [name]: value });
}

export const handleDateChange = (momentObj, { target }, name, callback) => {
    const value = momentObj.format('YYYY-MM-DD');
    callback({ [name]: value });
}

/**
 * Skraca standardowy timestamp do minut
 * @param {string} timestamp timestamp formatu "yyyy-MM-dd'T'HH:mm:ss.SSS"
 * @returns {string} timestamp skrócony do "yyyy-MM-dd' 'HH:mm"
 * @example
 * utils.timestampCutSeconds('2019-01-23T11:42:56.234')
 * // returns '2019-01-23 11:42'
 */
export function timestampCutSeconds(timestamp) {
    return _.truncate(_.replace(timestamp, 'T', ' '), { 'length': 16, 'omission': '' })
}

export function hashCode(str) {
    for (var i = 0, h = 0; i < str.length; i++)
        h = Math.imul(31, h) + str.charCodeAt(i) | 0;
    return h;
}

export function hashCodeObject(obj) {
    const hash = Object.values(obj).filter(val => typeof (val) !== 'function').reduce((a, b) => a + b, '') //.map(val => '' + val)
    return hashCode(hash)
}

export const formFieldLength = (str = '') => (str && str.length) || 0;