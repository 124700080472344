import React from 'react'
import { Input, Button } from 'semantic-ui-react'

class FilterText extends React.Component {

    handleChange = (e) => {
        const { value } = e.target;
        this.props.keepStateHandler(value);
    }

    clearFilter = () => this.props.keepStateHandler('')

    render() {
        const { state } = this.props;
        return (
            <div>
                <Input value={state} onChange={this.handleChange} />
                <Button onClick={this.clearFilter} icon='close' />
            </div>
        )
    }

}

export default FilterText