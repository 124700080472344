import React from 'react'
import { Button } from 'semantic-ui-react'
import { useHistory } from 'react-router';
import { LoginModal, ForgotPasswordButton, RegisterNewAccountButton } from '../user/LoginPage';

const AuthenticationService = props => {
    console.log('AuthenticationService ', props);
    const history = useHistory();

    return (
        <LoginModal {...props}>
            <Button content='Back' onClick={() => history.goBack()} />
            <ForgotPasswordButton />
            <RegisterNewAccountButton />
        </LoginModal>
    )
}

export default AuthenticationService;
