import React, { Component } from 'react'
import { Form, Button, Modal, Message, Container } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import _ from 'lodash'
import client from '../../lib/client.js'
import when from 'when'
import * as utils from '../../lib/utils.js'
import { projectFields } from './ProjectFields.js'
import { getInvalidFields, ToastErrorMessage } from '../../lib/validation.js'
import { Steps, StepGeneralInformation, StepResultsAndTrl, StepProjectProfiling } from './StepsFormContent.js'
import './ProjectCreateForm.css'
import ProjectPartersList from './ProjectPartners.js'
import OrganizationCreateForm from '../organization/OrganizationCreateForm.js'
import AuthenticationService from '../authentication_service/AuthenticationService.js'
import { Route } from 'react-router-dom'
import { UserContext } from '../../user-context';
import { userService } from '../user/user.service.js';
import ReactGA from 'react-ga'

class ProjectCreateForm extends Component {
    constructor(props) {
        super(props);

        this.totalSteps = 4;
        this.projectId = 0;
        this.state = {
            loading: true,
            readOnly: this.props.readOnly || true,
            currentStep: 1,
            project: this.props.project || {},
            projectPartners: [],
            invalidFields: []
        }
    }

    componentDidMount() {
        this.projectId = parseInt(this.props.match.params.id, 10);
        const page = document.location.pathname.replace(this.projectId, 'editView')
        ReactGA.pageview(page)
        if (this.projectId > 0) {
            client(`/api/projects/${this.projectId}`)
                .then(
                    response => {
                        let loggedUser = userService.loggedUser();
                        let project = Object.assign({}, response.entity, { _links: response.entity._links },
                            { loggedUserId: loggedUser ? loggedUser.id : null });

                        const canEdit =
                            userService.isAdmin() || userService.isAuthorizing() || (loggedUser && loggedUser.id === project.userCreateId);

                        this.setState({
                            project,
                            readOnly: !canEdit
                        });

                        return client(`/api/projects/${this.projectId}/projectPartners?projection=inlineOrganization`);
                    }, error => {
                        this.setState({ project: undefined });
                    })
                .then(
                    response => this.setState({ projectPartners: response.entity._embedded.projectPartners })
                )
                .finally(() => this.setState({ loading: false }));
        }
        else {
            this.setState({ loading: false, readOnly: false });
        }
    }

    isFormValid = () => this.state.invalidFields.length === 0;

    handleDismissValidationErrors = () => {
        this.setState({ invalidFields: [] });
    }

    handleSubmit = (e) => {
        let invalidFields = getInvalidFields(this.state.project, projectFields, this.handleValidationItemClick);

        this.setState({ invalidFields: invalidFields });

        if (invalidFields.length === 0) {
            this.setState({ loading: true });
            let project = {
                ...this.state.project,
                projectPartners: this.state.projectPartners
            };

            if (this.projectId > 0) {
                this.handleUpdateProject(project);
            }
            else {
                this.handleCreateProject(project);
            }
        }
    }

    handleCreateProject = (project, e) => {
        let loggedUser = userService.loggedUser();
        if (loggedUser && loggedUser.id) {
            project.userCreateId = loggedUser.id;
            project.userUpdateId = loggedUser.id;
        }
        client({
            method: 'POST',
            path: '/api/projects',
            entity: project,
            headers: { 'Content-Type': 'application/json' }
        }).then(response => {
            this.props.onSubmit(project, <span>Project created: <em>{project.title}</em></span>, e);
        }, error => {
            this.setState({ loading: false });
            toast.error(<ToastErrorMessage
                message={'Failed to create project: ' + error.entity.message}
                violations={error.entity.violations} />, { autoClose: false }
            );
        })
    }

    handleUpdateProject = (project, e) => {
        let loggedUser = userService.loggedUser();
        if (loggedUser && loggedUser.id) {
            project.userUpdateId = loggedUser.id;
        }
        client({
            method: 'PUT',
            path: project._links.self.href,
            entity: project,
            headers: { 'Content-Type': 'application/json' }
        }).then(response => {
            this.props.onSubmit(project, <span>Project updated: <em>{project.title}</em></span>, e);
        }, error => {
            this.setState({ loading: false });
            toast.error(<ToastErrorMessage
                message={'Failed to update project: ' + error.entity.message}
                violations={error.entity.violations} />, { autoClose: false }
            );
        })
    }

    handleClose = (e) => {
        this.props.history.push('/projects'); // history jest obiektem z react routera
    }

    handleProjectChange = (changes) => {
        let project = Object.assign({ ...this.state.project }, changes);
        this.setState({ project });
    }

    handleInputChange = ({ target }) => {
        let value = target.value;
        const name = target.name;

        if (name === 'noProjectPartners') {
            value = parseInt(value, 10);
        }

        let project = { ...this.state.project };
        project[name] = value;

        // let invalidField = this.state.invalidFields.find(o => o.name === name);
        // if (invalidField && isFieldValid(value, invalidField.validations)) {
        //     let invalidFields = this.state.invalidFields.filter(o => o.name !== invalidField.name);
        //     this.setState({ invalidFields });
        // }

        this.setState({ project });
    }

    handleDropdownChange = (evt, dropdownData) => {
        //console.log('handleDropdownChange', evt, dropdownData);

        const value = Array.isArray(dropdownData.selectedValues) ? utils.multiselectArrayToString(dropdownData.selectedValues) : dropdownData.selectedValues;
        const name = dropdownData.name;

        let project = { ...this.state.project };
        project[name] = value;

        //console.log('handleDropdownChange project', project);
        this.setState({ project });
    }

    handleDateChange = (momentObj, event, name) => {
        //console.log('dateChange', momentObj, event.target, name);
        let project = { ...this.state.project };
        project[name] = momentObj.format('YYYY-MM-DD');

        this.setState({ project });
    }

    handleProjectPartnerChange = (e, obj) => {
        //console.log('handleProjectPartnerChange', obj, obj.value);

        let projectPartners = _.cloneDeep(this.state.projectPartners);
        projectPartners.forEach(o => o.leadPartner = o.organizationId === obj.value && obj.checked);

        if (this.projectId > 0) {
            let modified = projectPartners.filter((o, i) => this.state.projectPartners[i].leadPartner !== o.leadPartner);

            when.all(modified.map(partner =>
                client({
                    method: 'PUT',
                    path: `/api/projectPartners/${partner.id}`,
                    entity: Object.assign({}, partner, { organization: null }, { projectId: this.projectId }),
                    headers: { 'Content-Type': 'application/json' }
                })
            )).done(result => {
                this.setState({ projectPartners });
            })
        }
        else {
            this.setState({ projectPartners });
        }
    }

    handleProjectPartnerAdd = (e, toAdd) => {
        let organizationIdToAdd = toAdd.id; //parseInt(_.last(toAdd.id.split("/")), 10);
        let projectPartners = _.cloneDeep(this.state.projectPartners);
        let partnerExists = projectPartners.findIndex(o => o.organizationId === organizationIdToAdd) >= 0;
        let newProjectPartner = {
            leadPartner: false,
            organizationId: organizationIdToAdd,
            organization: toAdd.organization
        };

        //console.log('handleProjectPartnerAdd', toAdd);
        //console.log('partnerExists', partnerExists, 'projectPartners', projectPartners);

        let refreshPartners = (obj) => {
            projectPartners.push(obj);
            this.setState({ projectPartners });
        }

        if (!partnerExists && organizationIdToAdd > 0) {
            if (this.projectId > 0) {
                this.setState({ loading: true });
                client({
                    method: 'POST', path: '/api/projectPartners', headers: { 'Content-Type': 'application/json' },
                    entity: Object.assign({}, newProjectPartner, { projectId: this.projectId }, { organization: null })
                }).then(response => {
                    let newPartnerId = parseInt(_.last(response.entity._links.self.href.split("/")), 10);
                    //console.log('newPartnerId', newPartnerId);
                    refreshPartners(Object.assign({}, newProjectPartner, { id: newPartnerId }, { _links: response.entity._links }));
                }).finally(() => this.setState({ loading: false }));
            }
            else {
                refreshPartners(newProjectPartner);
            }
        }
        else {
            toast.info(<span>Project partner already added: {newProjectPartner.organization.nameEnglish}</span>);
        }
    }

    handleProjectPartnerRemove = (e, toRemove) => {
        //console.log('handleProjectPartnerRemove', toRemove, e.target);

        let refreshPartners = () => {
            let projectPartners = this.state.projectPartners.filter(o => o.organizationId !== toRemove.organizationId);
            this.setState({ projectPartners });
        }

        if (toRemove.id > 0) {
            this.setState({ loading: true });
            client({ method: 'DELETE', path: toRemove._links.self.href })
                .then(response => refreshPartners())
                .finally(() => this.setState({ loading: false }));
        }
        else {
            refreshPartners();
        }
    }

    handleValidationItemClick = (clicked) => {
        if (clicked.step) {
            this.setState({ currentStep: clicked.step });
        }
    }

    handleStepClick = (e, stepObj) => {
        //console.log('step click ', e.target.dataset, stepObj);
        this.setState({ currentStep: stepObj['data-stepnumber'] })
    }

    handleStepProceed = (e) => {
        if (this.state.currentStep < this.totalSteps) {
            this.setState((prevState, props) => ({
                currentStep: prevState.currentStep + 1
            }));
        }
    }

    handleProjectPartnerCreate = (newOrganization, e) => {
        //console.log('handleProjectPartnerCreate', newOrganization);

        toast.success(<span>Organization created: <em>{newOrganization.nameEnglish}</em></span>);
        this.props.history.push(`/projects/${this.projectId > 0 ? this.projectId : 'register'}`);

        let newProjectPartner = {
            id: newOrganization.id, // newOrganization._links.self.href,
            description: newOrganization.contactPerson,
            title: newOrganization.nameEnglish,
            organization: { ...newOrganization }
        };
        this.handleProjectPartnerAdd(e, newProjectPartner);
    }

    render() {
        if (this.state.project === undefined) {
            return (
                <Modal open dimmer='inverted' basic>
                    <Message
                        header='Not found'
                        content='The requested project was not found.'
                    />
                </Modal>
            )
        }

        const { currentStep } = this.state;

        let submitButton = <Button color='green' key='submit' icon='checkmark'
            content='Save' onClick={this.handleSubmit} disabled={this.state.loading} />;
        let cancelButton = <Button key='cancel' color='red' icon='remove' content='Cancel' onClick={this.handleClose} />;
        let proceedButton = <Button color='blue' key='proceed' labelPosition='right' icon='chevron right'
            content='Proceed' onClick={this.handleStepProceed} disabled={this.state.loading} />;

        let buttons = [cancelButton];

        if (this.state.readOnly) {
            submitButton = null;
        }

        if (this.projectId > 0) {
            buttons.unshift(submitButton);
        }
        else {
            switch (currentStep) {
                default:
                case 1:
                case 2:
                case 3:
                    if (!this.state.loading) {
                        buttons.unshift(proceedButton);
                    }
                    break;

                case 4:
                    buttons.unshift(submitButton);
                    break;
            }
        }

        let formContent;
        switch (currentStep) {
            default:
            case 1:
                formContent =
                    <StepGeneralInformation project={this.state.project}
                        onProjectChange={this.handleProjectChange}
                        onInputChange={this.handleInputChange}
                        onDateChange={this.handleDateChange}
                        onDropdownChange={this.handleDropdownChange}
                        invalidFields={this.state.invalidFields}
                        readOnly={this.state.readOnly}
                        setAuthorized={this.setAuthorized}
                    />
                break;

            case 2:
                formContent =
                    <StepResultsAndTrl project={this.state.project}
                        onInputChange={this.handleInputChange}
                        onDateChange={this.handleDateChange}
                        onDropdownChange={this.handleDropdownChange}
                        invalidFields={this.state.invalidFields}
                        readOnly={this.state.readOnly}
                    />
                break;

            case 3:
                formContent =
                    <StepProjectProfiling
                        project={this.state.project}
                        onInputChange={this.handleInputChange}
                        onDateChange={this.handleDateChange}
                        onDropdownChange={this.handleDropdownChange}
                        invalidFields={this.state.invalidFields}
                        onProjectChange={this.handleProjectChange}
                        readOnly={this.state.readOnly}
                    />
                break;

            case 4:
                formContent =
                    <ProjectPartersList
                        {...this.props}
                        readOnly={this.state.readOnly}
                        project={this.state.project}
                        projectPartners={this.state.projectPartners}
                        onProjectPartnerChange={this.handleProjectPartnerChange}
                        onProjectPartnerAdd={this.handleProjectPartnerAdd}
                        onProjectPartnerRemove={this.handleProjectPartnerRemove}
                    />
                break;
        }

        let formRegister =
            <Modal open dimmer='inverted' size={currentStep === 4 ? 'large' : null}>
                <Modal.Header>
                    {this.props.title}<span className='nameEditLabel'>{this.state.project.acronym}</span>
                </Modal.Header>

                <Steps currentStep={currentStep} onStepClick={this.handleStepClick} />

                <Form className='attached segment' loading={this.state.loading} error={!this.isFormValid()}>
                    <Message className='validationErrors'
                        error
                        size='small'
                        header='The following fields are invalid or missing'
                        list={this.state.invalidFields.map(o => o.validationClickableError)}
                        onDismiss={this.handleDismissValidationErrors}
                    />
                    <Modal.Content scrolling={[1, 2, 3].includes(currentStep)} className='formScrollingContent'>
                        {formContent}

                        <Route path="/projects/:id/partners/register"
                            render={(props) =>
                                <OrganizationCreateForm
                                    {...props}
                                    onSubmit={this.handleProjectPartnerCreate}
                                    onClose={() => props.history.push(`/projects/${this.projectId > 0
                                        ? this.projectId : 'register'}`)}
                                />
                            }>
                        </Route>
                    </Modal.Content>
                </Form>
                <Message attached='bottom'>
                    <Container textAlign='right'>
                        {buttons}
                    </Container>
                </Message>
            </Modal>

        return (
            <UserContext.Consumer>
                {({ setLoggedUser }) => (
                    this.state.readOnly || userService.isAuthenticated() ?
                        formRegister
                        :
                        <AuthenticationService setLoggedUser={setLoggedUser} open />
                )}
            </UserContext.Consumer>
        )
    }
}

ProjectCreateForm.defaultProps = {
    title: 'Register your project',
    readOnly: true
};

export default ProjectCreateForm