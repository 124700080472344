import React, { useState, useRef } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Button, Icon, Loader, Image } from 'semantic-ui-react';
import InfrastructureCreateForm from './InfrastructureCreateForm';
import { DataList } from '../DataList';
import { Map, Marker, Popup, ZoomControl } from 'react-leaflet';
import 'react-leaflet-markercluster/dist/styles.min.css';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { useHistory } from 'react-router';
import { DefaultTileLayer } from '../map/MapView';
import { RegisterInfrastructureButton } from './InfrastructureList';
import { ToggleViewMode } from './Infrastructure';
import Control from 'react-leaflet-control';
import classNames from 'classnames';

const InfrastructureMap = ({ apiDataFetchUrl, searchEntity, ...rest }) => {
    return (
        <div className='infrastructure-map'>
            <DataList apiDataFetchUrl={apiDataFetchUrl} method='POST' apiEntityName='infrastructures' searchEntity={searchEntity}>
                {({ loading, data, onCreate, onUpdate }) =>
                    <>
                        <MapView infrastructures={data.filter(o => o.geoSearchData)} loading={loading} {...rest} />
                        <Routes onCreate={onCreate} onUpdate={onUpdate} />
                    </>}
            </DataList>
        </div>
    )
}

const DEFAULT_VIEWPORT = {
    center: [48, 10],
    zoom: 5
}

const MapView = ({ infrastructures, loading, viewMode, handleViewModeChange }) => {
    const [viewport] = useState(DEFAULT_VIEWPORT);
    const mapEl = useRef(null);

    return (
        <Map viewport={viewport} minZoom={2} maxZoom={18} zoomControl={false} ref={mapEl}>
            <Loader active={loading} />
            <DefaultTileLayer />

            <Control position='topright' className='infrastructure'>
                <RegisterInfrastructureButton />
            </Control>
            <Control position='topleft' className='infrastructure'>
                <ToggleViewMode viewMode={viewMode} onChange={handleViewModeChange} />
            </Control>

            <ZoomControl position='bottomright' />

            <MarkerClusterGroup maxClusterRadius={40}>
                {
                    infrastructures.map(infrastructure =>
                        <InfrastructureMarker
                            key={infrastructure.id}
                            position={[infrastructure.geoSearchData.lat, infrastructure.geoSearchData.lon]}
                            infrastructure={infrastructure}
                            mapEl={mapEl}
                        />
                    )
                }
            </MarkerClusterGroup>
        </Map>
    )
}

const InfrastructureMarker = ({ position, infrastructure, mapEl }) => {
    const markerEl = useRef(null);
    const history = useHistory();

    return (
        <Marker position={position} title={infrastructure.name} ref={markerEl}>
            <Popup className={classNames({ withImage: infrastructure.image })}>
                <div>
                    <div className='header'>{infrastructure.name}</div>
                    <div className={classNames('content', { withImage: infrastructure.image })}>
                        {infrastructure.image && <Image floated='left'
                            src={infrastructure.image.url} size='small' bordered rounded
                        />}
                        <span>{infrastructure.geoSearchData.displayName}</span>
                    </div>
                    <div className='buttons'>
                        <Button.Group basic compact size='tiny'>
                            <Button icon='edit' basic compact size='tiny' title='Details'
                                onClick={() => history.push(`/infrastructures/${infrastructure.id}`)}
                            />
                            <Button icon='location arrow' basic compact size='tiny' title='Go to location'
                                onClick={() => mapEl.current.leafletElement.setView(position, 15)}
                            />
                        </Button.Group>
                    </div>
                </div>
            </Popup>
        </Marker>
    )
}

const Routes = ({ onCreate, onUpdate }) => (
    <Switch>
        <Route path="/infrastructures/register" exact render={props =>
            <InfrastructureCreateForm {...props} authRequired onSubmit={onCreate} />
        } />
        <Route path='/infrastructures/:infrastructureId' render={props =>
            <InfrastructureCreateForm {...props} onSubmit={onUpdate}
                title={<span><Icon name='edit' /> Infrastructure</span>} />
        } />
    </Switch>
)

export default InfrastructureMap