import React from 'react'
import { Icon, Popup } from 'semantic-ui-react'

const InfoIconTip = ({ content, visible = true }) => (
    visible &&
    <Popup
        trigger={<Icon name='help circle' color='blue' className='iconTip' />}
        content={content}
    />
)

export default InfoIconTip;