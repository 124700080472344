import { isBlank } from '../../lib/validation.js'
import { isInteger, isNil, negate } from 'lodash'

export const projectFields = {
    title: {
        label: 'Project title',
        validations: isBlank,
        get validationError() { return this.label + ' is required' },
        step: 1
    },
    acronym: {
        label: 'Acronym',
        step: 1
    },
    startDate: {
        label: 'Start date',
        validations: isBlank,
        get validationError() { return this.label + ' is required' },
        step: 1
    },
    endDate: {
        label: 'End date',
        validations: isBlank,
        get validationError() { return this.label + ' is required' },
        step: 1
    },
    projectType: {
        label: 'Type of project',
        validations: isBlank,
        get validationError() { return this.label + ' is required' },
        step: 1
    },
    nationalInternational: {
        label: 'National/International',
        validations: isBlank,
        get validationError() { return this.label + ' is required' },
        step: 1
    },
    fundingProgramme: {
        label: 'Funding programme',
        validations: isBlank,
        get validationError() { return this.label + ' is required' },
        step: 1
    },
    totalBudget: {
        label: 'Total budget (Euro)',
        validations: isBlank,
        get validationError() { return this.label + ' is required' },
        step: 1
    },
    noProjectPartners: {
        label: 'No. of project partners',
        validations: [isNil, negate(isInteger)],
        get validationError() { return this.label + ' is required' },
        step: 1
    },
    finalizedRunning: {
        label: 'Project status',
        validations: isBlank,
        get validationError() { return this.label + ' is required' },
        step: 2
    },
    developmentStage: {
        label: 'Stage of development (simplified TRL)',
        validations: isBlank,
        get validationError() { return this.label + ' is required' },
        step: 2
    },
    technologyReadinessLevel: {
        label: 'Technology Readiness Level (TRL)',
        validations: isBlank,
        get validationError() { return this.label + ' is required' },
        step: 2
    },
    projectsOutput: {
        label: 'Project Outputs',
        validations: isBlank,
        get validationError() { return this.label + ' is required' },
        step: 2
    },
    iprStatus: {
        label: 'IPR Status',
        validations: isBlank,
        get validationError() { return this.label + ' is required' },
        step: 2
    },
    additiveManufacturing3dPrinting: {
        label: 'Additive Manufacturing & 3D-Printing',
        validations: isBlank,
        get validationError() { return this.label + ' is required' },
        step: 3
    },
    additiveManufacturingKpaMarketKeywords: {
        label: 'Additive Manufacturing & 3D-Printing: KPA – Market Keywords',
        step: 3,
        isValid: function(entityObj) {
            if (entityObj.additiveManufacturing3dPrinting === 'Yes') {
                return !isBlank(entityObj.additiveManufacturingKpaMarketKeywords);
            }
            return true;
        },
        get validationError() { return this.label + ' is required' }
    },
    additiveManufacturingKpaTechnologyKeywords: {
        label: 'Additive Manufacturing & 3D-Printing: KPA – Technology Keywords',
        step: 3,
        isValid: function(entityObj) {
            if (entityObj.additiveManufacturing3dPrinting === 'Yes') {
                return !isBlank(entityObj.additiveManufacturingKpaTechnologyKeywords);
            }
            return true;
        },
        get validationError() { return this.label + ' is required' }
    },    
    microNanoTechnology: {
        label: 'Micro-&Nanotechnology-related Processes & Materials',
        validations: isBlank,
        get validationError() { return this.label + ' is required' },
        step: 3
    },
    microNanotechnologyKpaMarketKeywords: {
        label: 'Micro-&Nanotechnology-related Processes & Materials: KPA – Market Keywords',
        step: 3,
        isValid: function(entityObj) {
            if (entityObj.microNanoTechnology === 'Yes') {
                return !isBlank(entityObj.microNanotechnologyKpaMarketKeywords);
            }
            return true;
        },
        get validationError() { return this.label + ' is required' }
    },
    microNanotechnologyKpaTechnologyKeywords: {
        label: 'Micro-&Nanotechnology-related Processes & Materials: KPA – Technology Keywords',
        step: 3,
        isValid: function(entityObj) {
            if (entityObj.microNanoTechnology === 'Yes') {
                return !isBlank(entityObj.microNanotechnologyKpaTechnologyKeywords);
            }
            return true;
        },
        get validationError() { return this.label + ' is required' }
    },    
    industry40: {
        label: 'Industry 4.0',
        validations: isBlank,
        get validationError() { return this.label + ' is required' },
        step: 3
    },
    industryKpaMarketKeywords: {
        label: 'Industry 4.0: KPA – Market Keywords',
        step: 3,
        isValid: function(entityObj) {
            if (entityObj.industry40 === 'Yes') {
                return !isBlank(entityObj.industryKpaMarketKeywords);
            }
            return true;
        },
        get validationError() { return this.label + ' is required' }
    },
    industryKpaTechnologyKeywords: {
        label: 'Industry 4.0: KPA – Technology Keywords',
        step: 3,
        isValid: function(entityObj) {
            if (entityObj.industry40 === 'Yes') {
                return !isBlank(entityObj.industryKpaTechnologyKeywords);
            }
            return true;
        },
        get validationError() { return this.label + ' is required' }
    }    
}