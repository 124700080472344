import React, { useState, useRef, useEffect } from 'react';
import { Form, Button, Modal } from 'semantic-ui-react';
import { Map, Marker, Popup, MapControl, withLeaflet } from 'react-leaflet';
import 'react-leaflet-markercluster/dist/styles.min.css';
import { DefaultTileLayer, DEFAULT_VIEWPORT } from './MapView';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';

export const SelectGeolocationFromMap = ({ lat, lng, displayName, readOnly, onPositionSelected }) => {
    const isLatLon = lat && lng;
    const defaultPosition = isLatLon ? { lat, lng } : null
    const [open, setOpen] = useState(false)
    const [selectedPosition, setSelectedPosition] = useState(null)

    //console.log('defaultPosition', defaultPosition, 'selectedPosition', selectedPosition)

    const trigger =
        <Form.Button title={readOnly ? 'Location on map' : 'Select location from map'} basic
            icon='location arrow' onClick={e => { e.preventDefault(); setOpen(true) }}
        />

    const handleCancel = () => {
        setSelectedPosition(null)
        setOpen(false)
    }

    const handleDone = () => {
        selectedPosition && onPositionSelected(selectedPosition)
        setSelectedPosition(null)
        setOpen(false)
    }

    if (readOnly && !isLatLon) {
        return null
    }

    return (
        <Modal trigger={trigger} open={open} onClose={() => setOpen(false)} dimmer='inverted' size='large' className='geolocation'>
            <Modal.Content>
                <MapViewPointMarker
                    position={defaultPosition}
                    popupContent={displayName}
                    onPositionSelected={setSelectedPosition}
                    readOnly={readOnly}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleCancel}>Cancel</Button>
                {!readOnly && <Button primary disabled={!defaultPosition && !selectedPosition} onClick={handleDone}>Done</Button>}
            </Modal.Actions>
        </Modal>
    )
}

const MapViewPointMarker = ({ position, popupContent, onPositionSelected, readOnly }) => {
    const markerEl = useRef(null);
    const [viewport] = useState(position ? { center: Object.values(position), zoom: 15 } : DEFAULT_VIEWPORT);
    const [markerPopupContent, setMarkerPopupContent] = useState(popupContent);
    const [markerPosition, setMarkerPosition] = useState(position);

    useEffect(() => {
        if (markerPopupContent && markerEl.current) {
            markerEl.current.leafletElement.openPopup()
        }
    })

    const handleClick = evt => {
        const isSearchBarClick = !!evt.originalEvent.target.closest('.leaflet-control-geosearch.bar');
        if (readOnly || isSearchBarClick) {
            return
        }
        const { latlng } = evt;
        setMarkerPosition(latlng)

        lookupAddress(latlng.lat, latlng.lng).then(data => {
            const { display_name } = data;
            if (display_name) {
                setMarkerPopupContent(display_name)
            }
            onPositionSelected({ ...latlng, displayName: display_name })
        })
    }

    const AddressSearch = withLeaflet(AddressControl);

    return (
        <Map viewport={viewport} minZoom={2} maxZoom={18} onClick={handleClick}>
            <DefaultTileLayer />

            {!readOnly && <AddressSearch
                setMarkerPosition={setMarkerPosition}
                onPositionSelected={onPositionSelected}
                setMarkerPopupContent={setMarkerPopupContent}
            />}

            {markerPosition &&
                <Marker position={markerPosition} ref={markerEl}>
                    <Popup>{markerPopupContent}</Popup>
                </Marker>
            }
        </Map>
    )
}

const lookupServiceUrl = new URL('https://nominatim.openstreetmap.org/reverse');

const lookupAddress = async (lat, lng) => {
    lookupServiceUrl.searchParams.set('lat', lat);
    lookupServiceUrl.searchParams.set('lon', lng);
    lookupServiceUrl.searchParams.set('format', 'jsonv2')

    const response = await fetch(lookupServiceUrl);
    return await response.json();
}

class AddressControl extends MapControl {
    createLeafletElement({ setMarkerPosition, onPositionSelected, setMarkerPopupContent, leaflet }) {
        leaflet.map.on('geosearch/showlocation', ({ location }) => {
            const { lat, lon: lng, display_name } = location.raw;

            setMarkerPosition({ lat, lng });
            setMarkerPopupContent(display_name)
            onPositionSelected({ lat, lng, displayName: display_name });
        })

        const provider = new OpenStreetMapProvider();
        return GeoSearchControl({
            provider,
            style: 'bar',
            showMarker: false,
            autoClose: true,
            keepResult: true,
            searchLabel: 'Enter address or select location on map',
        });
    }
}