import React from 'react'
import { DatePicker } from 'antd';
import 'antd/dist/antd.css'
//import moment from 'moment';
//import _ from 'lodash'

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';
//const monthFormat = 'YYYY/MM';

class DateRangePicker extends React.Component {

    onPanelChange = (value, mode) => { //callback when picker panel mode is changed function(value, mode)
        console.log('DateRangePicker.onPanelChange', value, mode)
        //this.props.onChange(value)
    }

    onOpenChange = (status) => {
        console.log('DateRangePicker.onOpenChange', status)
        //this.props.onChange(value)
    }

    onCalendarChange = (moments, dates) => { // function(dates: [moment, moment], dateStrings: [string, string])
        console.log('DateRangePicker.onCalendarChange', moments[0], moments.length)
        // const from = _.get(moments, 'a[0].b.c')
        // const to = moments[1] ? moments[1].format(dateFormat) : undefined
        //moments[0].format(dateFormat)
        if (moments.length === 2)
            this.props.onChange(this.props.filteredFild, moments[0].format(dateFormat), moments[1].format(dateFormat))
    }
    

    render() {
        //const { updateTimestampfrom, } = this.props;
        return (
            <RangePicker
                //value={[moment(updateTimestampfrom, dateFormat), moment('2015/01/01', dateFormat)]}
                format={dateFormat} onPanelChange={this.onPanelChange} onCalendarChange={this.onCalendarChange}
                onOpenChange={this.onOpenChange}
            />
        )
    }

}

export default DateRangePicker