import React from 'react'
import { Form, Popup, Step, Button } from 'semantic-ui-react'
import * as utils from '../../lib/utils.js'
import { userService } from '../user/user.service.js'
import Dropdown from '../inputs/Dropdown.js'
import DropdownTree from '../inputs/DropdownTree.js'
import { organizationFields as fields } from './OrganizationFields.js'
import OrganizationApi from './OrganizationApi.js'
import CountryRegionGroup from '../inputs/CountryRegionGroup'
import 'react-dropdown-tree-select/dist/styles.css'
import './Organization.css'
import { isFieldValid } from '../../lib/validation.js'
import TextareaAutosize from 'react-textarea-autosize';
import InfoIconTip from '../inputs/InfoIconTip';

export const Steps = (props) => (
    <Step.Group attached='top' size='tiny' widths={4}>
        <Step active={props.currentStep === 1}
            data-stepnumber={1} title='General information' link
            onClick={props.onStepClick}
        />

        <Step active={props.currentStep === 2}
            data-stepnumber={2} title='Competences' link
            onClick={props.onStepClick}
        />

        <Step active={props.currentStep === 3}
            data-stepnumber={3} title='Infrastructure' link
            onClick={props.onStepClick}
        />

        <Step active={props.currentStep === 4}
            data-stepnumber={4} title='Services' link
            onClick={props.onStepClick}
        />
    </Step.Group>
)

export const StepGeneralInformation = props => (
    <div className='stepContent'>
        <Form.TextArea id='form-input-nameEnglish'
            label={fields.nameEnglish.label}
            placeholder={fields.nameEnglish.label}
            autoFocus control={TextareaAutosize} rows={1}
            name="nameEnglish"
            value={props.organization.nameEnglish || ''}
            onChange={props.onInputChange}
            required={!props.readOnly}
            readOnly={props.readOnly}
            error={!isFieldValid(props.organization.nameEnglish, fields.nameEnglish.validations, props.organization)}
        //error={props.invalidFields.find(o => o.name === 'nameEnglish') !== undefined}
        />

        <Form.TextArea id='form-input-nameOriginalLanguage'
            label={fields.nameOriginalLanguage.label}
            placeholder={fields.nameOriginalLanguage.label}
            control={TextareaAutosize} rows={1}
            name="nameOriginalLanguage" value={props.organization.nameOriginalLanguage || ''}
            onChange={props.onInputChange} required={!props.readOnly}
            readOnly={props.readOnly}
            error={!isFieldValid(props.organization.nameOriginalLanguage, fields.nameOriginalLanguage.validations, props.organization)}
        //error={props.invalidFields.find(o => o.name === 'nameOriginalLanguage') !== undefined}
        />

        <Form.TextArea id='form-input-contactPerson'
            label={fields.contactPerson.label} placeholder={fields.contactPerson.label}
            control={TextareaAutosize} rows={1}
            name="contactPerson" value={props.organization.contactPerson || ''}
            onChange={props.onInputChange}
            readOnly={props.readOnly}
            error={!isFieldValid(props.organization.contactPerson, fields.contactPerson.validations, props.organization)}
        //error={props.invalidFields.find(o => o.name === 'contactPerson') !== undefined}
        />

        <Form.Input id='form-input-address' label={fields.address.label}
            placeholder={fields.address.label} required={!props.readOnly}
            name="address" value={props.organization.address || ''}
            onChange={props.onInputChange}
            readOnly={props.readOnly}
            error={!isFieldValid(props.organization.address, fields.address.validations, props.organization)}
        //error={props.invalidFields.find(o => o.name === 'address') !== undefined}
        />

        <Form.Group>
            <Form.Input id='form-input-zip_code' label={fields.zipCode.label}
                placeholder={fields.zipCode.label} required={!props.readOnly}
                name="zipCode" value={props.organization.zipCode || ''} onChange={props.onInputChange}
                width={5}
                readOnly={props.readOnly}
                error={!isFieldValid(props.organization.zipCode, fields.zipCode.validations, props.organization)}
            //error={props.invalidFields.find(o => o.name === 'zipCode') !== undefined}
            />

            <Form.Input id='form-input-city' label={fields.city.label} placeholder={fields.city.label}
                name="city" value={props.organization.city || ''} onChange={props.onInputChange} required={!props.readOnly}
                width={11}
                readOnly={props.readOnly}
                error={!isFieldValid(props.organization.city, fields.city.validations, props.organization)}
            //error={props.invalidFields.find(o => o.name === 'city') !== undefined}
            />
        </Form.Group>

        <CountryRegionGroup
            entity={props.organization}
            onChange={props.onOrganizationChange}
            readOnly={props.readOnly}
            countryLabel={fields.country.label}
            regionLabel={fields.region.label}
            countryValidations={fields.country.validations}
            countryRequired
        />

        <Form.Group>
            <Form.Input id='form-input-phone' label={fields.phone.label} placeholder={fields.phone.label} required={!props.readOnly}
                name="phone" value={props.organization.phone || ''} onChange={props.onInputChange}
                width={5}
                readOnly={props.readOnly}
                error={!isFieldValid(props.organization.phone, fields.phone.validations, props.organization)}
            //error={props.invalidFields.find(o => o.name === 'phone') !== undefined}
            />

            <Form.Input id='form-input-email' label={fields.email.label} placeholder={fields.email.label} required={!props.readOnly}
                name="email" value={props.organization.email || ''} onChange={props.onInputChange}
                width={11}
                readOnly={props.readOnly}
                error={!isFieldValid(props.organization.email, fields.email.validations, props.organization)}
            //error={props.invalidFields.find(o => o.name === 'email') !== undefined}
            />
        </Form.Group>

        <Form.Input id='form-input-www' label={fields.www.label} placeholder={fields.www.label} required={!props.readOnly}
            name="www" value={props.organization.www || ''} onChange={props.onInputChange}
            readOnly={props.readOnly}
            error={!isFieldValid(props.organization.www, fields.www.validations, props.organization)}
        //error={props.invalidFields.find(o => o.name === 'www') !== undefined}
        />

        <Dropdown
            required={!props.readOnly} upward
            onChange={props.onDropdownChange}
            value={props.organization.legalStatus}
            label={fields.legalStatus.label}
            name='legalStatus'
            dataUrl='/data/organization_legal_status.json'
            readOnly={props.readOnly}
            error={!isFieldValid(props.organization.legalStatus, fields.legalStatus.validations, props.organization)}
        //error={props.invalidFields.find(o => o.name === 'legalStatus') !== undefined}
        />

        <Dropdown
            required={!props.readOnly} upward
            onChange={props.onDropdownChange}
            value={props.organization.entityType}
            label={fields.entityType.label}
            name='entityType'
            dataUrl='/data/organization_entity_type.json'
            readOnly={props.readOnly}
            error={!isFieldValid(props.organization.entityType, fields.entityType.validations, props.organization)}
        //error={props.invalidFields.find(o => o.name === 'entityType') !== undefined}
        />

        <Form.Input id='form-input-dataSource' label='Data source' placeholder='Data source'
            name="dataSource" value={props.organization.dataSource || ''}
            onChange={props.onInputChange}
            readOnly={props.readOnly}
            error={props.invalidFields.find(o => o.name === 'dataSource') !== undefined}
        //error={props.invalidFields.find(o => o.name === 'dataSource') !== undefined}
        />

        <Dropdown
            onChange={props.onDropdownChange}
            value={"" + props.organization.userOwnerId}
            label='Data owner'
            name='userOwnerId'
            dataUrl='/api/dictionary/users'
            readOnly={props.readOnly}
        />
        {!props.readOnly && <Popup trigger={<Button icon='help circle' content='How do you change or update data in Synpro service?' />} wide='very'
            content='To obtain the right to change data, an account must be set up in the Synpro service. Then after logging in, send a request to change the data to the data owner (via "Send a request to change the data"). The data owner can give editing rights to the applicant or introduce the proposed changes themselves.'
        />}
        {
            userService.loggedUser() && props.organization._links &&
            <div className='data_request_div'>
                <a href={OrganizationApi.mailToHref(props.organization, props.userForDataRequest.email, props.organization.nameEnglish)}>
                    Send a request to change the data
                </a>
            </div>
        }
    </div>
)

export const StepCompetences = (props) => (
    <div>
        <Dropdown
            required={!props.readOnly}
            onChange={props.onDropdownChange}
            value={props.organization.additiveManufacturing3dPrinting}
            label={fields.additiveManufacturing3dPrinting.label}
            name='additiveManufacturing3dPrinting'
            dataUrl='/data/project_yes_no_not_known.json'
            infotip={<InfoIconTip content='laser technologies, lithography, Inkjet, FDM, printing technologies' visible={!props.readOnly} />}
            readOnly={props.readOnly}
            //error={!isFieldValid(props.organization.additiveManufacturing3dPrinting, fields.additiveManufacturing3dPrinting.validations, props.organization)}
            error={props.invalidFields.find(o => o.name === 'additiveManufacturing3dPrinting') !== undefined}
        />

        <Dropdown
            required={!props.readOnly}
            onChange={props.onDropdownChange}
            value={props.organization.microNanoTechnology}
            label={fields.microNanoTechnology.label}
            name='microNanoTechnology'
            dataUrl='/data/project_yes_no_not_known.json'
            infotip={<InfoIconTip content={`Nanoparticle-filled materials, novel nanomaterials, 
                        knowledge management & databases for micro-& nanotechnology-related processes & materials, 
                        OHS issues related to nanosafety`} visible={!props.readOnly} />}
            readOnly={props.readOnly}
            //error={!isFieldValid(props.organization.microNanoTechnology, fields.microNanoTechnology.validations, props.organization)}
            error={props.invalidFields.find(o => o.name === 'microNanoTechnology') !== undefined}
        />

        <Dropdown
            required={!props.readOnly}
            onChange={props.onDropdownChange}
            value={props.organization.industry40}
            label={fields.industry40.label}
            name='industry40'
            dataUrl='/data/project_yes_no_not_known.json'
            infotip={<InfoIconTip content={`(decentralised or customised production, production assistance systems, 
                        Human-machine interface, tailor-made products, IoT, CPS) - additive manufacturing not included`} visible={!props.readOnly} />}
            readOnly={props.readOnly}
            //error={!isFieldValid(props.organization.industry40, fields.industry40.validations, props.organization)}
            error={props.invalidFields.find(o => o.name === 'industry40') !== undefined}
        />

        <Form.Field disabled={true}>
            <Dropdown
                onChange={props.onDropdownChange}
                value={props.organization.competences}
                label='Competences'
                name='competences'
                dataUrl='/data/organization_competences.json'
                readOnly={props.readOnly}
            />
        </Form.Field>
        <Form.TextArea id='form-input-competencesDesc' label='Competences description' placeholder='Competences description'
            control={TextareaAutosize} rows={1}
            name="competencesDesc" value={props.organization.competencesDesc || ''} onChange={props.onInputChange}
            required={!props.readOnly}
            readOnly={props.readOnly}
            //error={!isFieldValid(props.organization.competencesDesc, fields.competencesDesc.validations, props.organization)}
            error={props.invalidFields.find(o => o.name === 'competencesDesc') !== undefined}
        />
    </div>
)

export const StepInfrastructure = (props) => (
    <div className='stepInfrastructure'>
        <DropdownTree
            name='infrastructureAdditiveManufacturing'
            label='Infrastructure - Additive Manufacturing'
            onChange={props.onDropdownChange}
            dataUrl='/data/organization_infrastructure_additive_manufacturing.json'
            value={utils.multiselectStringToArray(props.organization.infrastructureAdditiveManufacturing)}
            readOnly={props.readOnly}
        />

        <DropdownTree
            name='infrastructureIndustry40'
            label='Infrastructure - Industry 4.0'
            onChange={props.onDropdownChange}
            dataUrl='/data/organization_infrastructure_industry_40.json'
            value={utils.multiselectStringToArray(props.organization.infrastructureIndustry40)}
            readOnly={props.readOnly}
        />

        <DropdownTree
            name='infrastructureMicroNanoManufacturing'
            label='Infrastructure - Micro/Nano Manufacturing'
            onChange={props.onDropdownChange}
            dataUrl='/api/dictionary/infrastructure_micro_nano_manufacturing/dropdown_tree'
            value={utils.multiselectStringToArray(props.organization.infrastructureMicroNanoManufacturing)}
            readOnly={props.readOnly}
        />

        <Form.TextArea id='form-input-infrastructure_description' label='Description of Infrastructure'
            placeholder='Description of Infrastructure' control={TextareaAutosize} rows={1}
            name="infrastructureDescription" value={props.organization.infrastructureDescription || ''}
            onChange={props.onInputChange}
            readOnly={props.readOnly}
            error={props.invalidFields.find(o => o.name === 'infrastructureDescription') !== undefined}
            required={!props.readOnly}
        />
    </div>
)