import React from 'react';
import client from '../../lib/client.js';
import { userService } from '../user/user.service.js';
import AuthenticationService from '../authentication_service/AuthenticationService.js';
import { UserContext } from '../../user-context';
import { getInvalidFields, ToastErrorMessage } from '../../lib/validation.js';
import { infrastructureFields } from './InfrastructureFields';
import InfrastructureApi from './InfrastructureApi';
import { Button, Form, Modal, Message, Container } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import '../project/ProjectCreateForm.css';
import { Steps, StepGeneralInformation, StepSpecifications } from './StepsFormContent';
import InfrastructureRequestForm from './request/InfrastructureRequestForm';
import ReactGA from 'react-ga'

class InfrastructureCreateForm extends React.Component {
    constructor(props) {
        super(props);

        this.infrastructureId = 0
        this.totalSteps = 2;
        this.state = {
            loading: true,
            currentStep: 1,
            infrastructure: {
                geoSearchData: {},
                fileList: [],
                filesToDelete: []
            },
            invalidFields: [],
            contactFormVisible: false
        }
    }

    componentDidMount() {
        this.infrastructureId = parseInt(this.props.match.params.infrastructureId, 10);
        const page = document.location.pathname.replace(this.infrastructureId, 'editView')
        ReactGA.pageview(page)

        if (this.infrastructureId > 0) {
            client(`/api/infrastructures/${this.infrastructureId}`)
                .then(({ entity }) => {
                    let loggedUser = userService.loggedUser();
                    const infrastructure = { ...this.state.infrastructure, ...entity, _links: entity._links, loggedUserId: loggedUser ? loggedUser.id : null }
                    this.setState({ infrastructure });

                    return Promise.all([
                        entity.organization,
                        entity.geoSearchData.catch(e => { }) // early catch bo geo data może nie istnieć
                    ])
                })
                .then(([organizationResp, geoSearchDataResp = {}]) => {
                    const infrastructure = {
                        ...this.state.infrastructure,
                        organizationName: organizationResp.entity.nameEnglish,
                        geoSearchData: geoSearchDataResp.entity || {}
                    };
                    this.setState({ infrastructure });
                })
                .catch(() => this.setState({ infrastructure: undefined }))
                .finally(() => this.setState({ loading: false }));
        }
        else {
            this.setState({ loading: false });
        }
    }

    isFormValid = () => this.state.invalidFields.length === 0;

    handleDismissValidationErrors = () => this.setState({ invalidFields: [] });

    handleSubmit = e => {
        const { infrastructure } = this.state;
        const handleInvalidItemClick = clicked => clicked.step && this.setState({ currentStep: clicked.step })
        let invalidFields = getInvalidFields(infrastructure, infrastructureFields, handleInvalidItemClick);

        this.setState({ invalidFields });

        if (invalidFields.length === 0) {
            this.setState({ loading: true });

            if (this.infrastructureId > 0) {
                this.handleUpdateInfrastructure({ ...infrastructure });
            }
            else {
                this.handleCreateInfrastructure({ ...infrastructure });
            }
        }
    }

    handleCreateInfrastructure = (infrastructure, e) => {
        let loggedUser = userService.loggedUser();
        if (loggedUser && loggedUser.id) {
            infrastructure.userCreateId = loggedUser.id;
            infrastructure.userUpdateId = loggedUser.id;
        }

        const formData = new FormData();

        infrastructure.fileList.forEach(file => {
            if (file.originFileObj) {
                formData.append('files', file.originFileObj);
            }
        })

        delete infrastructure.fileList;
        formData.set('infrastructure', new Blob([JSON.stringify(infrastructure)], { type: 'application/json' }));
        formData.set('geoSearchData', new Blob([JSON.stringify(infrastructure.geoSearchData)], { type: 'application/json' }));

        client({
            method: 'POST',
            path: '/api/infrastructures',
            entity: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
        })
            .then(response => {
                this.props.onSubmit(response.entity, <span>Infrastructure created: <em>{infrastructure.name}</em></span>, e);
            })
            .catch(error => {
                this.setState({ loading: false });
                toast.error(<ToastErrorMessage
                    message={'Failed to create infrastructure: ' + error.entity.message}
                    violations={error.entity.violations} />, { autoClose: false }
                )
            })
    }

    handleUpdateInfrastructure = (infrastructure, e) => {
        let loggedUser = userService.loggedUser();
        if (loggedUser && loggedUser.id) {
            infrastructure.userUpdateId = loggedUser.id;
        }

        const formData = new FormData();

        infrastructure.fileList.forEach(file => {
            if (file.originFileObj) {
                formData.append('files', file.originFileObj);
            }
        })
        const filesToDelete = infrastructure.filesToDelete.map(o => o.uid);
        infrastructure.id = InfrastructureApi.infrastructureId(infrastructure);

        delete infrastructure.fileList;
        delete infrastructure.filesToDelete;

        formData.set('infrastructure', new Blob([JSON.stringify(infrastructure)], { type: 'application/json' }));
        formData.set('deleted_files', new Blob([JSON.stringify(filesToDelete)], { type: 'application/json' }));
        formData.set('geoSearchData', new Blob([JSON.stringify(infrastructure.geoSearchData)], { type: 'application/json' }));

        client({
            method: 'PATCH',
            path: infrastructure._links.self.href,
            entity: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
        })
            .then(response => {
                this.props.onSubmit(infrastructure, <span>Infrastructure updated: <em>{infrastructure.name}</em></span>, e);
            })
            .catch(error => {
                this.setState({ loading: false });
                toast.error(<ToastErrorMessage
                    message={'Failed to update infrastructure: ' + error.entity.message}
                    violations={error.entity.violations} />, { autoClose: false }
                )
            })
    }

    handleDelete = e => {
        const { infrastructure } = this.state;
        client({
            method: 'DELETE',
            path: infrastructure._links.self.href
        })
            .then(response => {
                this.props.onSubmit(infrastructure, <span>Infrastructure deleted: <em>{infrastructure.name}</em></span>, e);
            })
    }

    handleClose = e => {
        if (this.props.onClose) {
            this.props.onClose();
        }
        else {
            this.props.history.push('/infrastructures');
        }
    }

    handleInfrastructureChange = changes => {
        const infrastructure = Object.assign({ ...this.state.infrastructure }, changes);
        this.setState({ infrastructure });
    }

    handleStepClick = (e, stepObj) => {
        this.setState({ currentStep: stepObj['data-stepnumber'] })
    }

    handleStepProceed = e => {
        if (this.state.currentStep < this.totalSteps) {
            this.setState(prevState => ({
                currentStep: prevState.currentStep + 1
            }))
        }
    }

    setupButtons = currentStep => {
        let readOnly = !InfrastructureApi.canEdit(this.state.infrastructure)

        let submitButton = <Button color='green' key='submit' icon='checkmark'
            content='Save' onClick={this.handleSubmit} disabled={this.state.loading} />;
        let cancelButton = <Button key='cancel' color='red' icon='remove' content='Cancel' onClick={this.handleClose} />;
        let proceedButton = <Button color='blue' key='proceed' labelPosition='right' icon='chevron right'
            content='Proceed' onClick={this.handleStepProceed} disabled={this.state.loading} />;
        const contactButton = <Button color='blue' key='contact' content='Contact with owner' className='contactWithOwner'
            onClick={() => this.setState({ contactFormVisible: true })} disabled={this.state.loading} />;
        let deleteButton = <Button color='red' key='delete' icon='delete'
            content='Delete' onClick={this.handleDelete} disabled={this.state.loading} />;

        let buttons = [cancelButton];

        if (readOnly) {
            submitButton = null;
        }

        if (this.infrastructureId > 0) {
            buttons.unshift(submitButton);
            buttons.unshift(contactButton);
            if (!readOnly) {
                buttons.unshift(deleteButton);
            }
        }
        else {
            switch (currentStep) {
                default:
                case 1:
                    if (!this.state.loading) {
                        buttons.unshift(proceedButton);
                    }
                    break;

                case 2:
                    buttons.unshift(submitButton);
                    break;
            }
        }
        return buttons
    }

    render() {
        if (this.state.infrastructure === undefined) {
            return (
                <Modal open dimmer='inverted' basic>
                    <Message
                        header='Not found'
                        content='The requested infrastructure was not found.'
                    />
                </Modal>
            )
        }

        const { currentStep, infrastructure, invalidFields } = this.state;
        const readOnly = !InfrastructureApi.canEdit(infrastructure)
        let buttons = this.setupButtons(currentStep)

        let formContent;
        switch (currentStep) {
            default:
            case 1:
                formContent =
                    <StepGeneralInformation infrastructure={infrastructure}
                        onInfrastructureChange={this.handleInfrastructureChange}
                        invalidFields={invalidFields}
                        readOnly={readOnly}
                    />
                break;

            case 2:
                formContent =
                    <StepSpecifications infrastructure={infrastructure}
                        onInfrastructureChange={this.handleInfrastructureChange}
                        invalidFields={invalidFields}
                        readOnly={readOnly}
                    />
                break;
        }

        const formRegister =
            <Modal open dimmer={this.props.dimmer} onClose={this.handleClose}>
                <Modal.Header>
                    {this.props.title}<span className='nameEditLabel'>{infrastructure.name}</span>
                </Modal.Header>

                <Steps currentStep={currentStep} onStepClick={this.handleStepClick} />

                <Form className='attached segment' loading={this.state.loading} error={!this.isFormValid()}>
                    <Message className='validationErrors'
                        error
                        size='small'
                        header='The following fields are invalid or missing'
                        list={invalidFields.map(o => o.validationClickableError)}
                        onDismiss={this.handleDismissValidationErrors}
                    />
                    <Modal.Content>
                        {formContent}

                        {
                            this.state.contactFormVisible &&
                            <InfrastructureRequestForm
                                authRequired
                                open={this.state.contactFormVisible}
                                handleClose={() => this.setState({ contactFormVisible: false })}
                                infrastructure={infrastructure}
                            />
                        }
                    </Modal.Content>
                </Form>
                <Message attached='bottom'>
                    <Container textAlign='right'>
                        {buttons}
                    </Container>
                </Message>
            </Modal>

        return (
            <UserContext.Consumer>
                {({ setLoggedUser }) => (
                    (userService.isAuthenticated() || !this.props.authRequired) ?
                        formRegister
                        :
                        <AuthenticationService setLoggedUser={setLoggedUser} open />
                )}
            </UserContext.Consumer>
        )
    }
}

InfrastructureCreateForm.defaultProps = {
    title: 'Register your infrastructure',
    dimmer: 'inverted',
    readOnly: true
};

export default InfrastructureCreateForm