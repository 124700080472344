import React from 'react'
import { Container, Header, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import MainContent from './MainContent.js'
import './HomePage.css'

const HomePage = () => {
    
    return(
    <MainContent>
        <div className='camt'>
            <Container className='synergyText'>
                <Header as='h1' className='sheader'>SYNERGY INNOVATION HUB</Header>
                {/* <Container textAlign='center' className='sbuttons'>
                    <Button as={Link} to="/projects" size='large'>
                        Projects
                    </Button>
                    <Button as={Link} to="/organizations" size='large'>
                        Organizations
                    </Button>
                    <Button as={Link} to="/map" size='large'>
                        Map
                    </Button>
                    <Button as={Link} to="/infrastructures" size='large'>
                        Infrastructure Sharing
                    </Button>
                </Container> */}
                <Container>
                <div textAlign='center' className='modules'>
                    <div className='module_box' id='project_box'>
                        <div className='module_button'>
                            <Button as={Link} to="/projects" size='large'>
                                Projects
                            </Button>
                        </div>
                        <div className='module_info'>
                            Free online database of research & industrial projects where you can promote your achievements
                        </div>
                    </div>
                    <div className='module_box' id='organization_box'>
                        <div className='module_button'>
                            <Button as={Link} to="/organizations" size='large'>
                                Organizations
                            </Button>
                        </div>
                        <div className='module_info'>
                            Free online database of organizations where you can desribe and promote your competences 
                            and find experienced partners for cooperation
                        </div>
                    </div>
                    <div className='module_box' id='map_box'>
                        <div className='module_button'>
                            <Button as={Link} to="/map" size='large'>
                                Map
                            </Button>
                        </div>
                        <div className='module_info'>
                            Free online search & visualization tool for universities, 
                            R&D organizations and companies to find an organization based on its projects description
                        </div>
                    </div>
                    <div className='module_box' id='infrastructure_box'>
                        <div className='module_button'>
                            <Button as={Link} to="/infrastructures" size='large'>
                                Infrastructure Sharing
                            </Button>
                        </div>
                        <div className='module_info'>
                            „Uber for advanced manufacturing” - free online database of research infrastructure where you can register
                            and offer your machines and equipment or get direct access to the infrastructure that you need
                        </div>
                    </div>
                </div>
                    <div textAlign='center' className='modules'>
                        <div className='module_box link_box' id='crowdsourcing_box'>
                            <div className='module_button'>
                                    <Button onClick={() => window.open('https://synergyplatform.pwr.edu.pl/challenges')} size='large'>
                                    CROWDSOURCING
                                </Button>
                            </div>
                            <div className='module_info'>
                                By crowdsourcing companies can define their problems, needs and challenges, whereas students & scientists 
                                provide jointly developed solutions in an open innovation environment
                            </div>
                        </div>
                        <div className='module_box link_box' id='crowdfunding_box'>
                            <div className='module_button'>
                                    <Button onClick={() => window.open('https://synergyplatform.pwr.edu.pl/campaigns')} size='large'>
                                    CROWDFUNDING
                                </Button>
                            </div>
                            <div className='module_info'>
                                Free online tool enabling simulated crowdfunding - here creating and testing crowdfunding campaigns 
                                can be done
                            </div>
                        </div>
                        <div className='module_box' id='trainings_box'>
                            <div className='module_button'>
                                    <Button onClick={() => window.open('https://ideaup.pwr.edu.pl/')} size='large'>
                                    TRAININGS
                                </Button>
                            </div>
                            <div className='module_info'>
                                Free online trainings from international scientists focused on deep tech, innovation, 
                                and enterpreneurship
                            </div>
                        </div>
                        <div className='module_box' id='inspiration_box'>
                            <div className='module_button'>
                                    <Button onClick={() => window.open('https://readyforfuture.eu/')} size='large'>
                                    INSPIRATION KNOWLEDGE BASE
                                </Button>
                            </div>
                            <div className='module_info'>
                                Free online knowledge repository with best practises and success stories in four areas:
                                    <br />1) Innovation culture,
                                    <br />2) Digital manufacturing,
                                    <br />3) Open innovation 2.0 & sharing economy,
                                    <br />4) Green & circular economy & sustainability

                            </div>
                        </div>
                    </div>
                </Container>
            </Container>
        </div>
    </MainContent>
)}


export default HomePage