import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import client from '../lib/client.js';
import { toast } from 'react-toastify';

/**
 * Komponent dostarcza dane i operacje dla listy obiektów (organizacje, projekty, etc.)
 * Zawiera standardowe operacje, które powtarzają się niezależnie od typu obiektu: 
 * pobieranie danych, sortowanie, paginacja.
 * @param {*} props 
 */
export const DataList = props => {
    const initialSortBy = new URLSearchParams(props.apiDataFetchUrl.split('?')[1] || '').get('sort');

    const [data, setData] = useState([]);
    const [links, setLinks] = useState({});
    const [page, setPage] = useState({ totalPages: 0, totalElements: 0, number: 0 });
    const [sortBy, setSortBy] = useState(initialSortBy || props.defaultSortBy || 'id,asc');
    const [loading, setLoading] = useState(undefined);
    const history = useHistory();

    useEffect(() => {
        const url = new URL(props.apiDataFetchUrl, window.location.origin);
        url.searchParams.set('sort', sortBy);

        const requestParams = createRequestParamsFromUrl(url.toString());

        fetchData(requestParams);

        return () => {
            requestParams.cancel()
        }
// eslint-disable-next-line
    }, [props.apiDataFetchUrl]);

    const createRequestParams = ({ path, method = 'GET', entity }) => {
        const params = { path, method }
        if (entity) {
            params.entity = entity
            params.headers = { 'Content-Type': 'application/json' }
        }
        return params;
    }

    const createRequestParamsFromUrl = path => createRequestParams({ path, method: props.method, entity: props.searchEntity })

    const fetchData = requestParams => {
        setLoading(true);

        client(requestParams)
            .then(response => {
                setLoading(false);
                const { _embedded, _links, page } = response.entity;
                const enitiesList = _embedded ? _embedded[props.apiEntityName] : []
                setData(enitiesList);
                setLinks(_links);
                setPage(page);
            })
            .catch(err => {
                if (!err.request.canceled) {
                    setLoading(false);
                    console.log(err);
                }
            })
    }

    const handlePagination = (e, obj) => {
        const url = getUrl({ page: obj.activePage - 1, sort: sortBy });
        fetchData(createRequestParamsFromUrl(url.toString()));
    }

    const handleUpdate = (entity, message, e) => {
        toast.success(message);
        fetchData(createRequestParamsFromUrl(getUrl().toString()));
        const linkToList = props.linkToList || `/${props.apiEntityName}`
        history.push(linkToList);
    }

    const handleCreate = (entity, message, e) => {
        toast.success(message);
        fetchData(createRequestParamsFromUrl(getUrl().toString()));
        const linkToList = props.linkToList || `/${props.apiEntityName}`
        history.push(linkToList);
    }

    const handleDelete = (entity, message, e) => {
        toast.success(message);
        fetchData(createRequestParamsFromUrl(getUrl().toString()));
    }

    const handleSort = (sortByColumn, e) => {
        let direction = 'asc';
        let sort = getSortMetaData();
        if (sort.field === sortByColumn) {
            direction = sort.direction === 'ascending' ? 'desc' : 'asc';
        }
        setSortBy(`${sortByColumn},${direction}`);

        const url = getUrl({ page: page.number - 1, sort: `${sortByColumn},${direction}` });
        fetchData(createRequestParamsFromUrl(url.toString()));
    }

    const getUrl = (searchParams = { page: page.number, sort: sortBy }) => {
        let href = links.self.href;
        if (links.self.templated) {
            href = href.split('{')[0];
        }
        const url = new URL(href);
        for (let [key, value] of Object.entries(searchParams)) {
            url.searchParams.set(key, value);
        }
        return url;
    }

    const getSortMetaData = () => ({
        field: sortBy.split(',')[0],
        direction: sortBy.split(',')[1] === 'desc' ? 'descending' : 'ascending'
    })

    return (
        props.children({
            data,
            fetchData,
            sortMetaData: { sortBy, ...getSortMetaData() },
            page,
            loading,
            onPageChange: handlePagination,
            onSort: handleSort,
            onCreate: handleCreate,
            onUpdate: handleUpdate,
            onDelete: handleDelete,
        })
    )
}

DataList.propTypes = {
    apiDataFetchUrl: PropTypes.string.isRequired, // np.: /api/organizations
    apiEntityName: PropTypes.string.isRequired, // Nazwa z tablicą obiektów zwracaną przez API. np.: organizations
    defaultSortBy: PropTypes.string, // np.: title,desc
    searchEntity: PropTypes.object,
}