import React from 'react'
import { Input, Button, Grid, Popup } from 'semantic-ui-react'


class FilterDate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            startDate: null,
            endDate: '2019-04-16',
        }
    }

    componentDidMount() {
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    render() {
        //const { state, handleChange, } = this.props;
        const { startDate, endDate, } = this.state;
        return (
            <div>
                <Popup wide trigger={
                    <Button >
                        <Grid divided rows='equal'>
                            <Grid.Row>
                                From {startDate}
                            </Grid.Row>
                            <Grid.Row>
                                To {endDate}
                            </Grid.Row>
                        </Grid>
                    </Button>}
                    on='click'>
                    <div>
                        <Input id='form-input-startDate' type='date'
                            name="startDate" value={startDate} onChange={this.handleChange}
                        />
                        <Input id='form-input-endDate' type='date'
                            name="endDate" value={endDate} onChange={this.handleChange}
                        />
                    </div>
                    {/* <Grid divided rows='equal'>
                        <Grid.Row>
                            <Input id='form-input-startDate' type='date'
                                name="startDate" value={startDate} onChange={this.handleChange}
                            />
                        </Grid.Row>
                        <Grid.Row>
                            <Input id='form-input-endDate' type='date'
                                name="endDate" value={endDate} onChange={this.handleChange}
                            />
                        </Grid.Row>
                    </Grid> */}
                </Popup>
            </div>
        )
    }

}

export default FilterDate