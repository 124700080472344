import React from 'react'
import { Icon, Accordion, Modal, Table, Image, Container, Tab, Header, Grid, List, Button } from 'semantic-ui-react'
import _ from 'lodash'
import './MatchmakingResults.css'
import ReactGA from 'react-ga'

class MatchmakingResults extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        const keywordTabs = [
            {
                menuItem: 'Additive Manufacturing & 3D-Printing', render: () =>
                    <Tab.Pane><MatchmakingResultsTabContent clusteringType={ClusteringCategoryTypes.AdditiveManufacturing} /></Tab.Pane>
            },
            {
                menuItem: 'Micro-&Nanotechnology-related Processes & Materials', render: () =>
                    <Tab.Pane><MatchmakingResultsTabContent clusteringType={ClusteringCategoryTypes.MicroNanotechnology} /></Tab.Pane>
            },
            {
                menuItem: 'Industry 4.0', render: () =>
                    <Tab.Pane><MatchmakingResultsTabContent clusteringType={ClusteringCategoryTypes.Industry} /></Tab.Pane>
            },
        ]

        return (
            <Container textAlign='center'>
                <Header as='h2'>Results of grouping projects</Header>
                <Tab className='matchmakingTabs' panes={keywordTabs} />
            </Container>
        )
    }
}

const MatchmakingViewImage = (props) => (
    <Modal trigger={<Image src={props.src} className='pointer' />}
        dimmer='inverted' size='fullscreen'>
        <Modal.Content image>
            <Image wrapped src={props.src} />
        </Modal.Content>
    </Modal>
)

// const EmbedHtml = (props) => (
//     <Modal trigger={<Button>Show active view</Button>}
//         dimmer='inverted' size='fullscreen'>
//         <Modal.Content>
//             <Embed icon='right circle arrow' url='/matchmaking/additive_manufacturing.html' />
//         </Modal.Content>
//     </Modal>
// )

const ClusteringCategoryTypes = {
    AdditiveManufacturing: 'additive_manufacturing',
    MicroNanotechnology: 'micro_nanotechnology',
    Industry: 'industry',
}

class MatchmakingResultsTabContent extends React.Component {
    constructor(props) {
        super(props);

        this.clusters = this.clusters.bind(this);

        this.state = {
            projects: [],
            clusteringType: this.props.clusteringType,
            isLoading: false,
            accordionActiveIndex: 0,
            viewImageInModal: false,
            imageModalSrc: null,
        }
    }

    urlForType(clusteringCategoryType) {
        switch (clusteringCategoryType) {
            case ClusteringCategoryTypes.AdditiveManufacturing:
                return '/matchmaking/result_additive_manufacturing';
            case ClusteringCategoryTypes.MicroNanotechnology:
                return '/matchmaking/result_micro_nanotechnology';
            case ClusteringCategoryTypes.Industry:
                return '/matchmaking/result_industry';
            default:
                return;
        }
    }

    imageForType(clusteringCategoryType) {
        switch (clusteringCategoryType) {
            case ClusteringCategoryTypes.AdditiveManufacturing:
                return '/matchmaking/image_additive_manufacturing';
            case ClusteringCategoryTypes.MicroNanotechnology:
                return '/matchmaking/image_micro_nanotechnology';
            case ClusteringCategoryTypes.Industry:
                return '/matchmaking/image_industry';
            default:
                return;
        }
    }

    componentDidMount() {
        ReactGA.pageview('/matchmaking_results')
        this.fetchResult('/matchmaking/' + this.props.clusteringType + '.json'); //this.urlForType(this.props.clusteringType)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.clusteringType !== this.state.clusteringType) {
            this.fetchResult('/matchmaking/' + this.props.clusteringType + '.json'); //this.urlForType(this.props.clusteringType)
            this.setState({ clusteringType: this.props.clusteringType })
        }
    }

    resultObjectToProjectList(clusteringResultJson) {
        console.log('MatchmakingResultsTabContent.resultObjectToProjectList', clusteringResultJson)
        if(clusteringResultJson.nodes) {
            return clusteringResultJson.nodes.map(this.resultProjectAdapter)
        } 
        return []
    }

    resultProjectAdapter(clusteringResultProject) {
        return Object.assign({ ...clusteringResultProject }, {
            title: clusteringResultProject.projectname,
            acronym: clusteringResultProject.id, id: clusteringResultProject.projectid, clusterNumber: clusteringResultProject.group,
        });
    }

    fetchResult(request) {
        fetch(request, {
            method: 'get',
            headers: { 'Content-Type': 'application/json', },
        })
            .then(response => {
                if (!response.ok) {
                    //responseError = true;
                    return response.json();
                }
                return response.json();
            })
            .then(json => {
                this.setState({ projects: this.resultObjectToProjectList(json) })
            })
    }

    clusters = () => {
        return _.uniq(this.state.projects.map(p => p.clusterNumber)).sort((a, b) => a - b)
    }

    projectsInCluster = (cluster) => {
        return this.state.projects.filter(p => p.clusterNumber === cluster)
    }

    handleAccordionClick = (e, titleProps) => {
        const { index } = titleProps
        const { accordionActiveIndex } = this.state
        const newIndex = accordionActiveIndex === index ? -1 : index

        this.setState({ accordionActiveIndex: newIndex })
    }

    showInPopup = (clusteringType) => {
        //window.open('http://localhost:9000/matchmaking/html_additive_manufacturing.html','active', 'width=800,height=800')
        //window.open('http://localhost:9000/matchmaking/html_additive_manufacturing.html')
        const url = '/matchmaking/' + clusteringType + '.html'
        window.open(url)
    }


    render() {
        const { projects, accordionActiveIndex } = this.state;
        return (
            <React.Fragment>
                <Grid columns={2} divided>
                    <Grid.Row>
                        <Grid.Column>
                            <Accordion fluid styled>
                                {
                                    this.clusters().map(cluster => <React.Fragment>
                                        <Accordion.Title active={accordionActiveIndex === cluster} index={cluster} onClick={this.handleAccordionClick}>
                                            <Icon name='dropdown' />
                                            Cluster {cluster} [{this.projectsInCluster(cluster).length}]
                                        </Accordion.Title>
                                        <Accordion.Content active={accordionActiveIndex === cluster}>
                                            <List>
                                                {this.projectsInCluster(cluster).map(project =>
                                                    <List.Item>{project.acronym}</List.Item>)}
                                            </List>
                                        </Accordion.Content>
                                    </React.Fragment>)
                                }
                                <Accordion.Title active={accordionActiveIndex === 0} index={0} onClick={this.handleAccordionClick}>
                                    <Icon name='dropdown' />
                                    Full list
                            </Accordion.Title>
                                <Accordion.Content active={accordionActiveIndex === 0}>
                                    <Table celled striped sortable>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell
                                                >Project</Table.HeaderCell>
                                                <Table.HeaderCell
                                                >Cluster number</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            {projects.map(project =>
                                                <Table.Row key={project.acronym}>
                                                    <Table.Cell className='preLine'>
                                                        {project.acronym}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {project.clusterNumber}
                                                    </Table.Cell>
                                                </Table.Row>
                                            )}
                                        </Table.Body>

                                        <Table.Footer>
                                        </Table.Footer>
                                    </Table>
                                </Accordion.Content>
                            </Accordion>
                        </Grid.Column>
                        <Grid.Column>
                            <MatchmakingViewImage src={'/matchmaking/plot_' + this.props.clusteringType} />
                            {/* this.imageForType(this.props.clusteringType) */}
                            {/* <EmbedHtml /> */}
                            <Button onClick={() => this.showInPopup(this.props.clusteringType)}>Active view</Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </React.Fragment>
        )
    }
}


export { MatchmakingResults }