import React from 'react'
import { userService } from './components/user/user.service.js';

const defaultUserFields = {
    username: 'Guest',
    isAuthenticated: false,
    isAdmin: false,
    isApprobatory: false,
}

let loggedUser = userService.loggedUser()
console.log('UserContext loggedUser ', loggedUser)

export const defaultUser = {
    ...Object.assign({ ...defaultUserFields }, loggedUser),

    setUser: (newUser) => {

        if (!newUser) {
            console.log('UserContext logout');
            userService.logout();
            return defaultUserFields;
        }

        let user = Object.assign({ ...defaultUserFields }, { ...newUser });

        user.isAuthenticated = user.authorities ? true : false;
        user.username = newUser.firstName + ' ' + newUser.lastName;
        return user;
    },

    logout: () => {
        localStorage.removeItem("user");
        return defaultUserFields;
    },
}

export const UserContext = React.createContext({
    ...defaultUser,
    setLoggedUser: () => { },
});