import React, { Component } from 'react'
import { Input } from 'semantic-ui-react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

class InputDatePicker extends Component {

    render() {
        const { readOnly, ...rest } = this.props;
        let dateFormat = 'YYYY-MM-DD';
        let dateInput = 
            <Input icon="calendar" style={{ width: '9.5em' }}
                value={this.props.value} onChange={this.props.onChange}
                name={this.props.name} readOnly={readOnly}
            />;

        return (
            <DatePicker customInput={dateInput} showMonthDropdown showYearDropdown 
                dateFormat={dateFormat} placeholderText={dateFormat} 
                className={readOnly ? 'inputDate readOnly' : 'inputDate'}
                disabled={readOnly}
                {...rest}
                onChange={(momentObj, e) => this.props.onChange(momentObj, e, this.props.name)}
                autoComplete='off'
            />                                
        )
    }
}

export default InputDatePicker