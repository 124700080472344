import { authHeader } from './auth-header';
import _ from 'lodash';

export const userService = {
    login,
    loginByExternalToken,
    logout,
    register,
    getAll,
    getById,
    update,
    delete: _delete,
    loggedUser,
    isAuthenticated,
    isAdmin,
    isAuthorizing,
    registerNewAccount,
    changePassword,
    updateAuthorities,
    resetPassword,
    requestResetPassword,
    userId
};

const storage = sessionStorage;

function changePassword(user, setNewUser, setError) {
    let responseError = false;
    fetch('/authentication_provider_impl/change_password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify({ ...user })
    })
        .then(response => {
            if (!response.ok) {
                responseError = true;
                return response.json();
            }
            return response.json();
        })
        .then(json => {
            if (responseError) {
                setError(json.error);
            } else {
                setNewUser(json);
            }
        })
        .catch(error => {
            console.log('userService.changePassword.catch ', error);
        });
}

function registerNewAccount(user, setNewUser, setError) {
    let responseError = false;

    fetch('/authentication_provider_impl/register_new_account', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...user })
    })
        .then(response => {
            if (!response.ok) {
                responseError = true;
            }
            return response.json();
        })
        .then(json => {
            if (responseError) {
                setError(json.error);
            }
            else {
                setNewUser(json);
            }
            console.log('userService.registerNewAccount.json ', responseError, json);
        })
        .catch(error => {
            console.log('userService.registerNewAccount.catch ', error);
        });
}

function updateAuthorities(params) {
    const { userId, isAdmin, isAuthorizing } = params;
    console.log('userService.updateAuthorities ' + userId + ' ' + isAdmin + ' ' + isAuthorizing);
    fetch('/authentication_provider_impl/update_authorities', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId, isAdmin, isAuthorizing })
    })
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            return response.json();
        });
}

/**
 * @callback setLoggedUser
 * @param  {object} user
 */
/**

* @callback handleFetchError
 * @param  {object} error
 */

/**
 * @param {string} external_token
 * @param {setLoggedUser} setLoggedUser
 * @param {handleFetchError} handleError
 */
function loginByExternalToken(external_token, setLoggedUser, handleError) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ external_token })
    };

    return fetch('/authentication_provider_impl/external_token', requestOptions)
        .then(response => response.json())
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user && user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                storage.setItem('user', JSON.stringify(user));
                setLoggedUser && setLoggedUser(user);
            }
            else {
                handleError(user.error);
            }
            return user;
        });
}

function login(login, password, setLoggedUser, setError) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ login, password })
    };

    return fetch('/authentication_provider_impl/login', requestOptions)
        .then(response => response.json())
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user && user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                storage.setItem('user', JSON.stringify(user));
                setLoggedUser && setLoggedUser(user);
            }
            else {
                setError(user.error);
            }
            return user;
        });
}

function requestResetPassword(email, onSuccess = () => { }, onError = () => { }) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `email=${email}`
    };

    return fetch('/authentication_provider_impl/forgot_password', requestOptions)
        .then(response =>
            response.json().then(json => {
                response.ok ? onSuccess(json) : onError(json)
            })
        )
}

function resetPassword(payload, onSuccess = () => { }, onError = () => { }) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...payload })
    };

    return fetch('/authentication_provider_impl/reset_password', requestOptions)
        .then(response =>
            response.json().then(json => {
                response.ok ? onSuccess(json) : onError(json)
            })
        )
}

function loggedUser() {
    return JSON.parse(storage.getItem('user'));
}

function hasRole(role) {
    const user = loggedUser();
    if (user && user.authorities) {
        return user.authorities.map(a => a.name).includes(role);
    }
    return false;
}

function isAuthenticated() {
    return hasRole('ROLE_USER');
}

function isAdmin() {
    return hasRole('ROLE_ADMIN');
}

function isAuthorizing() {
    return hasRole('ROLE_AUTHORIZING');
}

function logout() {
    // remove user from local storage to log user out
    storage.removeItem('user');
    fetch('/authentication_provider_impl/logout', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
    })
    .then(handleResponse);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch('/users', requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch('/users/' + id, requestOptions).then(handleResponse);
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch('/users/register', requestOptions).then(handleResponse);
}

function update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch('/users/' + user.id, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch('/users/' + id, requestOptions).then(handleResponse);;
}

function handleResponse(response) {
    if (!response.ok) {
        return Promise.reject(response.statusText);
    }

    return response.json();
}

function userId(user) {
    return _.last(user._links.self.href.split("/"))
}