import React, { useEffect } from 'react';
import { Route, Link, Switch } from 'react-router-dom';
import { Button, Container, Icon } from 'semantic-ui-react';
import InfrastructureTable from './InfrastructureTable';
import InfrastructureCreateForm from './InfrastructureCreateForm';
import { DataList } from '../DataList';
import { ToggleViewMode } from './Infrastructure';
import ReactGA from 'react-ga'

const InfrastructureList = ({ apiDataFetchUrl, searchEntity, viewMode, handleViewModeChange }) => {
    useEffect(() => { ReactGA.pageview('/infrastructures') }, [])
    return (
        <div className='infrastructure-list'>
            <ToggleViewMode viewMode={viewMode} onChange={handleViewModeChange} />
            <RegisterInfrastructureButton />

            <DataList apiDataFetchUrl={apiDataFetchUrl} method='POST' apiEntityName='infrastructures' searchEntity={searchEntity}>
                {({ loading, data, sortMetaData, page, ...rest }) =>
                    <>
                        <InfrastructureTable loading={loading} sort={sortMetaData} infrastructures={data}
                            totalPages={page.totalPages} activePage={page.number} totalElements={page.totalElements}
                            {...rest}
                        />

                        <Routes onCreate={rest.onCreate} onUpdate={rest.onUpdate} />
                    </>}
            </DataList>
        </div>
    )
}

export const RegisterInfrastructureButton = props =>
    <Container className="searchControls" fluid style={{ paddingBottom: '2.5em' }}>
        <Button content='Register your infrastructure' icon='add' labelPosition='left' floated='right'
            primary as={Link} to="/infrastructures/register"
        />
    </Container>

const Routes = ({ onCreate, onUpdate }) => (
    <Switch>
        <Route path="/infrastructures/register" exact render={props =>
            <InfrastructureCreateForm {...props} authRequired onSubmit={onCreate} />
        } />
        <Route path="/infrastructures/:infrastructureId" render={props =>
            <InfrastructureCreateForm {...props} onSubmit={onUpdate}
                title={<span><Icon name='edit' /> Edit infrastructure</span>} />
        } />
    </Switch>
)

export default InfrastructureList