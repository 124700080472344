import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Container } from 'semantic-ui-react';

export const SearchBar = props => {
    const [searchText, setSearchText] = useState(props.searchText || '')

    const handleSearch = e => {
        if (e.key === 'Enter' || e.type === 'click') {
            props.onSearch(searchText.trim(), e);
        }
    }

    return (
        <Container className="searchControls" fluid>
            <Input icon={{ name: 'search', link: true, onClick: handleSearch }} autoFocus placeholder="Search..."
                onKeyUp={handleSearch} value={searchText}
                onChange={e => setSearchText(e.target.value)}
            />
            {props.children}
        </Container>
    )
}

SearchBar.propTypes = {
    onSearch: PropTypes.func.isRequired,
}