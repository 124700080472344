import axios from 'axios';
import { saveAs } from 'file-saver';
import docIcon from '../../../assets/icons/doc.png';
import pdfIcon from '../../../assets/icons/pdf.png';
import sheetIcon from '../../../assets/icons/sheet.png';

const CancelToken = axios.CancelToken;
export let cancelDownload;

export const download = async (file, handleProgress = () => { }) => {
    if (!file.uploaded) {
        file.url = await getBase64(file.originFileObj);
    }

    const config = {
        responseType: 'blob',
        onDownloadProgress({ loaded, total }) {
            const percent = Math.round((loaded / total) * 100);
            handleProgress({ loaded, total, percent })
        },
        cancelToken: new CancelToken(c => { cancelDownload = c })
    }

    // axios ma ciekawy bajer: onDownloadProgress
    return axios.get(file.url, config)
        .then(response => response.data)
        .then(blob => saveAs(blob, file.name))
        .catch(e => {
            if (axios.isCancel(e)) {
                console.log('Download request canceled', e.message);
            }
            else {
                console.error(e);
            }
        })

    // return fetch(file.url)
    //     .then(response => response.blob())
    //     .then(blob => { console.log('blob', blob); saveAs(blob, file.name) })
}

export const getBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
        reader.readAsDataURL(file);
    });

export const isImageContentType = type => (type.split('/')[0]).startsWith('image')

const thumbUrlFromMimeType = file => {
    if (isImageContentType(file.type)) {
        return file.thumbUrl
    }

    switch (file.type) {
        case 'application/pdf':
            return pdfIcon;
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/vnd.ms-excel':
            return sheetIcon;
        default:
            return docIcon;
    }
}

export const detectThumbUrl = (fileList = []) =>
    fileList.map(o => {
        if (!o.thumbUrl) {
            o.thumbUrl = thumbUrlFromMimeType(o)
        }
        return o;
    })