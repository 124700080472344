import React from 'react'
import { Route, Link, Switch } from 'react-router-dom'
import client from '../../lib/client.js'
import ProjectCreateForm from './ProjectCreateForm.js'
import _last from 'lodash/last'
import { Button, Table, Icon, Confirm } from 'semantic-ui-react'
import './ProjectList.css'
import { toast } from 'react-toastify'
import { userService } from '../user/user.service.js';
import ReactGA from 'react-ga';
import { DataList } from '../DataList';
import { DataTable, DataTableHeaderCell } from '../DataTable';
import { SearchBar } from '../SearchBar';

const ProjectListViewPermissions = {
    OnlyAuthorized: 'ViewOnlyAuthorized',
    AuthorizedAndOwn: 'ViewAuthorizedAndOwn',
    All: 'ViewAll',
}

const getUserViewPermissions = () => {
    if (!userService.isAuthenticated()) return ProjectListViewPermissions.OnlyAuthorized
    if (userService.isAdmin() || userService.isAuthorizing()) return ProjectListViewPermissions.All
    return ProjectListViewPermissions.AuthorizedAndOwn
}

class ProjectList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            viewPermission: getUserViewPermissions(),
            searchText: '',
        }
    }

    componentDidUpdate() {
        const viewPermission = getUserViewPermissions()
        if (viewPermission !== this.state.viewPermission) {
            this.setState({ viewPermission })
        }
    }

    componentDidMount() {
        ReactGA.pageview('/projects');
    }

    getDefaultUrl = () => {
        const { searchText, viewPermission } = this.state;

        switch (viewPermission) {
            case ProjectListViewPermissions.OnlyAuthorized:
                return `/api/projects/search/listOnlyAuthorized?searchText=${searchText}&page=0`;
            case ProjectListViewPermissions.AuthorizedAndOwn && userService.loggedUser():
                return `/api/projects/search/listAuthorizedAndOwn?searchText=${searchText}&idUser=${userService.loggedUser().id}&page=0`;
            case ProjectListViewPermissions.All:
                return `/api/projects/search/byTitleOrAcronym?searchText=${searchText}&page=0`;
            default:
                return `/api/projects/search/listAuthorizedAndOwn?searchText=${searchText}&page=0`;
        }
    }

    render() {
        return (
            <div>
                <DataList apiDataFetchUrl={this.getDefaultUrl()} apiEntityName='projects' defaultSortBy='title,asc'>
                    {({ loading, data, sortMetaData, page, ...rest }) => (
                        <>
                            <SearchBar onSearch={searchText => this.setState({ searchText })}>
                                <Button content='Register your project' icon='add' labelPosition='left' floated='right'
                                    primary as={Link} to="/projects/register" />
                            </SearchBar>

                            <ProjectTable loading={loading} sort={sortMetaData} projects={data}
                                totalPages={page.totalPages} activePage={page.number} totalElements={page.totalElements}
                                {...rest}
                            />

                            <Routes onCreate={rest.onCreate} onUpdate={rest.onUpdate} />
                        </>
                    )}
                </DataList>
            </div>
        )
    }
}

const Routes = ({ onCreate, onUpdate }) => (
    <Switch>
        <Route path="/projects/register" exact render={props =>
            <ProjectCreateForm {...props} onSubmit={onCreate} />
        } />
        <Route path="/projects/:id" render={props =>
            <ProjectCreateForm {...props} onSubmit={onUpdate}
                title={<span><Icon name='edit' /> Edit project</span>} />
        } />
    </Switch>
)

class DeleteProjectButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            disabled: false,
            open: false
        };
    }

    show = () => this.setState({ open: true })
    handleCancel = () => this.setState({ open: false })
    handleConfirm = (evt) => {
        this.handleDelete(this.props.project, evt)
    }

    handleDelete = (project, e) => {
        this.setState({
            loading: true,
            disabled: true,
            open: false
        });

        client({ method: 'DELETE', path: project._links.self.href })
            .then(response => {
                this.props.onDelete(project, <span>Project deleted: <em>{project.title}</em></span>, e);
            }, error => {
                toast.error(<span>{error.status.text}</span>, { autoClose: false });
            }).finally(() => {
                this.setState({
                    loading: false,
                    disabled: false
                });
            });
    }

    render() {
        let { project } = this.props;
        let confirmContent = <div className='content'>Are you sure to delete <em>{project.title}</em>?</div>;

        return (
            <>
                <Button icon='delete' compact title='Delete' loading={this.state.loading} disabled={this.state.disabled}
                    onClick={this.show}
                />
                <Confirm open={this.state.open} onCancel={this.handleCancel} onConfirm={this.handleConfirm}
                    dimmer='inverted' header='Delete project' content={confirmContent}
                />
            </>
        )
    }
}

const ProjectTable = ({ projects, loading, sort, ...rest }) => {
    const loggedUser = userService.loggedUser();

    return (
        <DataTable data={projects} loading={loading} sortMetaData={sort} footerColSpan={loggedUser ? 6 : 5} {...rest}
            header={headerProps => (
                <Table.Header>
                    <Table.Row>
                        <DataTableHeaderCell field='title' label='Title' {...headerProps} width={8} />
                        <DataTableHeaderCell field='acronym' label='Acronym' {...headerProps} />
                        <DataTableHeaderCell field='leadPartnerName' label='Lead partner' {...headerProps} width={3} />
                        <DataTableHeaderCell field='userCreateId' label='User created' {...headerProps} />
                        <DataTableHeaderCell field='authorizeTimestamp' label='Publication date' className='columnUpdated' {...headerProps} />
                        {loggedUser && <Table.HeaderCell collapsing></Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
            )}
            body={({ data, onDelete }) => (
                <Table.Body>
                    {data.map(project => {
                        const canEdit = userService.isAdmin() || userService.isAuthorizing()
                            || (loggedUser && loggedUser.id === project.userCreateId);
                        return (
                            <Table.Row key={project._links.self.href}>
                                <Table.Cell className='preLine'>
                                    <Link to={`/projects/${_last(project._links.self.href.split("/"))}`}
                                        title='Preview'>
                                        {project.title}
                                    </Link>
                                </Table.Cell>
                                <Table.Cell>
                                    {project.acronym}
                                </Table.Cell>
                                <Table.Cell>
                                    {project.leadPartnerName}
                                </Table.Cell>
                                <Table.Cell>
                                    {project.userCreate || partnerNameByUserId(project.userCreateId)}
                                </Table.Cell>
                                <Table.Cell textAlign='center'>
                                    {project.publicationDate}
                                </Table.Cell>
                                {loggedUser &&
                                    <Table.Cell>
                                        <Button.Group basic>
                                            {canEdit && <Button compact icon='edit' title='Edit' as={Link} to={`/projects/${_last(project._links.self.href.split("/"))}`} />}
                                            {canEdit && <DeleteProjectButton project={project} onDelete={onDelete} />}
                                        </Button.Group>
                                    </Table.Cell>
                                }
                            </Table.Row>
                        )
                    }
                    )}
                </Table.Body>
            )
            }
        />
    )
}

const partnerNameByUserId = userId => {
    switch (userId) {
        case 10:
            return 'JSI';
        case 11:
            return 'STEP_RI';
        case 12:
            return 'CRIT';
        case 13:
            return 'KIT';
        case 14:
            return 'PRO';
        case 15:
            return 'WrUST';
        default:
            return userId;
    }
}

export default ProjectList;
