import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router';
import { Menu, Dropdown } from 'semantic-ui-react';
import UserMenuCard from './user/UserMenuCard';
import { UserContext } from '../user-context';
import { userService } from './user/user.service.js';
import classNames from 'classnames';

const AppMenu = props => {
    const location = useLocation();

    const getActiveItem = () => {
        const currentPath = location.pathname;
        return currentPath.substring(1, currentPath.length) || '';
    }

    const activeItem = getActiveItem();

    return (
        <Menu pointing secondary className='topMenu' size='large'>
            <Menu.Item
                name='home' as={Link} to="/"
                active={activeItem.startsWith('home') || activeItem === ''}
            >
                Home
            </Menu.Item>
            <Menu.Item
                name='projects' as={Link} to="/projects"
                active={activeItem.startsWith('projects')}
            >
                Projects
            </Menu.Item>
            <Menu.Item
                name={'organizations'} as={Link} to="/organizations"
                active={activeItem.startsWith('organizations')}
            >
                Organizations
            </Menu.Item>
            <Menu.Item
                name='map' as={Link} to="/map"
                active={activeItem.startsWith('map')}
            >
                Map
            </Menu.Item>
            <MenuMatchmaking activeItem={activeItem} />
            {/* <Menu.Item
                name='infrastructures' as={Link} to="/infrastructures"
                active={activeItem.startsWith('infrastructures')}
            >
                Infrastructure sharing
            </Menu.Item> */}

            <MenuInfrastructure activeItem={activeItem} />

            <MenuUsers activeItem={activeItem} />
            <Menu.Item position='right' name='logout' style={{ paddingBottom: 0 + 'px' }}>
                <div style={{ paddingRight: 230 + 'px' }}>
                    <UserContext.Consumer>
                        {loggedUser => (<UserMenuCard loggedUser={loggedUser} />)}
                    </UserContext.Consumer>
                </div>
            </Menu.Item>
        </Menu>
    )
}

const MenuUsers = ({ activeItem }) => {
    const history = useHistory();
    return (
        userService.isAdmin() ?
            <Menu.Item
                name='users'
                active={activeItem.startsWith('users')}
                onClick={() => { history.push('/users') }}
            >
                Users
            </Menu.Item>
            :
            <Menu.Item />
    )
}

const MenuMatchmaking = ({ activeItem }) => {
    const history = useHistory();
    return (
        <Menu.Item
            name='matchmaking'
            active={activeItem.startsWith('matchmaking')}
            onClick={() => { history.push('/matchmaking_results') }}
        >
            Matchmaking
        </Menu.Item>
    )
}

const MenuInfrastructure = ({ activeItem }) => {
    const history = useHistory();
    const sharingMatchesLabel = 'Infrastructure sharing requests';
    const isDropdown = userService.isAdmin();

    const handleClick = (evt, data) => {
        evt.stopPropagation();
        if (data.text === sharingMatchesLabel) {
            history.push('/infrastructure-requests')
        }
        else {
            history.push('/infrastructures')
        }
    }

    return (
        <Menu.Item
            name='infrastructures' className={classNames({ 'dropdownItem': isDropdown })}
            active={activeItem.startsWith('infrastructures')}
            onClick={handleClick}
        >
            {isDropdown ?
                <Dropdown item simple text='Infrastructure sharing' onClick={handleClick}>
                    <Dropdown.Menu className='dropdownMenu'>
                        <Dropdown.Item text={sharingMatchesLabel}
                            onClick={handleClick} />
                    </Dropdown.Menu>
                </Dropdown>
                :
                'Infrastructure sharing'
            }
        </Menu.Item>
    )
}

export default AppMenu;