import React from 'react';
import { Route, Link, Switch, Redirect } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import { Table, Icon, Confirm } from 'semantic-ui-react'
import UserEditForm from './UserEditByAdminForm'
import _last from 'lodash/last'
import { userService } from './user.service.js';
import { DataList } from '../DataList';
import { DataTable } from '../DataTable';
import { SearchBar } from '../SearchBar';
import client from '../../lib/client.js';
import { toast } from 'react-toastify';

class UserList extends React.Component {
    state = {
        searchText: ''
    }

    getDefaultUrl = () => {
        const { searchText } = this.state;
        if (searchText !== '') {
            return `/api/users/search/findAllByLoginContainingIgnoreCase?login=${searchText}&page=0`
        }
        return '/api/users'
    }

    render() {
        if (!userService.isAdmin()) return <Redirect to='/' />

        return (
            <div>
                <DataList apiDataFetchUrl={this.getDefaultUrl()} apiEntityName='users' defaultSortBy='login,asc'
                    {...this.props}
                >
                    {({ loading, data, sortMetaData, page, ...rest }) => (
                        <>
                            <SearchBar onSearch={searchText => this.setState({ searchText })}>
                                <Button content='Register new account' icon='add' labelPosition='left' floated='right'
                                    primary as={Link} to="/register_new_account" />
                            </SearchBar>

                            <UserTable loading={loading} sort={sortMetaData} users={data}
                                totalPages={page.totalPages} activePage={page.number} totalElements={page.totalElements}
                                {...rest}
                            />

                            <Switch>
                                <Route path="/users/:id" render={props =>
                                    <UserEditForm {...props} onSubmit={rest.onUpdate}
                                        title={<span><Icon name='edit' /> Edit project</span>} />}
                                />
                            </Switch>
                        </>
                    )}
                </DataList>
            </div>
        )
    }
}

const UserTable = ({ users, loading, sort, ...rest }) => (
    <DataTable data={users} loading={loading} sortMetaData={sort} footerColSpan={5} {...rest}
        header={({ sortMetaData, onSort }) => (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell sorted={sortMetaData.field === 'login' ? sortMetaData.direction : null}
                        onClick={evt => onSort('login', evt)}>Login</Table.HeaderCell>
                    <Table.HeaderCell sorted={sortMetaData.field === 'firstName' ? sortMetaData.direction : null}
                        onClick={evt => onSort('firstName', evt)}>First name</Table.HeaderCell>
                    <Table.HeaderCell sorted={sortMetaData.field === 'lastName' ? sortMetaData.direction : null}
                        onClick={evt => onSort('lastName', evt)}>Last name</Table.HeaderCell>
                    <Table.HeaderCell sorted={sortMetaData.field === 'email' ? sortMetaData.direction : null}
                        onClick={evt => onSort('email', evt)}>E-mail</Table.HeaderCell>
                    <Table.HeaderCell collapsing></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )}
        body={({ data, onDelete }) => (
            <Table.Body>
                {data.map(user =>
                    <Table.Row key={user._links.self.href}>
                        <Table.Cell className='preLine'>
                            {user.login}
                        </Table.Cell>
                        <Table.Cell>
                            {user.firstName}
                        </Table.Cell>
                        <Table.Cell>
                            {user.lastName}
                        </Table.Cell>
                        <Table.Cell>
                            {user.email}
                        </Table.Cell>
                        <Table.Cell>
                            <Button.Group basic>
                                <Button icon='edit' title='Edit' as={Link} to={`/users/${_last(user._links.self.href.split("/"))}`} />
                                <DeleteUserButton user={user} onDelete={onDelete} />
                            </Button.Group>
                        </Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
        )}
    />
)

class DeleteUserButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            disabled: false,
            open: false
        };
    }

    show = () => this.setState({ open: true })
    handleCancel = () => this.setState({ open: false })
    handleConfirm = evt => {
        this.handleDelete(this.props.user, evt)
    }

    handleDelete = (user, evt) => {
        this.setState({
            loading: true,
            disabled: true,
            open: false
        });

        const userId = userService.userId(user);

        client({ method: 'DELETE', path: `/authentication_provider_impl/delete_user/${userId}` })
            .then(response => {
                this.props.onDelete(user, <span>User deleted: <em>{user.login}</em></span>, evt);
            }, error => {
                toast.error(<span>{error.entity.error}</span>, { autoClose: false });
            }).finally(() => {
                this.setState({ loading: false, disabled: false })
            })
    }

    render() {
        let { user } = this.props;
        let confirmContent =
            <div className='content'>
                Are you sure to delete <em>{user.login}</em>?
            </div>;

        return (
            <>
                <Button icon='delete' compact title='Delete' loading={this.state.loading} disabled={this.state.disabled}
                    onClick={this.show}
                />
                <Confirm open={this.state.open} onCancel={this.handleCancel} onConfirm={this.handleConfirm}
                    dimmer='inverted' header='Delete user' content={confirmContent}
                />
            </>
        )
    }
}

export default UserList