import React from 'react'
import { Form } from 'semantic-ui-react'
//import client from '../../lib/client.js'
import OptionListWrapper from './OptionListWrapper.js'
//import PropTypes from 'prop-types'

class Dropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            options: []
        };
    }

    componentDidMount() {
        if (this.props.dataUrl) {
            OptionListWrapper.byUrl(this.props.dataUrl)
                .then(response => this.setState({ options: response.entity }))
                .finally(() => this.setState({ loading: false }))
        }
        else {
            this.setState({ loading: false })
        }

        if (this.props.forField) {
            OptionListWrapper.forField(this.props.forField);
        }
    }

    render() {
        const { label, dataUrl, name, infotip, readOnly, placeholder, ...rest } = this.props;

        return (
            <Form.Dropdown name={name} label={<label>{label}{infotip}</label>} placeholder={placeholder || label} options={this.state.options}
                selection loading={this.state.loading} disabled={this.state.loading || readOnly}
                className={readOnly ? 'readOnly' : null}
                {...rest}
                onChange={(evt, data) => this.props.onChange(evt, { selectedValues: data.value, name: data.name })} />
        );
    }
}

export default Dropdown