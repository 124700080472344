import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
//import GraphiQL from 'graphiql';
import OrganizationFiltredTable from './OrganizationFiltredTable'
import ProjectsFiltredTable from './ProjectsFiltredTable'
import GraphQlTestForm from './GraphQlTestForm'
import IframeTest from './IframeTest'

class Analysis extends Component {
    render() {
        return (
                <Switch>
                    <Route path="/analysis/organizations" exact
                            render={(props) =>
                                <OrganizationFiltredTable {...props} />
                            }>
                    </Route>
                    <Route path="/analysis/projects" exact
                        render={(props) =>
                            <ProjectsFiltredTable {...props} />
                        }>
                    </Route>
                    <Route path="/analysis/graphql_test_form" exact
                        render={(props) =>
                            <GraphQlTestForm {...props} />
                        }>
                    </Route>
                    <Route path="/analysis/integration" exact
                        render={(props) =>
                            <IframeTest {...props} />
                        }>
                    </Route>
                
                    {/* <Route path="/analysis/graphiql" exact
                        render={(props) =>
                            <GraphiQL fetcher={graphQLFetcher} />
                        }>
                    </Route> */}
                </Switch>
        )
    }
}

export default Analysis