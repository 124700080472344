import React from 'react';
import { Form, Step, Grid } from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';
import { infrastructureFields as fields } from './InfrastructureFields';
import CountryRegionGroup from '../inputs/CountryRegionGroup'
import FilePreviewWall from './files/FilePreviewWall';
import { ProjectPartersSearch as OrganizationSearch } from '../project/ProjectPartners';
import Dropdown from '../inputs/Dropdown.js'
import DropdownTree from '../inputs/DropdownTree.js'
import * as utils from '../../lib/utils.js';
import { SelectGeolocationFromMap } from '../map/Geolocation';
import { isImageContentType } from './files/file-tools';
import InfoIconTip from '../inputs/InfoIconTip';

export const Steps = ({ currentStep, onStepClick }) => (
    <Step.Group attached='top' size='tiny' widths={2}>
        <Step active={currentStep === 1}
            data-stepnumber={1} title='General information' link
            onClick={onStepClick}
        />

        <Step active={currentStep === 2}
            data-stepnumber={2} title='Specifications/Technical Details/Pictures' link
            onClick={onStepClick}
        />
    </Step.Group>
)

export const StepGeneralInformation = ({ infrastructure, readOnly, onInfrastructureChange, invalidFields }) => {
    const { lat, lon, displayName } = infrastructure.geoSearchData || {};
    const firstImageFromFilelist = infrastructure.fileList.find(({ type }) => isImageContentType(type));

    return (
        <div className='stepContent stepContentInfrastructure'>
            <span className='generalInformationSingleUpload'>
                <FilePreviewWall
                    fileList={[firstImageFromFilelist || []].flat()}
                    filesToDelete={infrastructure.filesToDelete}
                    onChange={onInfrastructureChange}
                    readOnly={readOnly}
                    multiple={false}
                    listTypeToggling={false}
                    maxFiles={1}
                    defaultListType='picture-card'
                    placeholder={<span>Select or drop main picture of your infrastructure</span>}
                    accept='image/*'
                />
            </span>

            <Form.TextArea id='form-input-name'
                label={fields.name.label}
                placeholder={fields.name.label}
                autoFocus control={TextareaAutosize} rows={1}
                name='name'
                value={infrastructure.name || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                required={!readOnly}
                readOnly={readOnly}
                error={invalidFields.find(o => o.name === 'name') !== undefined}
            />
            <Form.TextArea id='form-input-description'
                label={
                    <label>
                        <span>
                            {fields.description.label}
                            <InfoIconTip content='Please provide general information about the equipment (manufacturer and model), technology, capacity and main outputs.' visible={!readOnly} />
                        </span>
                        <CharLimit
                            visible={!readOnly}
                            sizeCurrent={utils.formFieldLength(infrastructure.description)}
                            sizeMax={fields.description.sizeMax}
                        />
                    </label>
                }
                placeholder={fields.description.label}
                autoFocus control={TextareaAutosize} rows={1}
                value={infrastructure.description || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                name='description'
                readOnly={readOnly}
                required={!readOnly}
                error={invalidFields.find(o => o.name === 'description') !== undefined}
            />
            <Form.Field
                required={!readOnly}
                error={invalidFields.find(o => o.name === 'organizationId') !== undefined}
            >
                <label>{fields.organizationId.label}</label>
                <OrganizationSearch
                    placeholder='Search for organization...'
                    defaultValue={infrastructure.organizationName || ''}
                    insertValueOnSelect={true}
                    autoFocus={false}
                    readOnly={readOnly}
                    onProjectPartnerAdd={(e, data) => onInfrastructureChange({ organizationId: data.id, organizationName: data.title })}
                    onSearchClear={() => onInfrastructureChange({ organizationId: null, organizationName: '' })}
                />
            </Form.Field>
            <fieldset className='ui segment'>
                <legend>Location of infrastructure</legend>

                <Form.Input id='form-input-address' label={fields.address.label}
                    placeholder={fields.address.label} required={!readOnly}
                    name="address" value={infrastructure.address || ''}
                    onChange={e => handleInputChange(e, onInfrastructureChange)}
                    readOnly={readOnly}
                    error={invalidFields.find(o => o.name === 'address') !== undefined}
                />

                <Form.Group>
                    <Form.Input id='form-input-zip_code' label={fields.zipCode.label}
                        placeholder={fields.zipCode.label} required={!readOnly}
                        name="zipCode" value={infrastructure.zipCode || ''}
                        onChange={e => handleInputChange(e, onInfrastructureChange)}
                        width={5}
                        readOnly={readOnly}
                        error={invalidFields.find(o => o.name === 'zipCode') !== undefined}
                    />
                    <Form.Input id='form-input-city' label={fields.city.label} placeholder={fields.city.label}
                        name="city" value={infrastructure.city || ''}
                        onChange={e => handleInputChange(e, onInfrastructureChange)}
                        width={11}
                        readOnly={readOnly}
                        required={!readOnly}
                        error={invalidFields.find(o => o.name === 'city') !== undefined}
                    />
                </Form.Group>

                <CountryRegionGroup
                    entity={infrastructure}
                    onChange={onInfrastructureChange}
                    readOnly={readOnly}
                    countryLabel={fields.country.label}
                    regionLabel={fields.region.label}
                    countryValidations={fields.country.validations}
                    countryRequired
                    invalidFields={invalidFields}
                />

                <Form.Group className='geolocation'>
                    <Form.Input id='form-input-lat' label='Latitude' placeholder='Latitude' name='lat' type='number'
                        value={lat || ''} width={8} readOnly={readOnly}
                        onChange={({ target }) => {
                            const { value } = target;
                            const geoSearchData = { ...infrastructure.geoSearchData, lat: parseFloat(value) }
                            onInfrastructureChange({ geoSearchData })
                        }}
                    />
                    <Form.Input id='form-input-lng' label='Longitude' placeholder='Longitude' name='lng' type='number'
                        value={lon || ''} width={8} readOnly={readOnly}
                        onChange={({ target }) => {
                            const { value } = target;
                            const geoSearchData = { ...infrastructure.geoSearchData, lon: parseFloat(value) }
                            onInfrastructureChange({ geoSearchData })
                        }}
                    />
                    <SelectGeolocationFromMap
                        lat={lat} lng={lon}
                        displayName={displayName}
                        readOnly={readOnly}
                        onPositionSelected={({ lat, lng, displayName }) => {
                            const geoSearchData = { ...infrastructure.geoSearchData, lat, lon: lng, displayName }
                            onInfrastructureChange({ geoSearchData })
                        }}
                    />
                </Form.Group>
            </fieldset>

            <Form.TextArea id='form-input-researchServices'
                label={
                    <label>
                        <span>
                            {fields.researchServices.label}
                            <InfoIconTip content='Please describe the specific services that you are able to provide based on the described infrastructure, and also availability of expert staff to operate the infrastructure.' visible={!readOnly} />
                        </span>
                        <CharLimit
                            visible={!readOnly}
                            sizeCurrent={utils.formFieldLength(infrastructure.researchServices)}
                            sizeMax={fields.researchServices.sizeMax}
                        />
                    </label>
                }
                placeholder={fields.researchServices.label}
                autoFocus control={TextareaAutosize} rows={1}
                name='researchServices'
                value={infrastructure.researchServices || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                //required
                readOnly={readOnly}
                error={invalidFields.find(o => o.name === 'researchServices') !== undefined}
            />
            <Dropdown
                upward required={!readOnly}
                onChange={(e, dropdownData) => handleDropdownChange(dropdownData, onInfrastructureChange)}
                value={utils.multiselectStringToArray(infrastructure.possibilitiesToRent)}
                label={fields.possibilitiesToRent.label}
                name='possibilitiesToRent'
                dataUrl='/data/infrastructure_possibilities_to_rent.json'
                readOnly={readOnly}
                multiple selection
                error={invalidFields.find(o => o.name === 'possibilitiesToRent') !== undefined}
            />
            <Form.TextArea id='form-input-website'
                label={fields.website.label}
                placeholder={fields.website.label}
                autoFocus control={TextareaAutosize} rows={1}
                name='website'
                value={infrastructure.website || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                readOnly={readOnly}
                error={invalidFields.find(o => o.name === 'website') !== undefined}
            />
            <Form.TextArea id='form-input-openKeywords'
                label={fields.openKeywords.label}
                placeholder={fields.openKeywords.label}
                autoFocus control={TextareaAutosize} rows={1}
                name='openKeywords'
                value={infrastructure.openKeywords || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                required={!readOnly}
                readOnly={readOnly}
                error={invalidFields.find(o => o.name === 'openKeywords') !== undefined}
            />
            <Form.TextArea id='form-input-possibleApplication'
                label={
                    <label>
                        <span>
                            {fields.possibleApplication.label}
                            <InfoIconTip content='Please describe the purpose of use and possible application in different fields of research or sectors of industry, along with specific outputs.' visible={!readOnly} />
                        </span>
                        <CharLimit
                            visible={!readOnly}
                            sizeCurrent={utils.formFieldLength(infrastructure.possibleApplication)}
                            sizeMax={fields.possibleApplication.sizeMax}
                        />
                    </label>
                }
                placeholder={fields.possibleApplication.label}
                autoFocus control={TextareaAutosize} rows={1}
                name='possibleApplication'
                value={infrastructure.possibleApplication || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                readOnly={readOnly}
                error={invalidFields.find(o => o.name === 'possibleApplication') !== undefined}
            />

            <div className='stepInfrastructure'>
                <DropdownTree
                    name='infrastructureAdditiveManufacturing'
                    label='Infrastructure Type Additive Manufacturing'
                    onChange={(e, dropdownData) => handleDropdownChange(dropdownData, onInfrastructureChange)}
                    dataUrl='/data/organization_infrastructure_additive_manufacturing.json'
                    value={utils.multiselectStringToArray(infrastructure.infrastructureAdditiveManufacturing)}
                    readOnly={readOnly}
                />

                <DropdownTree
                    name='infrastructureIndustry40'
                    label='Infrastructure Type Industry 4.0'
                    onChange={(e, dropdownData) => handleDropdownChange(dropdownData, onInfrastructureChange)}
                    dataUrl='/data/organization_infrastructure_industry_40.json'
                    value={utils.multiselectStringToArray(infrastructure.infrastructureIndustry40)}
                    readOnly={readOnly}
                />

                <DropdownTree
                    name='infrastructureMicroNanoManufacturing'
                    label='Infrastructure Type Micro/Nano Manufacturing'
                    onChange={(e, dropdownData) => handleDropdownChange(dropdownData, onInfrastructureChange)}
                    dataUrl='/api/dictionary/infrastructure_micro_nano_manufacturing/dropdown_tree'
                    value={utils.multiselectStringToArray(infrastructure.infrastructureMicroNanoManufacturing)}
                    readOnly={readOnly}
                />
            </div>
            <div className='stepInfrastructure infrastructureResearchEquipment'>
                <DropdownTree
                    name='infrastructureResearchEquipment'
                    label='Infrastructure Type Research Equipment'
                    onChange={(e, dropdownData) => handleDropdownChange(dropdownData, onInfrastructureChange)}
                    dataUrl='/infrastructure_type/infrastructure_research_equipment.json'
                    value={utils.multiselectStringToArray(infrastructure.infrastructureResearchEquipment)}
                    readOnly={readOnly}
                />

                <DropdownTree
                    name='infrastructureOfficeEquipment'
                    label='Infrastructure Type Space and Office Equipment'
                    onChange={(e, dropdownData) => handleDropdownChange(dropdownData, onInfrastructureChange)}
                    dataUrl='/infrastructure_type/infrastructure_office_equipment.json'
                    value={utils.multiselectStringToArray(infrastructure.infrastructureOfficeEquipment)}
                    readOnly={readOnly}
                />
            </div>

            <Form.TextArea id='form-input-acquisitionYear'
                label={fields.acquisitionYear.label}
                placeholder={fields.acquisitionYear.label}
                autoFocus control={TextareaAutosize} rows={1}
                name='acquisitionYear'
                value={infrastructure.acquisitionYear || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                //required
                readOnly={readOnly}
                error={invalidFields.find(o => o.name === 'acquisitionYear') !== undefined}
            />
            <Form.TextArea id='form-input-usagePrice'
                label={fields.usagePrice.label}
                placeholder='E.g. 30 per hour, for negotiation, 500/day'
                autoFocus control={TextareaAutosize} rows={1}
                name='usagePrice'
                value={infrastructure.usagePrice || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                required={!readOnly}
                readOnly={readOnly}
                error={invalidFields.find(o => o.name === 'usagePrice') !== undefined}
            />
            <Form.TextArea id='form-input-responsiblePerson'
                label={fields.responsiblePerson.label}
                placeholder={fields.responsiblePerson.label}
                autoFocus control={TextareaAutosize} rows={1}
                name='responsiblePerson'
                value={infrastructure.responsiblePerson || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                required={!readOnly}
                readOnly={readOnly}
                error={invalidFields.find(o => o.name === 'responsiblePerson') !== undefined}
            />
            <Form.TextArea id='form-input-responsiblePersonEmail'
                label={fields.responsiblePersonEmail.label}
                placeholder={fields.responsiblePersonEmail.label}
                autoFocus control={TextareaAutosize} rows={1}
                name='responsiblePersonEmail'
                value={infrastructure.responsiblePersonEmail || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                required={!readOnly}
                readOnly={readOnly}
                error={invalidFields.find(o => o.name === 'responsiblePersonEmail') !== undefined}
            />
            <Form.TextArea id='form-input-responsiblePersonPhone'
                label={fields.responsiblePersonPhone.label}
                placeholder={fields.responsiblePersonPhone.label}
                autoFocus control={TextareaAutosize} rows={1}
                name='responsiblePersonPhone'
                value={infrastructure.responsiblePersonPhone || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                required={!readOnly}
                readOnly={readOnly}
                error={invalidFields.find(o => o.name === 'responsiblePersonPhone') !== undefined}
            />
        </div>
    )
}

export const StepSpecifications = ({ infrastructure, readOnly, onInfrastructureChange, invalidFields }) => (
    <div className='stepContent'>
        <Grid>
            <Grid.Row>
                <Grid.Column width={8}>
                    <FilePreviewWall
                        fileList={infrastructure.fileList}
                        filesToDelete={infrastructure.filesToDelete}
                        onChange={onInfrastructureChange}
                        readOnly={readOnly}
                    />
                </Grid.Column>
                <Grid.Column width={8}>
                    <Form.TextArea id='form-input-image-desc-copyright'
                        label={fields.imageDescriptionCopyright.label}
                        placeholder={fields.imageDescriptionCopyright.label}
                        autoFocus control={TextareaAutosize} rows={1}
                        name='imageDescriptionCopyright'
                        value={infrastructure.imageDescriptionCopyright || ''}
                        onChange={e => handleInputChange(e, onInfrastructureChange)}
                        readOnly={readOnly}
                        error={invalidFields.find(o => o.name === 'imageDescriptionCopyright') !== undefined}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </div>
)

const handleInputChange = ({ target }, callback) => {
    const { value, name } = target;
    callback({ [name]: value });
}

const handleDropdownChange = (dropdownData, callback) => {
    const value = Array.isArray(dropdownData.selectedValues) ? utils.multiselectArrayToString(dropdownData.selectedValues) : dropdownData.selectedValues;
    const name = dropdownData.name;
    callback({ [name]: value });
}

const CharLimit = ({ sizeCurrent, sizeMax, visible = true }) =>
    visible && <span className='charLimit' title='Character limit'>{sizeCurrent}/{sizeMax}</span>
