import React, { Component } from 'react'
import { Form } from 'semantic-ui-react'
import Dropdown from '../inputs/Dropdown.js'
import DropdownTree from '../inputs/DropdownTree.js'
import * as utils from '../../lib/utils.js'
import InfoIconTip from '../inputs/InfoIconTip';

class IndustryGroup extends Component {
    constructor(props) {
        super(props);
        this.handleProjectChange = props.onProjectChange;
    }

    componentDidMount() {
    }

    handleYesNoChange = (evt, dropdownData) => {
        const value = dropdownData.selectedValues;
        this.handleProjectChange({industry40: value});
    }

    handleTechnologyKeywordsChange = (evt, dropdownData) => {
        const value = Array.isArray(dropdownData.selectedValues) ? utils.multiselectArrayToString(dropdownData.selectedValues) : dropdownData.selectedValues;
        this.handleProjectChange({industryKpaTechnologyKeywords: value});
    }

    handleMarketKeywordsChange = (evt, dropdownData) => {
        const value = Array.isArray(dropdownData.selectedValues) ? utils.multiselectArrayToString(dropdownData.selectedValues) : dropdownData.selectedValues;
        this.handleProjectChange({industryKpaMarketKeywords: value});
    }

    render() {
        const { project, readOnly } = this.props;
        const kpaControllsDisabled = project.industry40 !== 'Yes';

        return (
            <fieldset className='ui segment'>
                <legend>Industry 4.0</legend>
                <Dropdown
                    required={!readOnly}
                    onChange={this.handleYesNoChange}
                    value={project.industry40}
                    label='Industry 4.0'
                    name='industry40'
                    dataUrl='/data/project_yes_no_not_known.json'
                    infotip={<InfoIconTip content='(decentralised or customised production, production assistance systems, 
                        Human-machine interface, tailor-made products, IoT, CPS) - additive manufacturing not included' visible={!readOnly} />}
                    error={this.props.invalidFields.find(o => o.name === 'industry40') !== undefined}
                    readOnly={readOnly}
                />
                <Form.Field disabled={kpaControllsDisabled}>
                    <DropdownTree
                        required={!kpaControllsDisabled && !readOnly}
                        name='industryKpaTechnologyKeywords'
                        label='KPA – Technology Keywords'
                        onChange={this.handleTechnologyKeywordsChange}
                        dataUrl='/api/dictionary/industry_kpa_technology_keywords/dropdown_tree'
                        value={utils.multiselectStringToArray(project.industryKpaTechnologyKeywords)}
                        error={this.props.invalidFields.find(o => o.name === 'industryKpaTechnologyKeywords') !== undefined}
                        readOnly={this.props.readOnly}
                        visible={!kpaControllsDisabled}
                    />
                    <DropdownTree
                        required={!kpaControllsDisabled && !readOnly}
                        name='industryKpaMarketKeywords'
                        label='KPA – Market Keywords'
                        onChange={this.handleMarketKeywordsChange}
                        dataUrl='/api/dictionary/industry_kpa_market_keywords/dropdown_tree'
                        value={utils.multiselectStringToArray(project.industryKpaMarketKeywords)}
                        error={this.props.invalidFields.find(o => o.name === 'industryKpaMarketKeywords') !== undefined}
                        readOnly={this.props.readOnly}
                        visible={!kpaControllsDisabled}
                    />
                </Form.Field>
            </fieldset>
        );
    }
}

export default IndustryGroup;