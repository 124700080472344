import React from 'react'

class IframeTest extends React.Component {

    render() {
        return (
            <div style={{height: 700 + 'px'}}>
                <iframe src="http://scienceship.com" width='100%' height='100%' title='scienceship'></iframe> 
            </div>
        )
    }

}

export default IframeTest