import { isBlank, maxSize } from '../../../lib/validation.js';
//import { isInteger, isNil, negate } from 'lodash';

export const infrastructureRequestFields = {
    requestDescription: {
        label: `Please give a short description of your company and describe
            your interest in the infrastructure`,
        sizeMax: 750,
        get validations() { return [isBlank, maxSize(this.sizeMax)] },
        get validationError() {
            if (isBlank(this.value)) return this.label + ' is required'
            return this.label + ' max size is ' + this.sizeMax
        }
    },
    possibilitiesToRent: {
        label: 'How do you want to use the infrastructure?',
        get validations() { return [isBlank] },
        get validationError() {
            if (isBlank(this.value)) return this.label + ' is required'
            return ''
        }
    },
    possibleUseStartDate: {
        label: 'Time slot when you want to use the infrastructure (start)',
        validations: isBlank,
        get validationError() { return this.label + ' is required' }
    },
    possibleUseEndDate: {
        label: 'Time slot when you want to use the infrastructure (end)',
        validations: isBlank,
        get validationError() { return this.label + ' is required' }
    },
    plannedUseDescription: {
        label: `How long do you plan to use the infrastructure? (e.g. 2 weeks)`,
        sizeMax: 100,
        get validations() { return [isBlank, maxSize(this.sizeMax)] },
        get validationError() {
            if (isBlank(this.value)) return this.label + ' is required'
            return this.label + ' max size is ' + this.sizeMax
        }
    },
    isVoucherApply: {
        label: `Yes, I would like to apply for a voucher of 5000 EURs and declare that my organisation and all other entities
            belonging to the same group as my organisation (according to the notion of “single undertaking” as defined
            in Article 2(2) of Regulation (EU) No 1407/2013 on de minimis aid) has not been granted with more
            than 200.000 € (de minimis threshold) de minimis aid during the current and the previous two fiscal years.`
    },
    voucherApplyPlanDescription: {
        label: `Please describe your plan in more detail, how would you like to use the infrastructure? 
            (Organization of sharing, use case … )`,
        sizeMax: 750,
        get validations() { return [maxSize(this.sizeMax)] },
        get validationError() {
            return this.label + ' max size is ' + this.sizeMax
        }
    },
    voucherApplyTechnologicalPotential: {
        label: `What is the technological potential for your company?
            How will the technology improve your business?`,
        sizeMax: 750,
        get validations() { return [maxSize(this.sizeMax)] },
        get validationError() {
            return this.label + ' max size is ' + this.sizeMax
        }
    },
    voucherApplyBenefitAnalysis: {
        label: `Please provide a short cost/benefit analysis of sharing the infrastructure?`,
        sizeMax: 750,
        get validations() { return [maxSize(this.sizeMax)] },
        get validationError() {
            return this.label + ' max size is ' + this.sizeMax
        }
    },
    isParticipatedInOtherSynergyActivities: {
        label: 'Yes, I took part in any other Synergy activities'
    },
    otherSynergyActivitiesDescription: {
        label: 'Please specify',
        sizeMax: 750,
        get validations() { return [maxSize(this.sizeMax)] },
        get validationError() {
            return this.label + ' max size is ' + this.sizeMax
        }
    }
}