import React from 'react';
import { Button, Form, Grid, Header, Segment, Message } from 'semantic-ui-react'
import { userService } from './user.service.js';
import ReactGA from 'react-ga'

class ChangePasswordForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {
                login: '',
                oldPassword: '',
                newPassword: '',
            },
            error: '',
            passwordChanged: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setError = this.setError.bind(this);
        this.handlePasswordChanged = this.handlePasswordChanged.bind(this);

        this.loginRegEx = /^[_'.@A-Za-z0-9-]*$/;
    }

    componentDidMount() {
        ReactGA.pageview('/change_password')
    }

    handleChange(e) {
        const { name, value } = e.target;
        let user = { ...this.state.user };
        user[name] = value;

        this.setState({ user });
    }

    validatePass(pass) {
        return (pass.length > 5);
    }

    formIsValid() {
        const { user } = this.state;
        return user.oldPassword.length > 0 && this.validatePass(user.newPassword);
    }

    setError(message) {
        this.setState({ error: message, isLoading: false, });
    }

    handlePasswordChanged(user) {
        this.setState({ passwordChanged: true, isLoading: false, });
    }

    handleSubmit(e) {
        this.setState({ error: '', isLoading: true, });
        const { user } = this.state;

        if (user.oldPassword && user.newPassword) {
            userService.changePassword(user, this.handlePasswordChanged, this.setError);
        }
    }

    render() {
        const { user, error, isLoading, passwordChanged } = this.state;
        let registerForm =
            <div >
                <div className='login-form'>
                    <Grid
                        textAlign='center'
                        style={{ height: '100%' }}
                        verticalAlign='middle'
                    >
                        <Grid.Column style={{ maxWidth: 450 }}>
                            <Header as='h2' color='teal' textAlign='center'>
                                Change password
                            </Header>
                            <Form size='large'>
                                <Segment>
                                    <Form.Input id='form-input-oldPassword' label='Old password' placeholder='Old password'
                                        name="oldPassword" value={user.oldPassword || ''} type='password' onChange={this.handleChange}
                                        autoFocus
                                    />
                                    <Form.Input id='form-input-newPassword' label='New password' placeholder='New password'
                                        name="newPassword" value={user.newPassword || ''} type='password' onChange={this.handleChange}
                                        error={!this.validatePass(user.newPassword)}
                                    />


                                    <Button color='teal' fluid size='large' onClick={(evt) => this.handleSubmit(evt)}
                                        disabled={!this.formIsValid() || isLoading}
                                    >
                                        Change password
                                    </Button>
                                </Segment>
                            </Form>
                            <span className='error'>{error}</span>
                        </Grid.Column>
                    </Grid>
                </div>
            </div>

        return (
            !passwordChanged
            ?
            registerForm
            :
            <Message>
                <Message.Header>Password successfully changed</Message.Header>
            </Message>
        )
    }
}

export { ChangePasswordForm };