import { userService } from '../user/user.service.js';
import _ from 'lodash';

export default class InfrastructureApi {
    static infrastructureId = infrastructure => Number(_.last(infrastructure._links.self.href.split('/')))

    static isNew = infrastructure => !infrastructure.updateTimestamp;

    static canEdit = infrastructure => {
        let loggedUser = userService.loggedUser();
        return userService.isAdmin() || userService.isAuthorizing()
            || (loggedUser && loggedUser.id === infrastructure.userOwnerId)
            || (loggedUser && loggedUser.id === infrastructure.userCreateId)
            || InfrastructureApi.isNew(infrastructure);
    }
}