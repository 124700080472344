import React, { useState } from 'react';
import { Modal, Button, Icon } from 'antd';
import { download, cancelDownload } from './file-tools';

export const ModalPreview = ({ previewFile, previewImage, visible, handlePreviewClose }) => {
    return (
        <Modal
            className='fileWallImgPreviewModal'
            onCancel={handlePreviewClose}
            centered
            visible={visible}
            footer={[
                <DownloadButton key='download' file={previewFile} />,
                <Button key='close' onClick={handlePreviewClose}>Close</Button>
            ]}
        >
            {
                previewImage &&
                <img alt='preview' className='fileWallImgPreview' style={{ maxHeight: window.innerHeight }}
                    src={previewImage}
                />
            }
        </Modal>
    )
}

const DownloadButton = ({ file }) => {
    const [downloading, setDownloading] = useState(false);
    const [percent, setPercent] = useState(0);

    const handleProgress = ({ percent }) => {
        setPercent(percent);
        if (percent === 100) {
            setDownloading(false)
            setPercent(0);
        }
    }

    const handleDownload = () => {
        setDownloading(true);
        download(file, handleProgress);
    }

    const handleCancel = () => {
        cancelDownload(file.name);
        setDownloading(false);
        setPercent(0);
    }

    return (
        downloading ?
            <Button type='danger' onClick={handleCancel}>
                <Icon type='loading' /> {`${percent}% Cancel`}
            </Button>
            :
            <Button type='primary' onClick={handleDownload} disabled={(file && !file.uploaded)}>
                <Icon type='download' /> Download
            </Button>
    )
}
